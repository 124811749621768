import { MarketplaceManagementIcon } from '@cart/ui/icons/MarketplaceManagementIcon'
import { UnifiedAnalyticsIcon } from '@cart/ui/icons/UnifiedAnalyticsIcon'
import { faArchive } from '@fortawesome/pro-solid-svg-icons/faArchive'
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell'
import { faBolt } from '@fortawesome/pro-solid-svg-icons/faBolt'
import { faBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark'
import { faBoxTaped } from '@fortawesome/pro-solid-svg-icons/faBoxTaped'
import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding'
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons/faBullhorn'
import { faBullseyePointer } from '@fortawesome/pro-solid-svg-icons/faBullseyePointer'
import { faCartShopping } from '@fortawesome/pro-solid-svg-icons/faCartShopping'
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons/faCircleQuestion'
import { faCrosshairs } from '@fortawesome/pro-solid-svg-icons/faCrosshairs'
import { faCubes } from '@fortawesome/pro-solid-svg-icons/faCubes'
import { faDatabase } from '@fortawesome/pro-solid-svg-icons/faDatabase'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faFileChartColumn } from '@fortawesome/pro-solid-svg-icons/faFileChartColumn'
import { faGaugeHigh } from '@fortawesome/pro-solid-svg-icons/faGaugeHigh'
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear'
import { faGears } from '@fortawesome/pro-solid-svg-icons/faGears'
import { faHeartPulse } from '@fortawesome/pro-solid-svg-icons/faHeartPulse'
import { faHouse } from '@fortawesome/pro-solid-svg-icons/faHouse'
import { faHyphen } from '@fortawesome/pro-solid-svg-icons/faHyphen'
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faMemo } from '@fortawesome/pro-solid-svg-icons/faMemo'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons/faScrewdriverWrench'
import { faShoppingBag } from '@fortawesome/pro-solid-svg-icons/faShoppingBag'
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar'
import { faStore } from '@fortawesome/pro-solid-svg-icons/faStore'
import { faTachometerAlt } from '@fortawesome/pro-solid-svg-icons/faTachometerAlt'
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag'
import { faThumbtack } from '@fortawesome/pro-solid-svg-icons/faThumbtack'
import { faToolbox } from '@fortawesome/pro-solid-svg-icons/faToolbox'
import { faTruckFast } from '@fortawesome/pro-solid-svg-icons/faTruckFast'
import { faUserAlt } from '@fortawesome/pro-solid-svg-icons/faUserAlt'
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle'
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
import { faWarehouse } from '@fortawesome/pro-solid-svg-icons/faWarehouse'

export const icons = {
  faBoxTaped,
  faArchive,
  faBell,
  faBolt,
  faBookmark,
  faBuilding,
  faBullhorn,
  faBullseyePointer,
  faCartShopping,
  faCircleQuestion,
  faCrosshairs,
  faCubes,
  faDatabase,
  faEnvelope,
  faFileChartColumn,
  faGaugeHigh,
  faGear,
  faGears,
  faHeartPulse,
  faHouse,
  faHyphen,
  faLock,
  faMemo,
  faPencil,
  faScrewdriverWrench,
  faShoppingBag,
  faStar,
  faStore,
  faTag,
  faTachometerAlt,
  faThumbtack,
  faToolbox,
  faTruckFast,
  faUserAlt,
  faUserCircle,
  faUsers,
  faWarehouse,
  MarketplaceManagementIcon,
  UnifiedAnalyticsIcon,
}
