import { DeprecatedButton, useRouter } from '@cart/ui'
import React from 'react'

import page_404 from '../../assets/images/page_doesnt_exist_404.png'

const ErrorPage404 = () => {
  const { navigate } = useRouter()

  const goBack = () => {
    navigate('/unified-analytics/dashboard')
  }

  return (
    <div tw="flex h-full w-full items-center justify-center">
      <div tw="flex w-full items-center justify-center" data-testid="error-page-404-container">
        <div tw="flex flex-1 flex-col-reverse justify-center gap-8 md:(flex-row gap-6)">
          <div tw="flex flex-col items-center justify-center md:items-start">
            <h2 tw="mb-0">Oops! this page doesn&prime;t exist</h2>
            <p tw="mb-0 text-center pt-[8px] pb-[32px] md:text-left">
              Double check the URL or head back to the page from which you navigated. Contact us if
              you think this is a bug.
            </p>
            <div>
              <DeprecatedButton text="Go Back" tw="mx-auto inline-flex! w-auto" onClick={goBack} />
            </div>
          </div>
          <div tw="flex flex-col items-center justify-center">
            <img src={page_404} alt="page does not exist" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage404
