export const RETURNS_DATA = [
  { DATE: '2022-05-01 00:00:00.000', RETURNS: '15' },
  { DATE: '2022-05-02 00:00:00.000', RETURNS: '86' },
  { DATE: '2022-05-03 00:00:00.000', RETURNS: '55' },
  { DATE: '2022-05-04 00:00:00.000', RETURNS: '58' },
  { DATE: '2022-05-05 00:00:00.000', RETURNS: '96' },
  { DATE: '2022-05-06 00:00:00.000', RETURNS: '60' },
  { DATE: '2022-05-07 00:00:00.000', RETURNS: '17' },
  { DATE: '2022-05-08 00:00:00.000', RETURNS: '13' },
  { DATE: '2022-05-09 00:00:00.000', RETURNS: '170' },
  { DATE: '2022-05-10 00:00:00.000', RETURNS: '111' },
  { DATE: '2022-05-11 00:00:00.000', RETURNS: '67' },
  { DATE: '2022-05-12 00:00:00.000', RETURNS: '96' },
  { DATE: '2022-05-13 00:00:00.000', RETURNS: '91' },
  { DATE: '2022-05-14 00:00:00.000', RETURNS: '12' },
  { DATE: '2022-05-15 00:00:00.000', RETURNS: '22' },
  { DATE: '2022-05-16 00:00:00.000', RETURNS: '113' },
  { DATE: '2022-05-17 00:00:00.000', RETURNS: '61' },
  { DATE: '2022-05-18 00:00:00.000', RETURNS: '90' },
  { DATE: '2022-05-19 00:00:00.000', RETURNS: '65' },
  { DATE: '2022-05-20 00:00:00.000', RETURNS: '78' },
  { DATE: '2022-05-21 00:00:00.000', RETURNS: '15' },
  { DATE: '2022-05-22 00:00:00.000', RETURNS: '7' },
  { DATE: '2022-05-23 00:00:00.000', RETURNS: '127' },
  { DATE: '2022-05-24 00:00:00.000', RETURNS: '170' },
  { DATE: '2022-05-25 00:00:00.000', RETURNS: '102' },
  { DATE: '2022-05-26 00:00:00.000', RETURNS: '305' },
  { DATE: '2022-05-27 00:00:00.000', RETURNS: '116' },
  { DATE: '2022-05-28 00:00:00.000', RETURNS: '19' },
  { DATE: '2022-05-29 00:00:00.000', RETURNS: '20' },
  { DATE: '2022-05-30 00:00:00.000', RETURNS: '10' },
  { DATE: '2022-05-31 00:00:00.000', RETURNS: '212' },
  { DATE: '2022-06-01 00:00:00.000', RETURNS: '102' },
  { DATE: '2022-06-02 00:00:00.000', RETURNS: '101' },
  { DATE: '2022-06-03 00:00:00.000', RETURNS: '71' },
  { DATE: '2022-06-04 00:00:00.000', RETURNS: '10' },
  { DATE: '2022-06-05 00:00:00.000', RETURNS: '7' },
  { DATE: '2022-06-06 00:00:00.000', RETURNS: '77' },
  { DATE: '2022-06-07 00:00:00.000', RETURNS: '75' },
  { DATE: '2022-06-08 00:00:00.000', RETURNS: '41' },
  { DATE: '2022-06-09 00:00:00.000', RETURNS: '154' },
  { DATE: '2022-06-10 00:00:00.000', RETURNS: '101' },
  { DATE: '2022-06-11 00:00:00.000', RETURNS: '18' },
  { DATE: '2022-06-12 00:00:00.000', RETURNS: '14' },
  { DATE: '2022-06-13 00:00:00.000', RETURNS: '140' },
  { DATE: '2022-06-14 00:00:00.000', RETURNS: '120' },
  { DATE: '2022-06-15 00:00:00.000', RETURNS: '92' },
  { DATE: '2022-06-16 00:00:00.000', RETURNS: '57' },
  { DATE: '2022-06-17 00:00:00.000', RETURNS: '66' },
  { DATE: '2022-06-18 00:00:00.000', RETURNS: '19' },
  { DATE: '2022-06-19 00:00:00.000', RETURNS: '29' },
  { DATE: '2022-06-20 00:00:00.000', RETURNS: '63' },
  { DATE: '2022-06-21 00:00:00.000', RETURNS: '260' },
  { DATE: '2022-06-22 00:00:00.000', RETURNS: '143' },
  { DATE: '2022-06-23 00:00:00.000', RETURNS: '78' },
  { DATE: '2022-06-24 00:00:00.000', RETURNS: '61' },
  { DATE: '2022-06-25 00:00:00.000', RETURNS: '17' },
  { DATE: '2022-06-26 00:00:00.000', RETURNS: '20' },
  { DATE: '2022-06-27 00:00:00.000', RETURNS: '89' },
  { DATE: '2022-06-28 00:00:00.000', RETURNS: '119' },
  { DATE: '2022-06-29 00:00:00.000', RETURNS: '234' },
  { DATE: '2022-06-30 00:00:00.000', RETURNS: '60' },
]
