// @ts-ignore
import {
  DeprecatedButton,
  DeprecatedDialog,
  DeprecatedInput,
  RadioButton,
  Spinner,
  Tooltip,
} from '@cart/ui'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import tw from 'twin.macro'

export const SavedViewControls = ({
  openAutocomplete,
  setOpenAutocomplete,
  handleAutocompleteInputChange,
  customViews,
  loadingAutocomplete,
  handleDefaultModalOpen,
  setShowSaveViewDialog,
  pageTitle,
  pageDescription,
  vizHasLoaded,
}) => {
  const [showDefaultTooltip, setShowDefaultTooltip] = useState(false)
  const [showSaveTooltip, setShowSaveTooltip] = useState(false)

  return (
    <div tw="[max-width: 1440px] m-auto flex justify-between">
      <div tw="flex flex-row items-center">
        <h2>{pageTitle}</h2>
        <Tooltip tw="[z-index: 5000] ml-3 mb-1" content={pageDescription}>
          <FontAwesomeIcon icon={solid('circle-info')} />
        </Tooltip>
      </div>
      {vizHasLoaded && (
        <div tw="mb-2 flex items-center justify-end gap-2">
          <Autocomplete
            id="autocomplete-cv"
            open={openAutocomplete}
            onOpen={() => {
              setOpenAutocomplete(true)
            }}
            onClose={() => {
              setOpenAutocomplete(false)
            }}
            onChange={handleAutocompleteInputChange}
            options={customViews}
            sx={{ width: 300 }}
            size="small"
            loading={loadingAutocomplete}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {/* @ts-ignore */}
                {option.label}
                {/* @ts-ignore */}
                {option.isDefault ? (
                  <FontAwesomeIcon icon={solid('star')} tw="mx-3 text-accent-five-800" />
                ) : null}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Saved Views"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingAutocomplete ? <Spinner tw="m-auto h-4 w-4" type="global" /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <span
            tw="relative"
            onFocus={() => setShowDefaultTooltip(true)}
            onMouseOver={() => setShowDefaultTooltip(true)}
            onMouseLeave={() => setShowDefaultTooltip(false)}
            onBlur={() => setShowDefaultTooltip(false)}
          >
            <FontAwesomeIcon
              icon={solid('pen-to-square')}
              onClick={handleDefaultModalOpen}
              tw="mx-3 h-6 w-6 cursor-pointer text-monochrome-600"
            />

            <div
              tw="[right: -23px] absolute z-50 flex flex-col justify-center"
              css={[showDefaultTooltip ? tw`flex` : tw`hidden`]}
            >
              <FontAwesomeIcon icon={solid('caret-up')} tw="-mb-1.5" />

              <div tw="w-full whitespace-nowrap bg-monochrome-900 text-white text-xs rounded-[3px] p-[5px]">
                Edit Default View
              </div>
            </div>
          </span>
          <span
            tw="relative"
            onFocus={() => setShowSaveTooltip(true)}
            onMouseOver={() => setShowSaveTooltip(true)}
            onMouseLeave={() => setShowSaveTooltip(false)}
            onBlur={() => setShowSaveTooltip(false)}
          >
            <FontAwesomeIcon
              icon={regular('floppy-disk')}
              onClick={() => setShowSaveViewDialog(true)}
              tw="mt-0.5 h-6 w-6 cursor-pointer text-monochrome-600"
            />
            <div
              tw="[right: -23px] absolute z-50 flex flex-col justify-center"
              css={[showSaveTooltip ? tw`flex` : tw`hidden`]}
            >
              <FontAwesomeIcon icon={solid('caret-up')} tw="-mb-1.5" />

              <div tw="w-full whitespace-nowrap bg-monochrome-900 text-white text-xs rounded-[3px] p-[5px]">
                Save View
              </div>
            </div>
          </span>
        </div>
      )}
    </div>
  )
}

export const SaveViewModal = ({
  showSaveViewDialog,
  toggleSaveView,
  methods,
  handleSubmit,
  handleCvSave,
}) => {
  return (
    <DeprecatedDialog
      id="save-view-dialog"
      title="Save View"
      show={showSaveViewDialog}
      close={toggleSaveView}
      width={800}
    >
      <FormProvider {...methods}>
        <form tw="w-full" onSubmit={handleSubmit(handleCvSave)}>
          <DeprecatedDialog.Body>
            <div tw="flex w-full flex-col">
              <p>Please name your custom view.</p>
              <DeprecatedInput id="cvTitle" label="Name" />
            </div>
          </DeprecatedDialog.Body>
          <DeprecatedDialog.Actions>
            <DeprecatedButton text="Cancel" variant="tertiary" onClick={toggleSaveView} />
            <DeprecatedButton text="Ok" type="submit" />
          </DeprecatedDialog.Actions>
        </form>
      </FormProvider>
    </DeprecatedDialog>
  )
}

export const DefaultViewModal = ({
  showSetDefaultDialog,
  toggleDefaultViewModal,
  methods,
  handleSubmit,
  watch,
  handleDefaultSave,
  loadingDefault,
  customViewsDefault,
  workbookSaving,
}) => {
  return (
    <DeprecatedDialog
      id="set-default-dialog"
      title="Edit Default View"
      show={showSetDefaultDialog}
      close={toggleDefaultViewModal}
      width={800}
    >
      <FormProvider {...methods}>
        <form tw="w-full" onSubmit={handleSubmit(handleDefaultSave)}>
          <DeprecatedDialog.Body>
            <div tw="flex w-full flex-col">
              <p>Please select a view to set as the default view.</p>
              <div tw="rounded border border-monochrome-600 p-4">
                {loadingDefault ? <Spinner tw="m-auto h-6 w-6" type="global" /> : null}
                {customViewsDefault.map(
                  (view) =>
                    view && (
                      <RadioButton
                        name="customViewsRadio"
                        id={view.id}
                        key={view.id}
                        label={view.label}
                        value={view.label}
                        checked={view.isDefault}
                      />
                    ),
                )}
                {customViewsDefault.length === 1 && (
                  <p tw="mt-6 flex justify-center text-monochrome-600">No Saved Views</p>
                )}
              </div>
            </div>
          </DeprecatedDialog.Body>
          <DeprecatedDialog.Actions>
            <DeprecatedButton text="Cancel" variant="tertiary" onClick={toggleDefaultViewModal} />
            <DeprecatedButton
              disabled={!watch('customViewsRadio')}
              busy={workbookSaving}
              text="Ok"
              type="submit"
            />
          </DeprecatedDialog.Actions>
        </form>
      </FormProvider>
    </DeprecatedDialog>
  )
}
