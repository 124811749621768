import { helpMenu } from '../Shared/help.menu'

export const rptMenu = [
  {
    id: 'rpt',
    items: [
      {
        title: 'Home',
        icon: 'faHouse',
        url: '/',
      },
    ],
  },
  {
    id: 'footer',
    items: [helpMenu],
  },
]
