import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, CircularProgress, Typography } from '@mui/material'
import tw from 'twin.macro'

const QuickStartProgress = ({ percentCompleted }) => (
  <Box tw="relative">
    <CircularProgress
      variant="determinate"
      tw="text-monochrome-300"
      size={40}
      thickness={8}
      value={100}
    />
    <CircularProgress
      variant="determinate"
      tw="absolute left-0 text-accent-three-700"
      size={40}
      thickness={8}
      value={percentCompleted}
    />
  </Box>
)

export const QuickStartGuide = ({ dataConnectorsList }) => {
  let percentCompleted = 0
  let count = 0
  let facebookConnected = false
  let googleAdsConnected = false
  let ga4Connected = false
  if (
    dataConnectorsList?.ua_dataConnectorsList?.some(
      (connector) => connector.title === 'Facebook' || connector.title === 'Google Ads',
    )
  ) {
    if (
      dataConnectorsList?.ua_dataConnectorsList?.some((connector) => connector.title === 'Facebook')
    ) {
      facebookConnected = true
    }
    if (
      dataConnectorsList?.ua_dataConnectorsList?.some(
        (connector) => connector.title === 'Google Ads',
      )
    ) {
      googleAdsConnected = true
    }

    percentCompleted += 50
    count += 1
  }
  if (
    dataConnectorsList?.ua_dataConnectorsList?.some(
      (connector) => connector.title === 'Google Analytics 4',
    )
  ) {
    ga4Connected = true
    percentCompleted += 50
    count += 1
  }

  return (
    <Accordion defaultExpanded={count < 2} tw="mb-10">
      <AccordionSummary
        expandIcon={
          <FontAwesomeIcon tw="text-2xl text-monochrome-600" icon={solid('caret-down')} />
        }
      >
        <Typography tw="flex w-1/2 shrink-0 items-center justify-around sm:w-2/5 md:w-1/3 lg:w-1/4">
          <QuickStartProgress percentCompleted={percentCompleted} />
          <p tw="[line-height: 0.5]">Quick Start Guide</p>
          {ga4Connected ? (
            <FontAwesomeIcon tw="text-2xl text-accent-three-700" icon={solid('circle-check')} />
          ) : (
            <FontAwesomeIcon tw="text-2xl text-monochrome-500" icon={solid('circle-1')} />
          )}
          {facebookConnected || googleAdsConnected ? (
            <FontAwesomeIcon tw="text-2xl text-accent-three-700" icon={solid('circle-check')} />
          ) : (
            <FontAwesomeIcon tw="text-2xl text-monochrome-500" icon={solid('circle-2')} />
          )}
        </Typography>
        <Typography tw="mr-4 flex w-full items-center justify-end text-monochrome-900/60">
          <strong>{count}/2</strong>&nbsp; Completed
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div tw="mx-4 mb-4 flex flex-col justify-center bg-monochrome-100 p-3">
          <Card variant="outlined" tw="mx-1 w-full">
            <CardContent>
              <div tw="mb-2 flex items-center">
                {ga4Connected ? (
                  <FontAwesomeIcon
                    tw="text-2xl text-accent-three-700"
                    icon={solid('circle-check')}
                  />
                ) : (
                  <FontAwesomeIcon tw="text-2xl text-monochrome-500" icon={solid('circle-1')} />
                )}
                <p css={[tw`ml-4`, ga4Connected && tw`line-through`]}>Connect Google Analytics</p>
              </div>
              <p tw="ml-10 text-xs text-monochrome-500">Estimated setup time: 2 minutes</p>
            </CardContent>
          </Card>
          <Card variant="outlined" tw="mx-1 mt-3 w-full">
            <CardContent>
              <div tw="mb-2 flex items-center">
                {facebookConnected || googleAdsConnected ? (
                  <FontAwesomeIcon
                    tw="text-2xl text-accent-three-700"
                    icon={solid('circle-check')}
                  />
                ) : (
                  <FontAwesomeIcon tw="text-2xl text-monochrome-500" icon={solid('circle-2')} />
                )}
                <p css={[tw`ml-4`, (facebookConnected || googleAdsConnected) && tw`line-through`]}>
                  Connect Ad Platforms
                </p>
              </div>
              <p tw="ml-10 text-xs text-monochrome-500">Add Google Ads and/or Facebook Ads</p>
              <Card variant="outlined" tw="ml-10 mt-4 mr-1 w-auto">
                <CardContent>
                  <div tw="mb-2 flex items-center">
                    {facebookConnected ? (
                      <FontAwesomeIcon
                        tw="text-2xl text-accent-three-700"
                        icon={solid('circle-check')}
                      />
                    ) : (
                      <FontAwesomeIcon
                        tw="text-2xl text-monochrome-500"
                        icon={solid('circle-check')}
                      />
                    )}
                    <p css={[tw`ml-4`, facebookConnected && tw`line-through`]}>Facebook Ads</p>
                  </div>
                  <p tw="ml-10 text-xs text-monochrome-500">Estimated setup time: 2 minutes</p>
                </CardContent>
              </Card>
              <Card variant="outlined" tw="ml-10 mt-4 mr-1 w-auto">
                <CardContent>
                  <div tw="mb-2 flex items-center">
                    {googleAdsConnected ? (
                      <FontAwesomeIcon
                        tw="text-2xl text-accent-three-700"
                        icon={solid('circle-check')}
                      />
                    ) : (
                      <FontAwesomeIcon
                        tw="text-2xl text-monochrome-500"
                        icon={solid('circle-check')}
                      />
                    )}
                    <p css={[tw`ml-4`, googleAdsConnected && tw`line-through`]}>Google Ads</p>
                  </div>
                  <p tw="ml-10 text-xs text-monochrome-500">Estimated setup time: 2 minutes</p>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
