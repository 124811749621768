import { useRouter } from '@cart/ui'
import React from 'react'
import { Link } from 'react-router-dom'

export const ErrorTestPage = () => {
  const { query } = useRouter()

  if (query.error) {
    throw new Error('This is a test error')
  }

  return (
    <div tw="text-left">
      <p>
        Welcome to the error test page. If you see this text, it&rsquo;s because no error has been
        forced. To force an error, please go to the following URL:
      </p>
      <p tw="text-primary-700">
        <Link to="/unified-analytics/error-test?error=true">
          {`${window.location.host}/unified-analytics/error-test?error=true`}
        </Link>
      </p>
      <p>
        If you see this code in production, please notify someone on the Unified Analytics team.
        Note that in DEV you will also see an overlay with a call stack.
      </p>
    </div>
  )
}
