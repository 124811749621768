import { useReactiveVar } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import {
  activeBizVar,
  useUApackageDataConnectorsListQuery,
} from '@brand-console/generated-graphql-hooks'
import { hasTokenExpired } from '@brand-console/utilities'
import { DeprecatedAlert, Spinner, useAppContext } from '@cart/ui'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { GeneralContainerFallback, pageErrorHandler } from './GeneralContainer.partials'
import { getExistConnectors } from './helpers/functions'
import { RouteList } from './routes/RouteList'
import { useAppDispatch, useAppSelector } from './stores/hooks/hooks'
import { closeAlert } from './stores/reducers/alertPropsSlice'
import { setHiddenVizHasLoaded } from './stores/reducers/dashboardSlice'
import { setToken } from './stores/reducers/tokenSlice'
import { useGetAnalyticsQuery } from './stores/services/analyticsEndpoints'
import { useLazyGetBusinessQuery } from './stores/services/businessEndpoints'
import { useLazyGetStatusesQuery } from './stores/services/choicesEndpoints'
import {
  useGetDataConnectorsQuery,
  useLazyGetDataConnectorsCardQuery,
} from './stores/services/dataConnectorEndpoints'
import { useGetTableauJWTQuery } from './stores/services/tableauEndpoints'

export const GeneralContainer = () => {
  const { uaTempUseTableauJwt220715 } = useFlags()
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { setMenuFilter } = useAppContext()
  const dispatch = useAppDispatch()

  const tokenInStore = useAppSelector((state) => state.tokenSlice.token)
  const alertProps = useAppSelector((state) => state.alertPropsSlice.alertProps)
  const organizationId = JSON.parse(localStorage.getItem('organization'))?.id
  const activeBiz = useReactiveVar(activeBizVar)
  const { loading: packageDataConnectorsListLoading } = useUApackageDataConnectorsListQuery({
    variables: {
      businessId: activeBiz?.id.toString(),
    },
  })

  // dynamic path
  const { data: analyticsData, isLoading: analyticsIsLoading } = useGetAnalyticsQuery(
    organizationId,
    {
      skip: !tokenInStore,
    },
  )
  const analytics = analyticsData?.data

  const { data: dataConnectors, isFetching: dataConnectorsIsFetching } = useGetDataConnectorsQuery(
    { organizationId },
    { skip: !tokenInStore },
  )

  const { data: tableauJWTData, refetch: refetchTableauJWT } = useGetTableauJWTQuery(
    organizationId,
    {
      skip: !uaTempUseTableauJwt220715 || !tokenInStore,
    },
  )

  const [getDataConnectorsCards] = useLazyGetDataConnectorsCardQuery()
  const [getBusiness] = useLazyGetBusinessQuery()
  const [getStatuses] = useLazyGetStatusesQuery()

  // TODO: Let's put these two things into redux (or context) so we don't have to memoize in very component
  const existingConnectors = useMemo(() => getExistConnectors(dataConnectors), [dataConnectors])
  const hasConnectors = existingConnectors.length > 0

  useEffect(() => {
    const token = tableauJWTData?.data?.token
    if (token && hasTokenExpired(token)) {
      dispatch(setHiddenVizHasLoaded(false))
      refetchTableauJWT()
    }
  }, [dispatch, refetchTableauJWT, tableauJWTData])

  useEffect(() => {
    if (!dataConnectorsIsFetching) {
      if (!hasConnectors) {
        setMenuFilter(['/unified-analytics/my-connectors'])
      } else {
        setMenuFilter()
      }
    }
  }, [dataConnectorsIsFetching, hasConnectors, setMenuFilter])

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const token = await getAccessTokenSilently()
        if ((!tokenInStore && token) || (tokenInStore && token && tokenInStore !== token)) {
          dispatch(setToken(token))
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any | unknown) {
        console.log(e.message)
      }
    }
    getUserMetadata()
  }, [tokenInStore, getAccessTokenSilently, dispatch])

  useEffect(() => {
    if (tokenInStore) {
      // pre-cache some things without blocking the UI
      getBusiness(undefined, true)
      getDataConnectorsCards(undefined, true)
      getStatuses(undefined, true)
    }
  }, [tokenInStore, getBusiness, getDataConnectorsCards, getStatuses])

  const analyticPaths = useMemo(() => {
    if (analytics) {
      const arr = Object.keys(analytics).map((key) =>
        analytics[key].children?.length > 0
          ? analytics[key].children.map((child) => `/${analytics[key].path}/${child.path}`)
          : `/${analytics[key].path}`,
      )
      return arr.flat()
    }
    return null
  }, [analytics])

  if (analyticsIsLoading || packageDataConnectorsListLoading) {
    return <Spinner tw="m-auto h-64 w-64" type="global" />
  }

  if (analyticPaths && analyticPaths.length > 0) {
    return (
      <div id="grid_general" tw="flex h-full w-full flex-col items-center p-2 md:p-4 lg:p-6">
        <ErrorBoundary FallbackComponent={GeneralContainerFallback} onError={pageErrorHandler}>
          {alertProps.map((alertProp) => (
            <DeprecatedAlert
              tw="mb-2 [max-width:1400px]"
              key={alertProp.id}
              type={alertProp.type}
              text={alertProp.text}
              heading={alertProp.heading}
              onClose={() => dispatch(closeAlert(alertProp.id))}
              show
              showIcon
            />
          ))}
          {isAuthenticated && tokenInStore && <RouteList analyticPaths={analyticPaths} />}
        </ErrorBoundary>
      </div>
    )
  }

  return null
}
