import React from 'react'

import maintenance from '../../../assets/images/maintenance.svg'

export const UnderMaintenance = () => {
  return (
    <div tw="top-1/2 left-1/2 w-full py-20 px-0 sm:(py-14 px-32) md:(relative p-16 translate-y-[-50%] translate-x-[-50%])">
      <div tw="flex flex-1 flex-col-reverse justify-center gap-8 md:(flex-row gap-6)">
        <div tw="flex flex-col items-center justify-center md:items-start">
          <h2>Under Maintenance</h2>
          <p tw="pt-2 text-center md:text-left">
            Sorry for the inconvenience. Application under maintenance and security updates. Please
            come back later.
          </p>
        </div>
        <div tw="flex flex-col items-center justify-center">
          <img src={maintenance} alt="" />
        </div>
      </div>
    </div>
  )
}
