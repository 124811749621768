import { useTooltip } from '@nivo/tooltip'
import React, { createElement, MouseEvent, useCallback } from 'react'

import { TooltipCard } from '../TooltipCard'

export function convertCentsToDollarString(valueInCents: number): string {
  const valueInDollars = valueInCents / 100
  return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(valueInDollars)
}

export function onlyEvenTicks(tickValue: number): string {
  if ((tickValue / 100) % 2 === 0) {
    return convertCentsToDollarString(tickValue)
  }
  return ''
}

function createTooltip(tooltip, bar) {
  return createElement(tooltip, { ...bar })
}

export const BarTooltip = (props) => {
  const {
    data: {
      formattedValue,
      data: { shipments, carrier },
    },
  } = props

  return (
    <TooltipCard>
      <table>
        <tr>
          <td>Carrier Name:</td>
          <td tw="font-bold">{carrier}</td>
        </tr>
        <tr>
          <td>Shipments:</td>
          <td tw="font-bold">{shipments}</td>
        </tr>
        <tr>
          <td>Cost per Pound:</td>
          <td tw="font-bold">{formattedValue}</td>
        </tr>
      </table>
    </TooltipCard>
  )
}

export const BarComponent = (props) => {
  const { bar, tooltip } = props
  const { showTooltipFromEvent, hideTooltip } = useTooltip()

  const renderTooltip = useCallback(() => createTooltip(tooltip, bar), [tooltip, bar])

  const handleTooltip = useCallback(
    (event: MouseEvent<SVGRectElement>) => showTooltipFromEvent(renderTooltip(), event),
    [showTooltipFromEvent, renderTooltip],
  )
  const handleMouseEnter = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      showTooltipFromEvent(renderTooltip(), event)
    },
    [showTooltipFromEvent, renderTooltip],
  )
  const handleMouseLeave = useCallback(() => {
    hideTooltip()
  }, [hideTooltip])

  const {
    formattedValue,
    data: { shipments },
  } = bar.data

  return (
    <g
      transform={`translate(${bar.x},${bar.y})`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleTooltip}
    >
      <rect width={bar.width} height={bar.height} fill={bar.color} />
      <text x={5} y={bar.height / 2} textAnchor="initial" dominantBaseline="central">
        <tspan tw="font-bold">{formattedValue}</tspan>{' '}
        <tspan tw="text-xs">({shipments} shipments)</tspan>
      </text>
    </g>
  )
}
