export const SHIPPING_COST_DATA = [
  {
    carrier: 'Fedex',
    shipments: 540,
    costInCents: 108,
  },
  {
    carrier: 'USPS',
    shipments: 270,
    costInCents: 751,
  },
  {
    carrier: 'UPS',
    shipments: 353,
    costInCents: 1051,
  },
  {
    carrier: 'DHL',
    shipments: 1,
    costInCents: 663,
  },
  {
    carrier: 'P. Exp',
    shipments: 100,
    costInCents: 100,
  },
]
