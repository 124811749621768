import { useReactiveVar } from '@apollo/client'
import {
  activeBizVar,
  useUApackageDataConnectorsListQuery,
} from '@brand-console/generated-graphql-hooks'
import { localStorageHelper, LocalStorageKeys } from '@brand-console/utilities'
import { Spinner, useRouter } from '@cart/ui'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useMemo, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { getExistConnectors } from '../helpers/functions'
import { AlertsPage } from '../pages/alertsPage/AlertsPage'
import { AnalyticsCommonPage } from '../pages/analytics/analyticsCommonPage/AnalyticsCommonPage'
import { ChannelMappingPage } from '../pages/ChannelMapping/ChannelMappingPage'
import { CustomViewsPage } from '../pages/CustomViews/CustomViewsPage'
import { Dashboard } from '../pages/dashboard/Dashboard/Dashboard'
import { DataHealth } from '../pages/DataHealth/DataHealth'
import { ErrorTestPage } from '../pages/ErrorTestPage/ErrorTestPage'
import { Fulfillment } from '../pages/Fulfillment/Fulfillment'
import { MyConnectorsPage } from '../pages/MyConnectors/MyConnectorsPage'
import { RoiPlaybook } from '../pages/RoiPlaybook/RoiPlaybook'
import { AllConnectors } from '../pages/settings/DataConnectors/AllConnectors'
import { useGetDataConnectorsQuery } from '../stores/services/dataConnectorEndpoints'

const EmailReports = React.lazy(() => import('../pages/EmailReports/EmailReports'))
const CreateEmailReportPage = React.lazy(() =>
  import('../pages/createEmailReportPage/CreateEmailReportPage'),
)
const ErrorPage404 = React.lazy(() => import('../pages/ErrorPage404/ErrorPage404'))
type PropsType = {
  analyticPaths: string[]
}

export const RouteList = ({ analyticPaths }: PropsType) => {
  const {
    uaTempShowEmailReports220520,
    uaTempShowAlertsPage220520,
    uaTempShowDataHealth,
    uaTempShowNewMyConnectorsTable221004,
    uaTempShowErrorTestPage221021,
    uaTempCampaignMapping230125,
    uaTempShowRoiGuidebook,
  } = useFlags()

  const activeBiz = useReactiveVar(activeBizVar)
  const { data: packageDataConnectorsList } = useUApackageDataConnectorsListQuery({
    variables: {
      businessId: activeBiz?.id.toString(),
    },
  })

  const [organizationId, setOrganizationId] = useState<string | undefined>(undefined)

  useEffect(() => {
    const orgId = localStorageHelper.get(LocalStorageKeys.ORGANIZATION)?.id
    setOrganizationId(orgId)
  }, [])

  const { data: dataConnectorsData, isFetching: dataConnectorsFetching } =
    useGetDataConnectorsQuery({ organizationId }, { skip: !organizationId })
  const hasDataConnectors = useMemo(() => {
    return getExistConnectors(dataConnectorsData).length > 0
  }, [dataConnectorsData])
  const isFreeTrial =
    packageDataConnectorsList?.ua_packagedataConnectorsList?.packageType.key === 'FreeTrial'

  const homeRoute = isFreeTrial ? 'roi-playbook' : 'dashboard'
  const router = useRouter()
  const { query } = router

  // @ts-ignore
  const { scope } = query

  return (
    <Routes>
      <Route path="/" element={<Navigate to={homeRoute} />} />
      <Route path="/connectors" element={<Navigate to="/unified-analytics/my-connectors" />} />
      <Route path="/custom-views" element={<CustomViewsPage />} />
      {uaTempCampaignMapping230125 && (
        <Route path="/channel-mapping" element={<ChannelMappingPage />} />
      )}
      {uaTempShowDataHealth && <Route path="/data-health" element={<DataHealth />} />}
      {uaTempShowRoiGuidebook && <Route path="/roi-playbook" element={<RoiPlaybook />} />}

      <Route path="/fulfillment" element={<Fulfillment />} />
      <Route path="/all-connectors" element={<AllConnectors />} />
      <Route path="/dashboard" element={<Dashboard />} />
      {uaTempShowNewMyConnectorsTable221004 && (
        <Route
          path="/my-connectors"
          element={
            hasDataConnectors || scope ? (
              <MyConnectorsPage />
            ) : (
              !dataConnectorsFetching &&
              dataConnectorsData !== undefined && (
                <Navigate to="/unified-analytics/all-connectors" />
              )
            )
          }
        />
      )}
      {uaTempShowAlertsPage220520 && <Route path="/alerts" element={<AlertsPage />} />}
      {uaTempShowAlertsPage220520 && <Route path="/alerts/create" element={<AlertsPage />} />}
      {uaTempShowEmailReports220520 && (
        <Route
          path="/email-reports"
          element={
            <React.Suspense fallback={<Spinner type="inline" />}>
              <EmailReports />
            </React.Suspense>
          }
        />
      )}
      {uaTempShowEmailReports220520 && (
        <Route
          path="/email-reports/create"
          element={
            <React.Suspense fallback={<Spinner type="inline" />}>
              <CreateEmailReportPage />
            </React.Suspense>
          }
        />
      )}
      {uaTempShowEmailReports220520 && (
        <Route path="/email-reports/:id" element={<CreateEmailReportPage />} />
      )}
      {analyticPaths.map(($path) => (
        <Route key="AnalyticsCommonPage" path={$path} element={<AnalyticsCommonPage />} />
      ))}
      {analyticPaths.map(($path) => (
        <Route
          key="AnalyticsCommonPage"
          path={`${$path}?custom-view=:custom-view`}
          element={<AnalyticsCommonPage />}
        />
      ))}
      {uaTempShowErrorTestPage221021 && <Route path="/error-test" element={<ErrorTestPage />} />}
      <Route
        path="*"
        element={
          <React.Suspense fallback={<Spinner type="inline" />}>
            <ErrorPage404 />
          </React.Suspense>
        }
      />
    </Routes>
  )
}
