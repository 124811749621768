import { useReactiveVar } from '@apollo/client'
import {
  activeOrgVar,
  useUADataConnectorsListQuery,
  useUADiscoverBlocksQuery,
} from '@brand-console/generated-graphql-hooks'
import { Button, Card, CardContent, Select, SelectItem, Spinner, Tooltip } from '@cart/ui'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useState } from 'react'

import AnalyticsJourney from '../../assets/images/AnalyticsJourney.png'
import CorrectRoas1 from '../../assets/images/CorrectRoas1.png'
import CorrectRoas2 from '../../assets/images/CorrectRoas2.png'
import DataHealth from '../../assets/images/DataHealth.png'
import IncorrectRoas1 from '../../assets/images/IncorrectRoas1.png'
import IncorrectRoas2 from '../../assets/images/IncorrectRoas2.png'
import IntelligenceBad from '../../assets/images/IntelligenceBad.png'
import IntelligenceGood from '../../assets/images/IntelligenceGood.png'
import MediaMixBad from '../../assets/images/MediaMixBad.png'
import QuestioningAccuracy from '../../assets/images/QuestioningAccuracy.png'
import RecommendedSpend from '../../assets/images/RecommendedSpend.png'
import ReportPrep from '../../assets/images/ReportPrep.png'
import SiloedBad from '../../assets/images/SiloedBad.png'
import SiloedGood from '../../assets/images/SiloedGood.png'
import SiloedMeh from '../../assets/images/SiloedMeh.png'
import TableauReport from '../../components/atoms/TableauReport/TableauReport'
import { UnderMaintenance } from '../../components/molecules/UnderMaintenance/UnderMaintenance'
import { useAppDispatch, useAppSelector } from '../../stores/hooks/hooks'
import { setHiddenVizHasLoaded } from '../../stores/reducers/dashboardSlice'
import {
  useGetTableauJWTQuery,
  useGetTrustedTicketQuery,
} from '../../stores/services/tableauEndpoints'
import { QuickStartGuide } from './RoiPlaybook.partials'

export const RoiPlaybook = () => {
  const [activeData, setActiveData] = useState<string>('sampleData')
  const activeOrg = useReactiveVar(activeOrgVar)
  const { uaTempUseTableauJwt220715 } = useFlags()
  const hiddenVizHasLoaded = useAppSelector((state) => state.dashboardSlice.hiddenVizHasLoaded)
  const dispatch = useAppDispatch()

  const orgId = JSON.parse(localStorage.getItem('organization'))?.id

  const { data: dataConnectorsList, loading: dataConnectorsListLoading } =
    useUADataConnectorsListQuery({
      variables: {
        organizationId: activeOrg?.id.toString(),
      },
    })

  const {
    data: ticketData,
    error: ticketError,
    isFetching: ticketIsFetching,
  } = useGetTrustedTicketQuery(orgId, {
    refetchOnMountOrArgChange: true,
    skip: uaTempUseTableauJwt220715,
  })
  const {
    data: tableauJWTData,
    error: tableauJWTError,
    isFetching: tableauJWTsFetching,
  } = useGetTableauJWTQuery(orgId, {
    refetchOnMountOrArgChange: true,
    skip: !uaTempUseTableauJwt220715,
  })
  const ticket = ticketData?.data?.ticket
  const token = tableauJWTData?.data?.token

  const { data: dataBlocksData, loading: dataBlocksLoading } = useUADiscoverBlocksQuery({
    variables: {
      isSampleData: false,
      organizationId: activeOrg?.id,
    },
  })

  const dataButtonClick = (destination) => {
    switch (destination) {
      case 'dataHealth':
        window.location.pathname = 'unified-analytics/data-health'
        break
      case 'attractOverview':
        window.location.pathname = 'unified-analytics/attract/overview'
        break
      case 'adjustAdSpend':
        window.location.pathname = 'unified-analytics/attract/adjust-ad-spend'
        break
      default:
        break
    }
  }

  useEffect(() => {
    let timer = null
    if (!hiddenVizHasLoaded && !uaTempUseTableauJwt220715) {
      timer = setTimeout(() => {
        dispatch(setHiddenVizHasLoaded(true))
      }, 4000)
    }
    return () => clearTimeout(timer)
  }, [dispatch, hiddenVizHasLoaded, uaTempUseTableauJwt220715])

  let switcherDisabled = false

  const tableauError = uaTempUseTableauJwt220715 ? tableauJWTError : ticketError
  if (tableauError) {
    if (
      ('status' in tableauError && tableauError.status === 500) ||
      ('originalStatus' in tableauError && tableauError.originalStatus === 500)
    ) {
      switcherDisabled = true
    }
  }

  if (
    !hiddenVizHasLoaded ||
    dataConnectorsListLoading ||
    dataBlocksLoading ||
    ticketIsFetching ||
    tableauJWTsFetching
  ) {
    return (
      <>
        <Spinner tw="m-auto h-64 w-64" type="global" />
        {!hiddenVizHasLoaded && dataBlocksData?.ua_discoverBlocks.length > 0 && (
          <div tw="hidden">
            {/* this fun little hack is necessary to maintain tableau auth with trusted ticket */}
            <TableauReport
              hidden
              url={dataBlocksData?.ua_discoverBlocks[0]?.subblocks[0]?.media[0]?.tableauUrl}
              token={uaTempUseTableauJwt220715 ? token : ticket}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <div tw="w-full px-0" data-testid="roi-playbook-container">
      <div tw="[max-width: 1200px] m-auto">
        <h2 tw="mb-4">Welcome to your Free Trial</h2>
        <QuickStartGuide dataConnectorsList={dataConnectorsList} />
        <div tw="mt-12 flex justify-between">
          <h2 tw="mb-6">Discover Unified Analytics</h2>
          <Select
            id="dataToggle"
            defaultValue="sampleData"
            disabled={switcherDisabled}
            onChange={(e) => {
              setActiveData(e.target.value as string)
            }}
            label=""
            size="small"
            tw="w-56"
          >
            <SelectItem value="sampleData">Sample Data</SelectItem>
            <SelectItem value="yourData">Your Data</SelectItem>
          </Select>
        </div>
        <h3>Siloed Data & Inflated ROAS</h3>
        <div tw="mt-2 mb-8 flex w-full flex-col gap-5 md:flex-row">
          <Card tw="flex w-full flex-col justify-between bg-accent-two-50 p-4">
            <CardContent tw="px-2.5">
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-two-100 p-2.5">
                  <FontAwesomeIcon tw="text-2xl text-accent-two-600" icon={solid('face-frown')} />
                </div>
                <h4 tw="[font-size: 18.8px]">Your data in platform silos</h4>
              </div>
              <p tw="mb-4 tracking-tighter">
                Each platform independently tracks and reports on your customer&apos;s journey. A
                single sale can be claimed by <strong>more than one</strong> platform, inflating
                your sales data and making a mess of your marketing optimization.
              </p>
              {activeData === 'sampleData' ? (
                <img tw="m-auto" src={SiloedBad} alt="Sample of siloed data" />
              ) : (
                <TableauReport
                  tw="m-auto"
                  url={
                    dataBlocksData?.ua_discoverBlocks[0]?.subblocks?.find(
                      ({ key }) => key === 'data_silos',
                    ).media[0]?.tableauUrl
                  }
                  options={{
                    width: '100%',
                    height: '580px',
                    hideTabs: false,
                  }}
                />
              )}
            </CardContent>
          </Card>
          <Card tw="flex w-full flex-col justify-between bg-accent-five-50 p-4">
            <CardContent tw="px-2.5">
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-five-100 p-2.5">
                  <FontAwesomeIcon tw="text-2xl text-accent-five-600" icon={solid('face-meh')} />
                </div>
                <h4 tw="[font-size: 18.8px]">De-duped with &lsquo;last touch&rsquo;</h4>
              </div>

              <p tw="mb-4">
                Google Analytics (GA) captures each conversion and assigns it to the last marketing
                touchpoint in the customer&apos;s journey. This de-duplication of your data gives
                you a cleaner look into reality.
              </p>
              {activeData === 'sampleData' ? (
                <img tw="m-auto" src={SiloedMeh} alt="Sample of siloed data" />
              ) : (
                <TableauReport
                  url={
                    dataBlocksData?.ua_discoverBlocks[0]?.subblocks?.find(
                      ({ key }) => key === 'deduped_last_touch',
                    ).media[0]?.tableauUrl
                  }
                  options={{
                    width: '100%',
                    height: '580px',
                    hideTabs: false,
                  }}
                />
              )}
              <p tw="[font-size: 10px] mt-2 pb-0">
                Google Analytics will never properly measure view-through conversions.
              </p>
            </CardContent>
          </Card>
          <Card tw="flex w-full flex-col justify-between bg-accent-six-50 p-4">
            <CardContent tw="px-2.5">
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-six-100 p-2.5">
                  <FontAwesomeIcon
                    tw="text-2xl text-accent-six-600"
                    icon={solid('face-grin-stars')}
                  />
                </div>
                <h4 tw="[font-size: 18.6px] [@media(min-width:1430px)]:whitespace-nowrap">
                  Harmonized with AI attribution
                </h4>
              </div>
              <p tw="mb-4">
                Ai driven Multi-Touch Attribution assigns credit to different points in the
                customer&apos;s journey and across the marketing lifecycle to help you understand
                the best places to spend your ad dollars.
              </p>
              {activeData === 'sampleData' ? (
                <img tw="m-auto" src={SiloedGood} alt="Sample of unsiloed data" />
              ) : (
                <TableauReport
                  url={
                    dataBlocksData?.ua_discoverBlocks[0]?.subblocks?.find(
                      ({ key }) => key === 'harmonized_ai_attribution',
                    ).media[0]?.tableauUrl
                  }
                  options={{
                    width: '100%',
                    height: '580px',
                    hideTabs: false,
                  }}
                />
              )}
              <div tw="mt-2 flex items-center">
                <p tw="pb-0 text-center text-xs">
                  Estimated real channel contribution with UA Plus.
                </p>
                <Tooltip
                  content="Our estimated contribution example reflects the variances we've observed across numerous clients, highlighting the differences between Last Touch and ML Attribution models. Keep in mind that multipliers can vary based on your specific marketing strategy and funnel investment. This offers a glimpse into what a typical customer experiences."
                  trigger="hover"
                  placement="top"
                >
                  <FontAwesomeIcon
                    tw="ml-2 text-sm text-accent-one-900"
                    icon={regular('circle-info')}
                  />
                </Tooltip>
              </div>
            </CardContent>
          </Card>
        </div>
        <h3>ROAS Accuracy & Channel Classification</h3>
        <div tw="mt-2 mb-8 flex w-full gap-5">
          <Card tw="flex w-full flex-col justify-between bg-accent-two-50 p-4">
            <CardContent tw="px-2.5">
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-two-100 p-2.5">
                  <FontAwesomeIcon tw="text-2xl text-accent-two-600" icon={solid('face-frown')} />
                </div>
                <h4 tw="text-xl">Incorrectly Reported ROAS</h4>
              </div>
              <p tw="mb-4">
                Like most marketers, you can have revenue sitting in an uncategorized bucket of
                &ldquo;Other&rdquo; due to missing or non-standard{' '}
                <a
                  href="https://ga-dev-tools.google/campaign-url-builder/"
                  tw="text-primary-700"
                  target="blank"
                >
                  Google Analytics UTM parameters
                </a>
                . This obscures the effectiveness of your ad platform&apos;s performance and hinders
                decision making. Stop leaving attributable revenue on the table and under-reporting
                on campaign effectiveness.
              </p>
              <p tw="mb-4">
                <a
                  href="https://cart.com/knowledge/utm-requirements-for-campaign-reporting-attribution-ai"
                  tw="text-primary-700"
                  target="blank"
                >
                  Read our UTM Guidelines
                </a>{' '}
                to can help solve this.
              </p>
              {activeData === 'sampleData' ? (
                <>
                  <img tw="mb-3" src={IncorrectRoas1} alt="Sample of Incorrect Reported ROAS" />
                  <img src={IncorrectRoas2} alt="Sample of Incorrect Reported ROAS" />
                </>
              ) : (
                <TableauReport
                  url={
                    dataBlocksData?.ua_discoverBlocks[1]?.subblocks?.find(
                      ({ key }) => key === 'incorrectly_reported_roas',
                    ).media[0]?.tableauUrl
                  }
                  options={{
                    width: '100%',
                    height: '480px',
                    hideTabs: false,
                  }}
                />
              )}
              <p tw="[font-size: 10px] mt-2 pb-0 text-center">
                Data is based on your ad spend & revenue that drives to your storefront
              </p>
            </CardContent>
          </Card>
          <Card tw="flex w-full flex-col justify-between bg-accent-six-50 p-4">
            <CardContent tw="px-2.5">
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-six-100 p-2.5">
                  <FontAwesomeIcon
                    tw="text-2xl text-accent-six-600"
                    icon={solid('face-grin-stars')}
                  />
                </div>
                <h4 tw="text-xl">Accurately Report on Your ROAS</h4>
              </div>
              <p tw="mb-8">
                With our automated channel reclassification feature, we enhance the accuracy of your
                data by cleaning your data for you. As a result, you can have confidence that all
                the campaign activity that contributes to your revenue is properly accounted for in
                your return on ad spend (ROAS) calculation.{' '}
                <a
                  href="https://cart.com/knowledge/utm-requirements-for-campaign-reporting-attribution-ai"
                  tw="text-primary-700"
                  target="blank"
                >
                  Read our UTM Guidelines
                </a>{' '}
                and update your channel mapping to improve the accuracy of your reported ROAS.
              </p>
              {activeData === 'sampleData' ? (
                <>
                  <img tw="mb-3" src={CorrectRoas1} alt="Sample of Correctly Reported ROAS" />
                  <img src={CorrectRoas2} alt="Sample of Correctly Reported ROAS" />
                </>
              ) : (
                <TableauReport
                  url={
                    dataBlocksData?.ua_discoverBlocks[1]?.subblocks?.find(
                      ({ key }) => key === 'accurately_reported_roas',
                    ).media[0]?.tableauUrl
                  }
                  options={{
                    width: '100%',
                    height: '480px',
                    hideTabs: false,
                  }}
                />
              )}
              <p tw="[font-size: 10px] mt-2 pb-0 text-center">
                Data is based on your ad spend & revenue that drives to your storefront
              </p>
            </CardContent>
          </Card>
        </div>
        {activeData !== 'sampleData' ? (
          <div tw="flex justify-between">
            <h3>Data Quality & Trust</h3>
            <Button size="small" variant="outlined" onClick={() => dataButtonClick('dataHealth')}>
              Launch Data Health
            </Button>
          </div>
        ) : (
          <h3>Data Quality & Trust</h3>
        )}
        <div tw="mt-2 mb-8 flex w-full gap-5">
          <Card tw="flex w-full flex-col justify-between bg-accent-two-50 p-4">
            <CardContent>
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-two-100 p-2.5">
                  <FontAwesomeIcon tw="text-2xl text-accent-two-600" icon={solid('face-frown')} />
                </div>
                <h4 tw="text-xl">Stop questioning if data is accurate</h4>
              </div>
              <p tw="mb-4">
                Say goodbye to <strong>opinions</strong> on data accuracy and <strong>know</strong>{' '}
                for certain your numbers are accurate.
              </p>
              <p tw="mb-4">
                Next time someone asks, “is this data accurate?” you can pull up our data health
                dashboard to prove it is!
              </p>
              <img tw="mb-6" src={QuestioningAccuracy} alt="Questioning Data Accuracy" />
              <div tw="flex justify-between gap-16">
                <div tw="flex flex-col">
                  <p>
                    <strong>Spent hours analyzing irrelevant data</strong>
                  </p>
                  <p tw="mb-2">(Story of my life)</p>
                  <p>
                    <strong>Found the source of the error</strong>
                  </p>
                  <p>(It was a typo...)</p>
                </div>
                <div tw="flex flex-col">
                  <p>
                    <strong>Corrected the mistake</strong>
                  </p>
                  <p tw="mb-2">(Ctrl + Z saves the day)</p>
                  <p>
                    <strong>Presented incorrect data to your stakeholder</strong>
                  </p>
                  <p>(You can&apos;t regain lost trust)</p>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card tw="flex w-full flex-col justify-between bg-accent-six-50 p-4">
            <CardContent tw="px-2">
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-six-100 p-2.5">
                  <FontAwesomeIcon
                    tw="text-2xl text-accent-six-600"
                    icon={solid('face-grin-stars')}
                  />
                </div>
                <h4 tw="text-xl">Know for certain your data is healthy every day</h4>
              </div>
              <p tw="mb-14">
                With our custom &lsquo;Data Health&rsquo; dashboard, be aware of what data is
                included/excluded in your analysis. Also, configure custom views and create alerts
                to monitor anomalies and build trust in you data.
              </p>
              <p tw="mb-3">
                <strong>Data Health</strong>
              </p>
              {activeData === 'sampleData' ? (
                <img tw="mb-3" src={DataHealth} alt="Sample of Healthy Data" />
              ) : (
                <TableauReport
                  url={
                    dataBlocksData?.ua_discoverBlocks[2]?.subblocks?.find(
                      ({ key }) => key === 'know_data_health',
                    ).media[0]?.tableauUrl
                  }
                  options={{
                    width: '100%',
                    height: '400px',
                    hideTabs: false,
                  }}
                />
              )}
            </CardContent>
          </Card>
        </div>
        {activeData !== 'sampleData' ? (
          <div tw="flex justify-between">
            <h3>Analytics Journey</h3>

            <Button
              size="small"
              variant="outlined"
              onClick={() => dataButtonClick('attractOverview')}
            >
              Launch Attract Overview
            </Button>
          </div>
        ) : (
          <h3>Analytics Journey</h3>
        )}
        <div tw="mt-2 mb-8 flex w-full gap-5">
          <Card tw="flex w-full flex-col justify-between bg-accent-two-50 p-4">
            <CardContent>
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-two-100 p-2.5">
                  <FontAwesomeIcon tw="text-2xl text-accent-two-600" icon={solid('face-frown')} />
                </div>
                <h4 tw="text-xl">Stop drowning in hours of report prep</h4>
              </div>
              <p tw="mb-10">
                On average, people spend 80% of their time putting together reports and only 20% of
                their time optimizing their marketing campaigns.
              </p>

              <img tw="mb-6" src={ReportPrep} alt="Hours of Report prep" />
              <div tw="flex justify-between gap-4">
                <div tw="flex flex-col">
                  <p>
                    <strong>Spent hours pulling data</strong>
                  </p>
                  <p tw="mb-2">(When you could have been acting on it)</p>
                  <p>
                    <strong>Too many tabs open to &ldquo;report&rdquo; #s</strong>
                  </p>
                  <p>(Don&apos;t make your computer sound like a jet engine)</p>
                </div>
                <div tw="flex flex-col">
                  <p>
                    <strong>Not catching the trend in time</strong>
                  </p>
                  <p tw="mb-2">(Don&apos;t just report the past; get on top of huge shifts)</p>
                  <p>
                    <strong>Not able to answer simple questions quickly</strong>
                  </p>
                  <p>(You can&apos;t regain lost trust)</p>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card tw="flex w-full flex-col justify-between bg-accent-six-50 p-4">
            <CardContent tw="px-1.5">
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-six-100 p-2.5">
                  <FontAwesomeIcon
                    tw="text-2xl text-accent-six-600"
                    icon={solid('face-grin-stars')}
                  />
                </div>
                <h4 tw="text-xl">Immediately jump into analysis</h4>
              </div>
              <p tw="mb-4">
                UA eliminates data collection hurdles and unlocks actionable insights instantly. Not
                only do we aggregate your data, we organize and display it using best-in-class
                visualizations.
              </p>
              {activeData === 'sampleData' ? (
                <img tw="mb-3" src={AnalyticsJourney} alt="Sample of good analytics" />
              ) : (
                <TableauReport
                  url={
                    dataBlocksData?.ua_discoverBlocks[3]?.subblocks?.find(
                      ({ key }) => key === 'jump_into_analysis',
                    ).media[0]?.tableauUrl
                  }
                  options={{
                    width: '100%',
                    height: '520px',
                    hideTabs: false,
                  }}
                />
              )}
            </CardContent>
          </Card>
        </div>
        {activeData !== 'sampleData' ? (
          <div tw="flex justify-between">
            <div tw="flex items-center">
              <h3>Media Mix Recommendations </h3>
              <Tooltip
                content="Media Mix Recommendations are based upon the maximization of blended return on ad spend using Google Analytics last touch revenue."
                trigger="hover"
                placement="right"
              >
                <FontAwesomeIcon
                  tw="ml-2 text-lg text-accent-one-900"
                  icon={regular('circle-info')}
                />
              </Tooltip>
            </div>

            <Button
              size="small"
              variant="outlined"
              onClick={() => dataButtonClick('adjustAdSpend')}
            >
              Launch Adjust Ad Spend
            </Button>
          </div>
        ) : (
          <div tw="flex items-center">
            <h3>Media Mix Recommendations </h3>
            <Tooltip
              content="Media Mix Recommendations are based upon the maximization of blended return on ad spend using Google Analytics last touch revenue."
              trigger="hover"
              placement="right"
            >
              <FontAwesomeIcon
                tw="ml-2 text-lg text-accent-one-900"
                icon={regular('circle-info')}
              />
            </Tooltip>
          </div>
        )}

        <div tw="mt-2 mb-8 flex w-full gap-5">
          <Card tw="flex w-full flex-col justify-between bg-accent-two-50 p-4">
            <CardContent tw="px-2.5">
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-two-100 p-2.5">
                  <FontAwesomeIcon tw="text-2xl text-accent-two-600" icon={solid('face-frown')} />
                </div>
                <h4 tw="text-xl">Current spend</h4>
              </div>
              <p tw="mb-2">
                <strong>Stop guessing with tedious media mix adjustments</strong>
              </p>
              <p tw="mb-10">
                By aggregating all of your marketing spend and results in one place, discover
                prescriptive ways to optimize Spend and grow Sales
              </p>
              {activeData === 'sampleData' ? (
                <img tw="mb-6" src={MediaMixBad} alt="Tedious Media Mix" />
              ) : (
                <TableauReport
                  url={
                    dataBlocksData?.ua_discoverBlocks[4]?.subblocks?.find(
                      ({ key }) => key === 'current_spend',
                    ).media[0]?.tableauUrl
                  }
                  options={{
                    width: '100%',
                    height: '480px',
                    hideTabs: false,
                  }}
                />
              )}
            </CardContent>
          </Card>
          <Card tw="flex w-full flex-col justify-between bg-accent-six-50 p-4">
            <CardContent tw="px-2.5">
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-six-100 p-2.5">
                  <FontAwesomeIcon
                    tw="text-2xl text-accent-six-600"
                    icon={solid('face-grin-stars')}
                  />
                </div>
                <h4 tw="text-xl">Recommended spend</h4>
              </div>
              <p tw="mb-2">
                <strong>Spend Recommendation:</strong>
              </p>
              <p tw="mb-4">
                Keeping overall Ad Spend budget of $25,408 the same, consider shifting $5,624 away
                from Facebook and into Google Ads to increase Return On Spend by 13.5%.
              </p>
              {activeData === 'sampleData' ? (
                <img tw="mb-3" src={RecommendedSpend} alt="Sample of recommended spend" />
              ) : (
                <TableauReport
                  url={
                    dataBlocksData?.ua_discoverBlocks[4]?.subblocks?.find(
                      ({ key }) => key === 'recommended_spend',
                    ).media[0]?.tableauUrl
                  }
                  options={{
                    width: '100%',
                    height: '480px',
                    hideTabs: false,
                  }}
                />
              )}
            </CardContent>
          </Card>
        </div>
        {activeData !== 'sampleData' ? (
          <div tw="flex justify-between">
            <h3>Intelligence</h3>
            <Button
              size="small"
              variant="outlined"
              onClick={() => dataButtonClick('adjustAdSpend')}
            >
              Launch Adjust Ad Spend
            </Button>
          </div>
        ) : (
          <h3>Intelligence</h3>
        )}

        <div tw="mt-2 mb-8 flex w-full gap-5">
          <Card tw="flex w-full flex-col justify-between bg-accent-two-50 p-4">
            <CardContent>
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-two-100 p-2.5">
                  <FontAwesomeIcon tw="text-2xl text-accent-two-600" icon={solid('face-frown')} />
                </div>
                <h4 tw="text-xl">Stop procrastinating & know where to focus efforts </h4>
              </div>
              <img tw="mb-6" src={IntelligenceBad} alt="Procrastinating" />
              <div tw="flex justify-between gap-3">
                <div tw="[min-width: 250px] flex flex-col">
                  <p>
                    <strong>Ineffective use of resources</strong>
                  </p>
                  <p tw="mb-2">Drain users&apos; resources, including time, effort, and manpower</p>
                  <p>
                    <strong>Missed opportunities</strong>
                  </p>
                  <p>Struggle to identify and capitalize on high-potential areas</p>
                </div>
                <div tw="[max-width: 240px] flex flex-col">
                  <p>
                    <strong>Inability to track campaign attribution accurately</strong>
                  </p>
                  <p tw="mb-2">
                    It can be challenging to accurately attribute conversions and measure the true
                    impact of marketing efforts
                  </p>
                  <p>
                    <strong>Limited scalability</strong>
                  </p>
                  <p>Lack of agility in decision making</p>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card tw="flex w-full flex-col justify-between bg-accent-six-50 p-4">
            <CardContent tw="px-2">
              <div tw="mb-4 flex items-center">
                <div tw="[width: 40px] [height: 40px] mr-2 flex items-center justify-center rounded-full bg-accent-six-100 p-2.5">
                  <FontAwesomeIcon
                    tw="text-2xl text-accent-six-600"
                    icon={solid('face-grin-stars')}
                  />
                </div>
                <h4 tw="text-xl">Personalized recommendations on a campaign level</h4>
              </div>
              <p tw="mb-4">
                Specifically tailored to your campaign needs, our analytics tool takes personalized
                recommendations to a whole new level.
              </p>
              <p tw="mb-2">
                <strong>Campaign Recommendation Quadrants</strong>
              </p>
              {activeData === 'sampleData' ? (
                <img
                  tw="mb-3"
                  src={IntelligenceGood}
                  alt="Scatter chart of good Intelligence data"
                />
              ) : (
                <TableauReport
                  url={
                    dataBlocksData?.ua_discoverBlocks[5]?.subblocks?.find(
                      ({ key }) => key === 'personalized_campaign_recommendations',
                    ).media[0]?.tableauUrl
                  }
                  options={{
                    width: '100%',
                    height: '380px',
                    hideTabs: false,
                  }}
                />
              )}
              <p tw="[font-size: 10px] mt-2 pb-0 text-center">
                This chart is utilizing Google Analytics, when you upgrade your free trial
                you&apos;ll get estimated MTA contributions
              </p>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}
