import { ComingSoonDataTypes } from '../types/dasboardTypes'
import { dsApi } from './dsApiSlice'

type ComingSoonResponseType = {
  data: ComingSoonDataTypes[]
}

const tableauEndpoints = dsApi.injectEndpoints({
  endpoints: (builder) => ({
    getComingSoon: builder.query<ComingSoonResponseType, void>({
      query: () => 'newscarousel/',
    }),
  }),
})

export const { useGetComingSoonQuery, useLazyGetComingSoonQuery } = tableauEndpoints
