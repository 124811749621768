import { DashboardDataTypes } from '../types/dasboardTypes'
import { dsApi } from './dsApiSlice'

type DashboardResponseType = {
  data: DashboardDataTypes[]
}

const dashboardEndpoints = dsApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<DashboardResponseType, string>({
      query: (organizationId) => {
        return `dashboard/${
          typeof organizationId !== 'undefined' ? `?organization_id=${organizationId}` : ''
        }`
      },
    }),
  }),
})

export const { useGetDashboardQuery, useLazyGetDashboardQuery } = dashboardEndpoints
