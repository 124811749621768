import { format } from 'date-fns'
import { useMemo, useReducer } from 'react'
import { theme } from 'twin.macro'

import { ACTION_TYPES, daysToGoBackReducer, queryData, sumByKey } from '../chartHelpers'
import { RETURNS_DATA } from '../ReturnsData'

export function useReturnsData() {
  const [daysToGoBack, dispatch] = useReducer(daysToGoBackReducer, 60)
  const widenDateRange = () => dispatch({ type: ACTION_TYPES.WIDEN })
  const narrowDateRange = () => dispatch({ type: ACTION_TYPES.NARROW })
  const slicedData = queryData(RETURNS_DATA, daysToGoBack)

  const data = useMemo(() => {
    const previousPeriod = slicedData.slice(0, daysToGoBack / 2)
    const currentPeriod = slicedData.slice(daysToGoBack / 2, daysToGoBack)

    const dataPrevious = previousPeriod.map((d) => {
      const xAsDate = new Date(d.DATE)
      return {
        // adds 30 days to the date to put the line on the same scale as the current data
        x: new Date(xAsDate.setDate(xAsDate.getDate() + daysToGoBack / 2)),
        // + converts the string to a number
        returns: +d.RETURNS,
      }
    })

    const dataCurrent = currentPeriod.map((d) => ({
      date: format(new Date(d.DATE), 'MMM dd'),
      // x: d.DATE,
      returns: +d.RETURNS,
      color: theme`colors.primary.700`,
    }))

    const currentTotal = sumByKey(dataCurrent, 'returns')
    const previousTotal = sumByKey(dataPrevious, 'returns')

    const percentChange = ((currentTotal - previousTotal) / previousTotal) * 100
    return { percentChange, currentTotal, previousTotal, series: dataCurrent }
  }, [daysToGoBack, slicedData])

  return {
    data,
    daysToGoBack,
    widenDateRange,
    narrowDateRange,
    slicedData,
  }
}
