import { nanoid } from 'nanoid'
import React, { ReactElement } from 'react'

export const UnifiedAnalyticsIcon = ({ className }: { className?: string }): ReactElement => {
  const title = 'Unified Analytics Icon'
  const idA = nanoid()
  const idB = nanoid()
  const idC = nanoid()
  return (
    <svg
      aria-label={title}
      className={className}
      fill="none"
      role="img"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11775_76153)">
        <path
          d="M48.0585 12.822V29.4786C48.0585 31.0872 47.2018 32.5733 45.8089 33.3776L37.8477 37.9759V30.5044C37.8477 28.8958 36.991 27.4097 35.5981 26.6054L29.1289 22.8696L22.6655 26.6112C21.2726 27.4155 20.4159 28.9016 20.4159 30.5102V37.9818L12.4547 33.3834C11.0618 32.5792 10.2051 31.093 10.2051 29.4844V12.8278C10.2051 11.2193 11.0618 9.73311 12.4547 8.92883L26.8909 0.60052C28.278 -0.203754 29.9914 -0.203754 31.3844 0.60052L45.8147 8.93466C47.2018 9.73311 48.0585 11.2193 48.0585 12.822Z"
          fill="#069AE3"
        />
        <path
          d="M58.281 30.51V47.1667C58.281 48.7753 57.4243 50.2614 56.0314 51.0657L41.5952 59.3999C40.2082 60.2042 38.4947 60.2042 37.1018 59.3999L29.1406 54.8015H29.1348L22.6657 51.0657C21.3369 50.3022 20.4976 48.9152 20.4218 47.394C20.416 47.3241 20.416 47.2483 20.416 47.1726V37.9641L26.8852 41.7C28.2723 42.5042 29.9857 42.5042 31.3786 41.7L37.8478 37.9641V30.5042C37.8478 28.8956 36.9911 27.4094 35.5981 26.6052L29.129 22.8693L37.096 18.2768C38.483 17.4725 40.1965 17.4725 41.5894 18.2768L48.0586 22.0126L56.0197 26.611C57.4126 27.4153 58.2693 28.9014 58.281 30.51Z"
          fill="#DA7009"
        />
        <path
          d="M37.8591 37.9761V47.1786C37.8591 47.2544 37.8591 47.3243 37.8533 47.4001C37.7776 48.9212 36.9383 50.3024 35.6095 51.0717L29.1404 54.8075H29.1345L21.1734 59.4001C19.7863 60.2043 18.0728 60.2043 16.6799 59.4001L2.24964 51.0659C0.856726 50.2616 0 48.7755 0 47.1669V30.5103C0 28.9018 0.856726 27.4156 2.24964 26.6113L10.2108 22.013L10.2166 22.0072L16.6858 18.2714C18.0728 17.4671 19.7863 17.4671 21.1792 18.2714L29.1404 22.8755L22.677 26.6172C21.2841 27.4214 20.4274 28.9076 20.4274 30.5161V37.9877L26.8907 41.7177C28.2778 42.522 29.9913 42.522 31.3842 41.7177L37.8591 37.9761Z"
          fill="#005982"
        />
        <path
          d="M48.0585 22.0184V29.4842C48.0585 31.0927 47.2018 32.5789 45.8088 33.3832L37.8477 37.9815V30.5041C37.8477 28.8955 36.991 27.4094 35.5981 26.6051L29.1289 22.8693L37.0959 18.2768C38.483 17.4725 40.1964 17.4725 41.5893 18.2768L48.0585 22.0184Z"
          fill={`url(#${idA})`}
        />
        <path
          d="M20.4159 37.9762V30.5104C20.4159 28.9018 21.2726 27.4157 22.6655 26.6114L29.1346 22.8697L21.1735 18.2714C19.7806 17.4671 18.0672 17.4671 16.6801 18.2714L10.2109 22.0072V29.4788C10.2109 31.0874 11.0677 32.5735 12.4606 33.3778L20.4159 37.9762Z"
          fill={`url(#${idB})`}
        />
        <path
          d="M31.3845 41.712C29.9916 42.5163 28.2781 42.5163 26.891 41.712L20.4219 37.9762V47.1729C20.4219 48.7814 21.2786 50.2676 22.6715 51.0719L29.1407 54.8077L35.6098 51.0719C37.0027 50.2676 37.8595 48.7814 37.8595 47.1729V37.9762L31.3845 41.712Z"
          fill={`url(#${idC})`}
        />
      </g>
      <defs>
        <linearGradient
          id={idA}
          x1="31.6483"
          y1="41.127"
          x2="50.0976"
          y2="2.77942"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1151" stopColor="#069AE3" />
          <stop offset="0.8156" stopColor="#DA7009" />
        </linearGradient>
        <linearGradient
          id={idB}
          x1="1.37802"
          y1="22.9281"
          x2="35.9373"
          y2="32.0295"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA7009" />
          <stop offset="0.286" stopColor="#069AE3" />
          <stop offset="0.8828" stopColor="#005982" />
        </linearGradient>
        <linearGradient
          id={idC}
          x1="21.2844"
          y1="68.9756"
          x2="32.8921"
          y2="30.4591"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2804" stopColor="#DA7009" />
          <stop offset="0.4483" stopColor="#069AE3" />
          <stop offset="0.8511" stopColor="#005982" />
        </linearGradient>
        <clipPath id="clip0_11775_76153">
          <rect width="58.2807" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
