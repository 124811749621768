import { IMenu } from '../../Menu/Menu.types'
import { helpMenu } from '../Shared/help.menu'
import { UaMenuIds } from './UaMenu.types'

export const uaMenuBase = [
  {
    id: 'ua',
    items: [
      {
        title: 'ROI Playbook',
        url: '/unified-analytics/roi-playbook',
        icon: 'faStar',
        hidden: true,
        id: UaMenuIds.ROI_PLAYBOOK,
      },
      {
        title: 'Dashboard',
        url: '/unified-analytics/dashboard',
        icon: 'faGaugeHigh',
      },
      {
        title: 'Custom Views',
        url: '/unified-analytics/custom-views',
        icon: 'faBookmark',
      },
    ],
  },
  {
    title: 'Reports',
    items: [],
    hidden: true,
    id: UaMenuIds.REPORTS,
  },
  {
    title: 'Settings',
    icon: 'faCog',
    id: 'settings',
    items: [
      {
        title: 'Data Connectors',
        icon: 'faDatabase',
        items: [
          {
            title: 'My Connectors',
            url: '/unified-analytics/my-connectors',
            hidden: true,
            id: UaMenuIds.DATA_CONNECTORS,
          },
          {
            title: 'All Connectors',
            url: '/unified-analytics/all-connectors',
          },
        ],
      },
      {
        title: 'Data Health',
        url: '/unified-analytics/data-health',
        icon: 'faHeartPulse',
        hidden: true,
        id: UaMenuIds.DATA_HEALTH,
      },
      {
        title: 'Channel Mapping',
        url: '/unified-analytics/channel-mapping',
        icon: 'faBookmark',
        hidden: true,
        id: UaMenuIds.CHANNEL_MAPPING,
      },
      {
        title: 'Email Reports',
        url: '/unified-analytics/email-reports',
        icon: 'faEnvelope',
        hidden: true,
        id: UaMenuIds.EMAIL_REPORTS,
      },
      {
        title: 'Alerts',
        url: '/unified-analytics/alerts/create',
        icon: 'faThumbtack',
        hidden: true,
        id: UaMenuIds.ALERTS,
      },
      ...[helpMenu],
    ],
  },
] as IMenu[]
