import { useReactiveVar } from '@apollo/client'
import { activeOrgVar } from '@brand-console/generated-graphql-hooks'
import { Spinner } from '@cart/ui'
import React from 'react'

import TableauReport from '../../components/atoms/TableauReport/TableauReport'
import { useGetAnalyticsQuery } from '../../stores/services/analyticsEndpoints'
import { useGetTrustedTicketQuery } from '../../stores/services/tableauEndpoints'

export const DataHealth = () => {
  const activeOrg = useReactiveVar(activeOrgVar)
  const orgId = activeOrg?.id.toString()

  // for correct iframe height
  const iframeHeight = '1550px'
  const { data: ticketData, isFetching: ticketIsFetching } = useGetTrustedTicketQuery(orgId, {
    refetchOnMountOrArgChange: true,
  })
  const {
    data: analyticsResponse,
    isLoading: analyticsIsLoading,
    isFetching: analyticsIsFetching,
  } = useGetAnalyticsQuery(orgId)
  const analyticsData = analyticsResponse?.data

  const isLoading = analyticsIsLoading || analyticsIsFetching || ticketIsFetching
  const ticket = ticketData?.data?.ticket
  const link = analyticsData?.DataHealth?.link

  if (isLoading) {
    return <Spinner tw="m-auto h-64 w-64" type="global" />
  }

  return (
    <div tw="w-full px-0" data-testid="data-health-container">
      <div tw="[max-width: 1440px] m-auto">
        <h2 tw="mb-3 ml-11">Data Analytics Health Check</h2>
        <TableauReport
          url={link}
          token={ticket}
          options={{
            width: '100%',
            height: iframeHeight,
            hideTabs: false,
          }}
          query="?:embed=yes&:toolbar=no"
        />
      </div>
    </div>
  )
}
