import { useFlags } from 'launchdarkly-react-client-sdk'

import { Menu } from '../../Menu/Menu'
import { showHideMenuItems } from '../Shared/visibility.helper'
import { uaMenuBase } from './UaMenu.menu'
import { UaMenuIds } from './UaMenu.types'
import { useUaMenuData } from './useUaMenuData'

export const UaMenu = () => {
  const {
    uaTempShowEmailReports220520,
    uaTempShowAlertsPage220520,
    uaTempCampaignMapping230125,
    uaTempShowDataHealth,
  } = useFlags()

  const { isVizEnabled, reportsMenuItems, isFreeTrialUser, hasDataConnectors } = useUaMenuData()

  const menu = reportsMenuItems ? uaMenuBase : []
  // enhance menu with options

  if (menu.length) {
    if (reportsMenuItems.length) {
      menu[1].items = reportsMenuItems
    } else {
      menu[1].hidden = true
    }

    const mainMenuVisibility = {
      [UaMenuIds.ROI_PLAYBOOK]: isFreeTrialUser,
    }

    showHideMenuItems(menu[0].items, mainMenuVisibility)

    const menuVisibility = {
      [UaMenuIds.CHANNEL_MAPPING]: uaTempCampaignMapping230125,
      [UaMenuIds.EMAIL_REPORTS]: uaTempShowEmailReports220520,
      [UaMenuIds.ALERTS]: uaTempShowAlertsPage220520,
      [UaMenuIds.DATA_HEALTH]: uaTempShowDataHealth && isVizEnabled,
    }

    showHideMenuItems(menu[2].items, menuVisibility)

    const connectorsMenuVisibility = {
      [UaMenuIds.DATA_CONNECTORS]: hasDataConnectors,
    }

    showHideMenuItems(menu[2].items[0].items, connectorsMenuVisibility)
  }

  return <Menu menuSections={menu} />
}
