// common
import { logger } from '@brand-console/utilities'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useRef } from 'react'

import { useAppDispatch } from '../../../stores/hooks/hooks'
import { displayAlert } from '../../../stores/reducers/alertPropsSlice'
import { setHiddenVizHasLoaded } from '../../../stores/reducers/dashboardSlice'

type OptionsType = {
  height?: string
  width?: string
  hideTabs?: boolean
  device?: string
}

type PropsType = {
  url?: string
  token?: string
  options?: OptionsType
  query?: string
  hidden?: boolean
}

const TableauReport: React.FC<PropsType> = ({ url, options, query, token, hidden, ...props }) => {
  const log = logger.setLogger(logger.LoggerNames.UNIFIED_ANALYTICS)
  const { uaTempUseTableauJwt220715 } = useFlags()
  const dispatch = useAppDispatch()
  const ref = useRef(null)

  useEffect(() => {
    const { current } = ref
    const handleFirstVizSizeKnown = () => {
      dispatch(setHiddenVizHasLoaded(true))
    }
    let timer = null
    if (hidden && current) {
      current.addEventListener('firstvizsizeknown', handleFirstVizSizeKnown)
      // this timeout goes to eleven
      timer = setTimeout(() => {
        dispatch(setHiddenVizHasLoaded(true))
        dispatch(
          displayAlert({
            heading: 'Error: unable to authenticate with Tableau server',
            type: 'error',
            text: 'Please try refreshing the page or try again later.',
          }),
        )
        log.error('Error authenticating with Tableau server')
      }, 11000)
    }

    return () => {
      if (hidden && current) {
        current.removeEventListener('firstvizsizeknown', handleFirstVizSizeKnown)
      }
      clearTimeout(timer)
    }
  }, [dispatch, hidden])

  const urlParse = new URL(url)

  if (uaTempUseTableauJwt220715) {
    return (
      // @ts-ignore
      <tableau-viz
        style={{ display: hidden ? 'none' : 'block' }}
        ref={ref}
        hideTabs={options?.hideTabs}
        toolbar="hidden"
        device={options?.device}
        height={options?.height}
        width={options?.width}
        token={token}
        src={url}
      />
    )
  }

  // This can be removed once trusted ticket is completely turned off and we're using JWTs everywhere
  return (
    // @ts-ignore
    <tableau-viz
      style={{ display: hidden ? 'none' : 'block' }}
      ref={ref}
      hideTabs={options?.hideTabs}
      toolbar="hidden"
      height={options?.height}
      width={options?.width}
      src={
        token ? `${urlParse.protocol}//${urlParse.host}/trusted/${token}${urlParse.pathname}` : url
      }
    />
  )
}

export default TableauReport
