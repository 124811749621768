import { Spinner } from '@cart/ui'
import React, { useState } from 'react'

import no_connectors from '../../assets/images/no-connectors.png'

export const ChannelMappingPage = () => {
  const [noChannels, setNoChannels] = useState(false)

  const loading = false

  if (loading) {
    return <Spinner tw="m-auto h-64 w-64" type="global" />
  }

  if (noChannels) {
    return (
      <div
        tw="top-1/2 left-1/2 w-full py-20 px-0 sm:(py-14 px-32) md:(absolute p-16 translate-y-[-50%] translate-x-[-50%])"
        data-testid="channel-mapping-empty-container"
      >
        <div tw="flex flex-1 flex-col justify-center md:flex-row">
          <div tw="flex w-full flex-col items-center justify-center md:(w-1/2 items-start)">
            <h1 tw="mb-0">Marketing Channel Mapping</h1>
            <p tw="mb-0 text-center pt-[8px] pb-[32px] md:text-left">
              Placeholder text to be displayed if no channels to map.
            </p>
          </div>
          <div tw="flex flex-col items-center justify-center">
            <img src={no_connectors} alt="custom views" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div tw="w-full px-0" data-testid="channel-mapping-container">
      <div tw="m-auto [max-width: 1440px]">
        <h2 tw="mb-6">Marketing Channel Mapping</h2>
        <p>placeholder content to be replaced by table.</p>
      </div>
    </div>
  )
}
