import { RolesNames } from '@brand-console/types'

/** Checks if the role is business admin or above. */
export const isAtLeastBizAdmin = (roleName: string) => {
  if (!roleName) return false

  return [
    RolesNames.BUSINESS_ADMINISTRATOR,
    RolesNames.ORGANIZATION_ADMINISTRATOR,
    RolesNames.ORGANIZATION_MANAGER,
    RolesNames.CUSTOMER_SALES_REP,
    RolesNames.SUPER_ADMIN,
  ].includes(roleName as RolesNames)
}

/** Checks if the role is org admin or above. */
export const isAtLeastOrgAdmin = (roleName: string) => {
  if (!roleName) return false

  return [
    RolesNames.ORGANIZATION_ADMINISTRATOR,
    RolesNames.CUSTOMER_SALES_REP,
    RolesNames.SUPER_ADMIN,
  ].includes(roleName as RolesNames)
}

export const isAtLeastOrgManager = (roleName: string) => {
  if (!roleName) return false

  return [
    RolesNames.ORGANIZATION_MANAGER,
    RolesNames.ORGANIZATION_ADMINISTRATOR,
    RolesNames.CUSTOMER_SALES_REP,
    RolesNames.SUPER_ADMIN,
  ].includes(roleName as RolesNames)
}

/** Checks if the role is org level */
export const isOrgRole = (roleName: string) => {
  if (!roleName) return false

  return [
    RolesNames.ORGANIZATION_MEMBER,
    RolesNames.ORGANIZATION_ADMINISTRATOR,
    RolesNames.ORGANIZATION_MANAGER,
    RolesNames.CUSTOMER_SALES_REP,
    RolesNames.SUPER_ADMIN,
  ].includes(roleName as RolesNames)
}
