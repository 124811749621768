import { Menu } from '../../Menu/Menu'
import { rptMenu } from './RptMenu.menu'

export const RptMenu = () => {

  const menu = rptMenu
  // enhance menu with options

  return <Menu menuSections={menu} />
}
