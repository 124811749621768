import { ResponsiveBar } from '@nivo/bar'
import React from 'react'
import { theme } from 'twin.macro'

import { ChartHeader } from '../ChartHeader'
import { CHART_MARGIN } from '../chartHelpers'
import { customTimeTicks, ReturnsChartTooltip } from './ReturnsChart.partials'
import { useReturnsData } from './useReturnsData'

export const ReturnsChart = ({ title }) => {
  const { data } = useReturnsData()

  return (
    <div>
      <ChartHeader
        title={title}
        percentChange={data.percentChange}
        currentTotal={data.currentTotal}
      />
      <div tw="w-full h-64">
        <ResponsiveBar
          indexBy="date"
          keys={['returns']}
          animate
          data={data.series}
          margin={CHART_MARGIN}
          enableGridX={false}
          enableGridY={false}
          colors={() => {
            return theme`colors.monochrome.500`
          }}
          enableLabel={false}
          axisBottom={{
            renderTick: customTimeTicks,
            tickSize: 0,
            tickPadding: 10,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
          }}
          tooltip={ReturnsChartTooltip}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: theme`colors.monochrome.500`,
                  strokeWidth: 1,
                },
              },
            },
          }}
        />
      </div>
    </div>
  )
}
