export const SessionsByDate = [
  // { DATE: '5/1/22', SESSIONS: '1501' },
  { DATE: '5/2/22', SESSIONS: '2365' },
  { DATE: '5/3/22', SESSIONS: '1443' },
  { DATE: '5/4/22', SESSIONS: '1126' },
  { DATE: '5/5/22', SESSIONS: '1190' },
  { DATE: '5/6/22', SESSIONS: '1063' },
  { DATE: '5/7/22', SESSIONS: '1006' },
  { DATE: '5/8/22', SESSIONS: '1102' },
  { DATE: '5/9/22', SESSIONS: '1369' },
  { DATE: '5/10/22', SESSIONS: '1095' },
  { DATE: '5/11/22', SESSIONS: '1153' },
  { DATE: '5/12/22', SESSIONS: '1053' },
  { DATE: '5/13/22', SESSIONS: '915' },
  { DATE: '5/14/22', SESSIONS: '1100' },
  { DATE: '5/15/22', SESSIONS: '1222' },
  { DATE: '5/16/22', SESSIONS: '1257' },
  { DATE: '5/17/22', SESSIONS: '984' },
  { DATE: '5/18/22', SESSIONS: '987' },
  { DATE: '5/19/22', SESSIONS: '1007' },
  { DATE: '5/20/22', SESSIONS: '853' },
  { DATE: '5/21/22', SESSIONS: '803' },
  { DATE: '5/22/22', SESSIONS: '793' },
  { DATE: '5/23/22', SESSIONS: '749' },
  { DATE: '5/24/22', SESSIONS: '696' },
  { DATE: '5/25/22', SESSIONS: '658' },
  { DATE: '5/26/22', SESSIONS: '702' },
  { DATE: '5/27/22', SESSIONS: '667' },
  { DATE: '5/28/22', SESSIONS: '747' },
  { DATE: '5/29/22', SESSIONS: '641' },
  { DATE: '5/30/22', SESSIONS: '716' },
  { DATE: '5/31/22', SESSIONS: '810' },
  { DATE: '6/1/22', SESSIONS: '897' },
  { DATE: '6/2/22', SESSIONS: '898' },
  { DATE: '6/3/22', SESSIONS: '811' },
  { DATE: '6/4/22', SESSIONS: '1004' },
  { DATE: '6/5/22', SESSIONS: '938' },
  { DATE: '6/6/22', SESSIONS: '1109' },
  { DATE: '6/7/22', SESSIONS: '1008' },
  { DATE: '6/8/22', SESSIONS: '1201' },
  { DATE: '6/9/22', SESSIONS: '1167' },
  { DATE: '6/10/22', SESSIONS: '1290' },
  { DATE: '6/11/22', SESSIONS: '1200' },
  { DATE: '6/12/22', SESSIONS: '1277' },
  { DATE: '6/13/22', SESSIONS: '1272' },
  { DATE: '6/14/22', SESSIONS: '1142' },
  { DATE: '6/15/22', SESSIONS: '1295' },
  { DATE: '6/16/22', SESSIONS: '1100' },
  { DATE: '6/17/22', SESSIONS: '1273' },
  { DATE: '6/18/22', SESSIONS: '1326' },
  { DATE: '6/19/22', SESSIONS: '1259' },
  { DATE: '6/20/22', SESSIONS: '1329' },
  { DATE: '6/21/22', SESSIONS: '1199' },
  { DATE: '6/22/22', SESSIONS: '1250' },
  { DATE: '6/23/22', SESSIONS: '1315' },
  { DATE: '6/24/22', SESSIONS: '1062' },
  { DATE: '6/25/22', SESSIONS: '1294' },
  { DATE: '6/26/22', SESSIONS: '1302' },
  { DATE: '6/27/22', SESSIONS: '1247' },
  { DATE: '6/28/22', SESSIONS: '1195' },
  { DATE: '6/29/22', SESSIONS: '1060' },
  { DATE: '6/30/22', SESSIONS: '1217' },
]
