import React from 'react'

import { PageContainer } from '../../components/atoms/PageContainer/PageContainer'

export const AlertsPage = () => {
  return (
    <PageContainer>
      <h2>Alerts coming soon</h2>
    </PageContainer>
  )
}
