import { MenuItem } from '../MenuItem/MenuItem'
import { SubMenu } from '../SubMenu/SubMenu'

export const MenuRenderer = ({ item, activeItem, setActiveItem }) => {
  if (item.hidden) return null

  return item.items ? (
    <SubMenu submenu={item} activeItem={activeItem} setActiveItem={setActiveItem} level={0} />
  ) : (
    <MenuItem activeItem={activeItem} setActiveItem={setActiveItem} item={item} />
  )
}
