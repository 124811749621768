export const ALL_DATA = [
  {
    DATE: '2022-08-14',
    SESSIONS: '374',
    REVENUE: '2812.33',
    ORDERS: '26',
  },
  {
    DATE: '2022-08-13',
    SESSIONS: '372',
    REVENUE: '2701.87',
    ORDERS: '27',
  },
  {
    DATE: '2022-08-12',
    SESSIONS: '419',
    REVENUE: '4375.62',
    ORDERS: '42',
  },
  {
    DATE: '2022-08-11',
    SESSIONS: '377',
    REVENUE: '4510.47',
    ORDERS: '37',
  },
  {
    DATE: '2022-08-10',
    SESSIONS: '272',
    REVENUE: '3034.12',
    ORDERS: '21',
  },
  {
    DATE: '2022-08-09',
    SESSIONS: '267',
    REVENUE: '2693.84',
    ORDERS: '23',
  },
  {
    DATE: '2022-08-08',
    SESSIONS: '360',
    REVENUE: '6770.3',
    ORDERS: '37',
  },
  {
    DATE: '2022-08-07',
    SESSIONS: '398',
    REVENUE: '2363.75',
    ORDERS: '23',
  },
  {
    DATE: '2022-08-06',
    SESSIONS: '426',
    REVENUE: '4437.92',
    ORDERS: '29',
  },
  {
    DATE: '2022-08-05',
    SESSIONS: '398',
    REVENUE: '4408.55',
    ORDERS: '40',
  },
  {
    DATE: '2022-08-04',
    SESSIONS: '456',
    REVENUE: '4399.15',
    ORDERS: '48',
  },
  {
    DATE: '2022-08-03',
    SESSIONS: '421',
    REVENUE: '3202.62',
    ORDERS: '25',
  },
  {
    DATE: '2022-08-02',
    SESSIONS: '438',
    REVENUE: '5473.22',
    ORDERS: '41',
  },
  {
    DATE: '2022-08-01',
    SESSIONS: '438',
    REVENUE: '3857.98',
    ORDERS: '41',
  },
  {
    DATE: '2022-07-31',
    SESSIONS: '344',
    REVENUE: '4748.62',
    ORDERS: '33',
  },
  {
    DATE: '2022-07-30',
    SESSIONS: '379',
    REVENUE: '3077.73',
    ORDERS: '27',
  },
  {
    DATE: '2022-07-29',
    SESSIONS: '470',
    REVENUE: '4365.16',
    ORDERS: '42',
  },
  {
    DATE: '2022-07-28',
    SESSIONS: '419',
    REVENUE: '3612.58',
    ORDERS: '27',
  },
  {
    DATE: '2022-07-27',
    SESSIONS: '427',
    REVENUE: '3991.29',
    ORDERS: '31',
  },
  {
    DATE: '2022-07-26',
    SESSIONS: '504',
    REVENUE: '6034.01',
    ORDERS: '47',
  },
  {
    DATE: '2022-07-25',
    SESSIONS: '825',
    REVENUE: '5380.01',
    ORDERS: '41',
  },
  {
    DATE: '2022-07-24',
    SESSIONS: '412',
    REVENUE: '4494.17',
    ORDERS: '34',
  },
  {
    DATE: '2022-07-23',
    SESSIONS: '432',
    REVENUE: '1922.23',
    ORDERS: '34',
  },
  {
    DATE: '2022-07-22',
    SESSIONS: '472',
    REVENUE: '6609.76',
    ORDERS: '44',
  },
  {
    DATE: '2022-07-21',
    SESSIONS: '569',
    REVENUE: '4469.11',
    ORDERS: '48',
  },
  {
    DATE: '2022-07-20',
    SESSIONS: '562',
    REVENUE: '5639.42',
    ORDERS: '54',
  },
  {
    DATE: '2022-07-19',
    SESSIONS: '533',
    REVENUE: '4644.6',
    ORDERS: '54',
  },
  {
    DATE: '2022-07-18',
    SESSIONS: '634',
    REVENUE: '5599.45',
    ORDERS: '59',
  },
  {
    DATE: '2022-07-17',
    SESSIONS: '500',
    REVENUE: '4432.18',
    ORDERS: '29',
  },
  {
    DATE: '2022-07-16',
    SESSIONS: '490',
    REVENUE: '4708.28',
    ORDERS: '40',
  },
  {
    DATE: '2022-07-15',
    SESSIONS: '537',
    REVENUE: '4652.18',
    ORDERS: '56',
  },
  {
    DATE: '2022-07-14',
    SESSIONS: '549',
    REVENUE: '7282.16',
    ORDERS: '73',
  },
  {
    DATE: '2022-07-13',
    SESSIONS: '494',
    REVENUE: '5535.32',
    ORDERS: '43',
  },
  {
    DATE: '2022-07-12',
    SESSIONS: '440',
    REVENUE: '4516.27',
    ORDERS: '35',
  },
  {
    DATE: '2022-07-11',
    SESSIONS: '574',
    REVENUE: '5674.83',
    ORDERS: '52',
  },
  {
    DATE: '2022-07-10',
    SESSIONS: '432',
    REVENUE: '4225.03',
    ORDERS: '34',
  },
  {
    DATE: '2022-07-09',
    SESSIONS: '445',
    REVENUE: '4915.9',
    ORDERS: '47',
  },
  {
    DATE: '2022-07-08',
    SESSIONS: '553',
    REVENUE: '7712.02',
    ORDERS: '59',
  },
  {
    DATE: '2022-07-07',
    SESSIONS: '591',
    REVENUE: '7083.34',
    ORDERS: '46',
  },
  {
    DATE: '2022-07-06',
    SESSIONS: '519',
    REVENUE: '5716.37',
    ORDERS: '48',
  },
  {
    DATE: '2022-07-05',
    SESSIONS: '531',
    REVENUE: '2766.78',
    ORDERS: '32',
  },
  {
    DATE: '2022-07-04',
    SESSIONS: '403',
    REVENUE: '2426.99',
    ORDERS: '24',
  },
  {
    DATE: '2022-07-03',
    SESSIONS: '589',
    REVENUE: '3832.63',
    ORDERS: '37',
  },
  {
    DATE: '2022-07-02',
    SESSIONS: '671',
    REVENUE: '1995.71',
    ORDERS: '30',
  },
  {
    DATE: '2022-07-01',
    SESSIONS: '575',
    REVENUE: '5404.49',
    ORDERS: '46',
  },
  {
    DATE: '2022-06-30',
    SESSIONS: '590',
    REVENUE: '7343.29',
    ORDERS: '50',
  },
  {
    DATE: '2022-06-29',
    SESSIONS: '486',
    REVENUE: '4102.82',
    ORDERS: '33',
  },
  {
    DATE: '2022-06-28',
    SESSIONS: '534',
    REVENUE: '3382.93',
    ORDERS: '31',
  },
  {
    DATE: '2022-06-27',
    SESSIONS: '530',
    REVENUE: '3873.02',
    ORDERS: '35',
  },
  {
    DATE: '2022-06-26',
    SESSIONS: '657',
    REVENUE: '6213.06',
    ORDERS: '48',
  },
  {
    DATE: '2022-06-25',
    SESSIONS: '613',
    REVENUE: '4851.29',
    ORDERS: '36',
  },
  {
    DATE: '2022-06-24',
    SESSIONS: '533',
    REVENUE: '4113.04',
    ORDERS: '35',
  },
  {
    DATE: '2022-06-23',
    SESSIONS: '583',
    REVENUE: '7557.06',
    ORDERS: '61',
  },
  {
    DATE: '2022-06-22',
    SESSIONS: '536',
    REVENUE: '4398.78',
    ORDERS: '41',
  },
  {
    DATE: '2022-06-21',
    SESSIONS: '559',
    REVENUE: '5177.59',
    ORDERS: '43',
  },
  {
    DATE: '2022-06-20',
    SESSIONS: '568',
    REVENUE: '4660.97',
    ORDERS: '45',
  },
  {
    DATE: '2022-06-19',
    SESSIONS: '666',
    REVENUE: '3953.48',
    ORDERS: '38',
  },
  {
    DATE: '2022-06-18',
    SESSIONS: '680',
    REVENUE: '4801.65',
    ORDERS: '45',
  },
  {
    DATE: '2022-06-17',
    SESSIONS: '665',
    REVENUE: '9291.72',
    ORDERS: '65',
  },
  {
    DATE: '2022-06-16',
    SESSIONS: '541',
    REVENUE: '5544.81',
    ORDERS: '45',
  },
  {
    DATE: '2022-06-15',
    SESSIONS: '679',
    REVENUE: '8050.2',
    ORDERS: '66',
  },
  {
    DATE: '2022-06-14',
    SESSIONS: '557',
    REVENUE: '9115.74',
    ORDERS: '60',
  },
  {
    DATE: '2022-06-13',
    SESSIONS: '567',
    REVENUE: '7089.21',
    ORDERS: '50',
  },
  {
    DATE: '2022-06-12',
    SESSIONS: '634',
    REVENUE: '4956.19',
    ORDERS: '45',
  },
  {
    DATE: '2022-06-11',
    SESSIONS: '637',
    REVENUE: '4761.36',
    ORDERS: '39',
  },
  {
    DATE: '2022-06-10',
    SESSIONS: '657',
    REVENUE: '5707.67',
    ORDERS: '52',
  },
  {
    DATE: '2022-06-09',
    SESSIONS: '591',
    REVENUE: '5227.42',
    ORDERS: '43',
  },
  {
    DATE: '2022-06-08',
    SESSIONS: '557',
    REVENUE: '6269.3',
    ORDERS: '39',
  },
  {
    DATE: '2022-06-07',
    SESSIONS: '466',
    REVENUE: '4874.21',
    ORDERS: '40',
  },
  {
    DATE: '2022-06-06',
    SESSIONS: '504',
    REVENUE: '4401.9',
    ORDERS: '35',
  },
  {
    DATE: '2022-06-05',
    SESSIONS: '459',
    REVENUE: '5334.14',
    ORDERS: '40',
  },
  {
    DATE: '2022-06-04',
    SESSIONS: '535',
    REVENUE: '5529.14',
    ORDERS: '41',
  },
  {
    DATE: '2022-06-03',
    SESSIONS: '387',
    REVENUE: '6940.93',
    ORDERS: '52',
  },
  {
    DATE: '2022-06-02',
    SESSIONS: '396',
    REVENUE: '407.47',
    ORDERS: '3',
  },
  {
    DATE: '2022-06-01',
    SESSIONS: '341',
    REVENUE: '3603.67',
    ORDERS: '31',
  },
  {
    DATE: '2022-05-31',
    SESSIONS: '300',
    REVENUE: '4391.83',
    ORDERS: '36',
  },
  {
    DATE: '2022-05-30',
    SESSIONS: '259',
    REVENUE: '2672.21',
    ORDERS: '25',
  },
  {
    DATE: '2022-05-29',
    SESSIONS: '280',
    REVENUE: '2466.48',
    ORDERS: '20',
  },
  {
    DATE: '2022-05-28',
    SESSIONS: '299',
    REVENUE: '1200.84',
    ORDERS: '15',
  },
  {
    DATE: '2022-05-27',
    SESSIONS: '277',
    REVENUE: '2914.56',
    ORDERS: '24',
  },
  {
    DATE: '2022-05-26',
    SESSIONS: '297',
    REVENUE: '3093.46',
    ORDERS: '30',
  },
  {
    DATE: '2022-05-25',
    SESSIONS: '234',
    REVENUE: '2007.69',
    ORDERS: '14',
  },
  {
    DATE: '2022-05-24',
    SESSIONS: '261',
    REVENUE: '1804.75',
    ORDERS: '16',
  },
  {
    DATE: '2022-05-23',
    SESSIONS: '270',
    REVENUE: '2192.81',
    ORDERS: '22',
  },
  {
    DATE: '2022-05-22',
    SESSIONS: '345',
    REVENUE: '2981.01',
    ORDERS: '24',
  },
  {
    DATE: '2022-05-21',
    SESSIONS: '382',
    REVENUE: '3942.91',
    ORDERS: '31',
  },
  {
    DATE: '2022-05-20',
    SESSIONS: '379',
    REVENUE: '4158.12',
    ORDERS: '24',
  },
  {
    DATE: '2022-05-19',
    SESSIONS: '449',
    REVENUE: '3708.67',
    ORDERS: '33',
  },
  {
    DATE: '2022-05-18',
    SESSIONS: '337',
    REVENUE: '1973.27',
    ORDERS: '22',
  },
  {
    DATE: '2022-05-17',
    SESSIONS: '388',
    REVENUE: '2965.33',
    ORDERS: '31',
  },
  {
    DATE: '2022-05-16',
    SESSIONS: '590',
    REVENUE: '5042.75',
    ORDERS: '45',
  },
  {
    DATE: '2022-05-15',
    SESSIONS: '650',
    REVENUE: '4896.58',
    ORDERS: '49',
  },
  {
    DATE: '2022-05-14',
    SESSIONS: '591',
    REVENUE: '5988.7',
    ORDERS: '46',
  },
  {
    DATE: '2022-05-13',
    SESSIONS: '435',
    REVENUE: '4101.9',
    ORDERS: '36',
  },
  {
    DATE: '2022-05-12',
    SESSIONS: '488',
    REVENUE: '3694.91',
    ORDERS: '36',
  },
  {
    DATE: '2022-05-11',
    SESSIONS: '490',
    REVENUE: '2322.33',
    ORDERS: '26',
  },
  {
    DATE: '2022-05-10',
    SESSIONS: '506',
    REVENUE: '5630.8',
    ORDERS: '43',
  },
  {
    DATE: '2022-05-09',
    SESSIONS: '674',
    REVENUE: '5566.3',
    ORDERS: '54',
  },
  {
    DATE: '2022-05-08',
    SESSIONS: '498',
    REVENUE: '4179.96',
    ORDERS: '33',
  },
  {
    DATE: '2022-05-07',
    SESSIONS: '506',
    REVENUE: '2624.82',
    ORDERS: '18',
  },
  {
    DATE: '2022-05-06',
    SESSIONS: '483',
    REVENUE: '4172.69',
    ORDERS: '33',
  },
  {
    DATE: '2022-05-05',
    SESSIONS: '605',
    REVENUE: '4678.44',
    ORDERS: '35',
  },
  {
    DATE: '2022-05-04',
    SESSIONS: '415',
    REVENUE: '4260.89',
    ORDERS: '41',
  },
  {
    DATE: '2022-05-03',
    SESSIONS: '761',
    REVENUE: '6029.19',
    ORDERS: '46',
  },
  {
    DATE: '2022-05-02',
    SESSIONS: '847',
    REVENUE: '7458.75',
    ORDERS: '55',
  },
  {
    DATE: '2022-05-01',
    SESSIONS: '755',
    REVENUE: '4533.73',
    ORDERS: '44',
  },
  {
    DATE: '2022-04-30',
    SESSIONS: '671',
    REVENUE: '3427.02',
    ORDERS: '31',
  },
  {
    DATE: '2022-04-29',
    SESSIONS: '510',
    REVENUE: '3187.36',
    ORDERS: '32',
  },
  {
    DATE: '2022-04-28',
    SESSIONS: '663',
    REVENUE: '4847.28',
    ORDERS: '46',
  },
  {
    DATE: '2022-04-27',
    SESSIONS: '634',
    REVENUE: '4254.94',
    ORDERS: '39',
  },
  {
    DATE: '2022-04-26',
    SESSIONS: '683',
    REVENUE: '2691.76',
    ORDERS: '38',
  },
  {
    DATE: '2022-04-25',
    SESSIONS: '681',
    REVENUE: '3984.35',
    ORDERS: '40',
  },
  {
    DATE: '2022-04-24',
    SESSIONS: '453',
    REVENUE: '3278.52',
    ORDERS: '34',
  },
  {
    DATE: '2022-04-23',
    SESSIONS: '447',
    REVENUE: '2369.58',
    ORDERS: '24',
  },
  {
    DATE: '2022-04-22',
    SESSIONS: '573',
    REVENUE: '3865',
    ORDERS: '35',
  },
  {
    DATE: '2022-04-21',
    SESSIONS: '547',
    REVENUE: '3266.75',
    ORDERS: '37',
  },
  {
    DATE: '2022-04-20',
    SESSIONS: '544',
    REVENUE: '3044.35',
    ORDERS: '28',
  },
  {
    DATE: '2022-04-19',
    SESSIONS: '761',
    REVENUE: '3786.45',
    ORDERS: '36',
  },
  {
    DATE: '2022-04-18',
    SESSIONS: '515',
    REVENUE: '4009.91',
    ORDERS: '40',
  },
  {
    DATE: '2022-04-17',
    SESSIONS: '354',
    REVENUE: '2012.7',
    ORDERS: '23',
  },
  {
    DATE: '2022-04-16',
    SESSIONS: '435',
    REVENUE: '2482.22',
    ORDERS: '23',
  },
  {
    DATE: '2022-04-15',
    SESSIONS: '374',
    REVENUE: '2297.72',
    ORDERS: '20',
  },
  {
    DATE: '2022-04-14',
    SESSIONS: '473',
    REVENUE: '4617.41',
    ORDERS: '34',
  },
  {
    DATE: '2022-04-13',
    SESSIONS: '443',
    REVENUE: '2001.71',
    ORDERS: '23',
  },
  {
    DATE: '2022-04-12',
    SESSIONS: '426',
    REVENUE: '2355.67',
    ORDERS: '25',
  },
  {
    DATE: '2022-04-11',
    SESSIONS: '463',
    REVENUE: '2849.27',
    ORDERS: '27',
  },
  {
    DATE: '2022-04-10',
    SESSIONS: '412',
    REVENUE: '2435.61',
    ORDERS: '22',
  },
  {
    DATE: '2022-04-09',
    SESSIONS: '470',
    REVENUE: '3063.5',
    ORDERS: '26',
  },
  {
    DATE: '2022-04-08',
    SESSIONS: '493',
    REVENUE: '3044.26',
    ORDERS: '23',
  },
  {
    DATE: '2022-04-07',
    SESSIONS: '496',
    REVENUE: '5101.47',
    ORDERS: '28',
  },
  {
    DATE: '2022-04-06',
    SESSIONS: '519',
    REVENUE: '1590.64',
    ORDERS: '20',
  },
  {
    DATE: '2022-04-05',
    SESSIONS: '526',
    REVENUE: '3353.23',
    ORDERS: '29',
  },
  {
    DATE: '2022-04-04',
    SESSIONS: '559',
    REVENUE: '6284.79',
    ORDERS: '52',
  },
  {
    DATE: '2022-04-03',
    SESSIONS: '578',
    REVENUE: '4252.33',
    ORDERS: '37',
  },
  {
    DATE: '2022-04-02',
    SESSIONS: '465',
    REVENUE: '3346.82',
    ORDERS: '28',
  },
  {
    DATE: '2022-04-01',
    SESSIONS: '547',
    REVENUE: '2728.11',
    ORDERS: '32',
  },
  {
    DATE: '2022-03-31',
    SESSIONS: '536',
    REVENUE: '3516.8',
    ORDERS: '37',
  },
  {
    DATE: '2022-03-30',
    SESSIONS: '567',
    REVENUE: '3970.85',
    ORDERS: '31',
  },
  {
    DATE: '2022-03-29',
    SESSIONS: '553',
    REVENUE: '3096.28',
    ORDERS: '29',
  },
  {
    DATE: '2022-03-28',
    SESSIONS: '642',
    REVENUE: '3989.82',
    ORDERS: '35',
  },
  {
    DATE: '2022-03-27',
    SESSIONS: '534',
    REVENUE: '4022.38',
    ORDERS: '39',
  },
  {
    DATE: '2022-03-26',
    SESSIONS: '487',
    REVENUE: '2918.8',
    ORDERS: '30',
  },
  {
    DATE: '2022-03-25',
    SESSIONS: '531',
    REVENUE: '4098',
    ORDERS: '34',
  },
  {
    DATE: '2022-03-24',
    SESSIONS: '540',
    REVENUE: '2826.14',
    ORDERS: '30',
  },
  {
    DATE: '2022-03-23',
    SESSIONS: '538',
    REVENUE: '2896.68',
    ORDERS: '22',
  },
  {
    DATE: '2022-03-22',
    SESSIONS: '581',
    REVENUE: '3812.71',
    ORDERS: '24',
  },
  {
    DATE: '2022-03-21',
    SESSIONS: '607',
    REVENUE: '4023.44',
    ORDERS: '39',
  },
  {
    DATE: '2022-03-20',
    SESSIONS: '551',
    REVENUE: '3677.63',
    ORDERS: '33',
  },
  {
    DATE: '2022-03-19',
    SESSIONS: '449',
    REVENUE: '2190.17',
    ORDERS: '21',
  },
  {
    DATE: '2022-03-18',
    SESSIONS: '508',
    REVENUE: '4694.94',
    ORDERS: '32',
  },
  {
    DATE: '2022-03-17',
    SESSIONS: '521',
    REVENUE: '4234.16',
    ORDERS: '43',
  },
  {
    DATE: '2022-03-16',
    SESSIONS: '531',
    REVENUE: '3425.44',
    ORDERS: '28',
  },
  {
    DATE: '2022-03-15',
    SESSIONS: '565',
    REVENUE: '3688.34',
    ORDERS: '33',
  },
  {
    DATE: '2022-03-14',
    SESSIONS: '586',
    REVENUE: '5723.47',
    ORDERS: '47',
  },
  {
    DATE: '2022-03-13',
    SESSIONS: '429',
    REVENUE: '1471.55',
    ORDERS: '15',
  },
  {
    DATE: '2022-03-12',
    SESSIONS: '432',
    REVENUE: '1309.62',
    ORDERS: '13',
  },
  {
    DATE: '2022-03-11',
    SESSIONS: '398',
    REVENUE: '3244.62',
    ORDERS: '30',
  },
  {
    DATE: '2022-03-10',
    SESSIONS: '437',
    REVENUE: '3586.64',
    ORDERS: '31',
  },
  {
    DATE: '2022-03-09',
    SESSIONS: '491',
    REVENUE: '2865.57',
    ORDERS: '23',
  },
  {
    DATE: '2022-03-08',
    SESSIONS: '507',
    REVENUE: '2117.06',
    ORDERS: '19',
  },
  {
    DATE: '2022-03-07',
    SESSIONS: '535',
    REVENUE: '2753.49',
    ORDERS: '28',
  },
  {
    DATE: '2022-03-06',
    SESSIONS: '441',
    REVENUE: '2450.8',
    ORDERS: '25',
  },
  {
    DATE: '2022-03-05',
    SESSIONS: '403',
    REVENUE: '1781.12',
    ORDERS: '18',
  },
  {
    DATE: '2022-03-04',
    SESSIONS: '453',
    REVENUE: '2344.09',
    ORDERS: '20',
  },
  {
    DATE: '2022-03-03',
    SESSIONS: '503',
    REVENUE: '4234.89',
    ORDERS: '32',
  },
  {
    DATE: '2022-03-02',
    SESSIONS: '463',
    REVENUE: '2461.26',
    ORDERS: '22',
  },
  {
    DATE: '2022-03-01',
    SESSIONS: '497',
    REVENUE: '3299.59',
    ORDERS: '35',
  },
  {
    DATE: '2022-02-28',
    SESSIONS: '457',
    REVENUE: '2399.29',
    ORDERS: '21',
  },
  {
    DATE: '2022-02-27',
    SESSIONS: '434',
    REVENUE: '1809.03',
    ORDERS: '19',
  },
  {
    DATE: '2022-02-26',
    SESSIONS: '458',
    REVENUE: '1969.32',
    ORDERS: '17',
  },
  {
    DATE: '2022-02-25',
    SESSIONS: '396',
    REVENUE: '3607.94',
    ORDERS: '29',
  },
  {
    DATE: '2022-02-24',
    SESSIONS: '341',
    REVENUE: '1634.29',
    ORDERS: '17',
  },
  {
    DATE: '2022-02-23',
    SESSIONS: '320',
    REVENUE: '1029.11',
    ORDERS: '10',
  },
  {
    DATE: '2022-02-22',
    SESSIONS: '418',
    REVENUE: '1918.26',
    ORDERS: '19',
  },
  {
    DATE: '2022-02-21',
    SESSIONS: '559',
    REVENUE: '3236.75',
    ORDERS: '32',
  },
  {
    DATE: '2022-02-20',
    SESSIONS: '449',
    REVENUE: '2424.51',
    ORDERS: '19',
  },
  {
    DATE: '2022-02-19',
    SESSIONS: '522',
    REVENUE: '1759.74',
    ORDERS: '21',
  },
  {
    DATE: '2022-02-18',
    SESSIONS: '469',
    REVENUE: '3559.25',
    ORDERS: '34',
  },
  {
    DATE: '2022-02-17',
    SESSIONS: '503',
    REVENUE: '4557.14',
    ORDERS: '32',
  },
  {
    DATE: '2022-02-16',
    SESSIONS: '449',
    REVENUE: '3296.85',
    ORDERS: '27',
  },
  {
    DATE: '2022-02-15',
    SESSIONS: '411',
    REVENUE: '2626.13',
    ORDERS: '23',
  },
  {
    DATE: '2022-02-14',
    SESSIONS: '518',
    REVENUE: '3556.21',
    ORDERS: '29',
  },
  {
    DATE: '2022-02-13',
    SESSIONS: '441',
    REVENUE: '3555.21',
    ORDERS: '29',
  },
  {
    DATE: '2022-02-12',
    SESSIONS: '455',
    REVENUE: '2168.68',
    ORDERS: '23',
  },
  {
    DATE: '2022-02-11',
    SESSIONS: '493',
    REVENUE: '3367.84',
    ORDERS: '34',
  },
  {
    DATE: '2022-02-10',
    SESSIONS: '504',
    REVENUE: '1591.67',
    ORDERS: '16',
  },
  {
    DATE: '2022-02-09',
    SESSIONS: '513',
    REVENUE: '4063.69',
    ORDERS: '38',
  },
  {
    DATE: '2022-02-08',
    SESSIONS: '561',
    REVENUE: '2589.74',
    ORDERS: '30',
  },
  {
    DATE: '2022-02-07',
    SESSIONS: '526',
    REVENUE: '4946.96',
    ORDERS: '43',
  },
  {
    DATE: '2022-02-06',
    SESSIONS: '549',
    REVENUE: '3370.35',
    ORDERS: '25',
  },
  {
    DATE: '2022-02-05',
    SESSIONS: '713',
    REVENUE: '2434.06',
    ORDERS: '22',
  },
  {
    DATE: '2022-02-04',
    SESSIONS: '609',
    REVENUE: '3910.73',
    ORDERS: '31',
  },
  {
    DATE: '2022-02-03',
    SESSIONS: '451',
    REVENUE: '3519.02',
    ORDERS: '33',
  },
  {
    DATE: '2022-02-02',
    SESSIONS: '442',
    REVENUE: '3825.61',
    ORDERS: '25',
  },
  {
    DATE: '2022-02-01',
    SESSIONS: '479',
    REVENUE: '4246.18',
    ORDERS: '30',
  },
  {
    DATE: '2022-01-31',
    SESSIONS: '358',
    REVENUE: '1815.64',
    ORDERS: '18',
  },
  {
    DATE: '2022-01-30',
    SESSIONS: '393',
    REVENUE: '1628.42',
    ORDERS: '16',
  },
  {
    DATE: '2022-01-29',
    SESSIONS: '376',
    REVENUE: '2551.53',
    ORDERS: '19',
  },
  {
    DATE: '2022-01-28',
    SESSIONS: '390',
    REVENUE: '2074.76',
    ORDERS: '26',
  },
  {
    DATE: '2022-01-27',
    SESSIONS: '345',
    REVENUE: '3532.87',
    ORDERS: '11',
  },
  {
    DATE: '2022-01-26',
    SESSIONS: '365',
    REVENUE: '2297.72',
    ORDERS: '17',
  },
  {
    DATE: '2022-01-25',
    SESSIONS: '135',
    REVENUE: '560.45',
    ORDERS: '5',
  },
  {
    DATE: '2022-01-24',
    SESSIONS: '223',
    REVENUE: '1634.58',
    ORDERS: '15',
  },
  {
    DATE: '2022-01-23',
    SESSIONS: '255',
    REVENUE: '805.24',
    ORDERS: '8',
  },
  {
    DATE: '2022-01-22',
    SESSIONS: '305',
    REVENUE: '1361.88',
    ORDERS: '10',
  },
  {
    DATE: '2022-01-21',
    SESSIONS: '282',
    REVENUE: '1044.15',
    ORDERS: '11',
  },
  {
    DATE: '2022-01-20',
    SESSIONS: '280',
    REVENUE: '1109.92',
    ORDERS: '8',
  },
  {
    DATE: '2022-01-19',
    SESSIONS: '407',
    REVENUE: '4114.53',
    ORDERS: '31',
  },
  {
    DATE: '2022-01-18',
    SESSIONS: '472',
    REVENUE: '1370.18',
    ORDERS: '13',
  },
  {
    DATE: '2022-01-17',
    SESSIONS: '462',
    REVENUE: '2651.09',
    ORDERS: '20',
  },
  {
    DATE: '2022-01-16',
    SESSIONS: '429',
    REVENUE: '1627.2',
    ORDERS: '18',
  },
  {
    DATE: '2022-01-15',
    SESSIONS: '375',
    REVENUE: '2747.81',
    ORDERS: '19',
  },
  {
    DATE: '2022-01-14',
    SESSIONS: '388',
    REVENUE: '2066.97',
    ORDERS: '19',
  },
  {
    DATE: '2022-01-13',
    SESSIONS: '391',
    REVENUE: '943.46',
    ORDERS: '12',
  },
  {
    DATE: '2022-01-12',
    SESSIONS: '351',
    REVENUE: '1346.95',
    ORDERS: '17',
  },
  {
    DATE: '2022-01-11',
    SESSIONS: '431',
    REVENUE: '2246.42',
    ORDERS: '16',
  },
  {
    DATE: '2022-01-10',
    SESSIONS: '588',
    REVENUE: '2965.3',
    ORDERS: '27',
  },
  {
    DATE: '2022-01-09',
    SESSIONS: '524',
    REVENUE: '2901.42',
    ORDERS: '14',
  },
  {
    DATE: '2022-01-08',
    SESSIONS: '541',
    REVENUE: '1548.1',
    ORDERS: '15',
  },
  {
    DATE: '2022-01-07',
    SESSIONS: '433',
    REVENUE: '1476.51',
    ORDERS: '19',
  },
  {
    DATE: '2022-01-06',
    SESSIONS: '432',
    REVENUE: '3716.38',
    ORDERS: '21',
  },
  {
    DATE: '2022-01-05',
    SESSIONS: '472',
    REVENUE: '2297.52',
    ORDERS: '19',
  },
  {
    DATE: '2022-01-04',
    SESSIONS: '421',
    REVENUE: '3841.38',
    ORDERS: '26',
  },
  {
    DATE: '2022-01-03',
    SESSIONS: '146',
    REVENUE: '810.83',
    ORDERS: '4',
  },
  {
    DATE: '2022-01-02',
    SESSIONS: '47',
    REVENUE: '0',
    ORDERS: '0',
  },
  {
    DATE: '2022-01-01',
    SESSIONS: '52',
    REVENUE: '0',
    ORDERS: '0',
  },
]
