/* eslint-disable import/no-cycle */
import { useReactiveVar } from '@apollo/client'
import { useOrg_userToggleMfaMutation } from '@brand-console/generated-graphql-hooks'
import { appendIfValid, logger } from '@brand-console/utilities'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Spinner } from '@cart/ui'
import React, { FC, useEffect, useState } from 'react'

import {
  AuthenticationMethod,
  authenticatorIdToRemoveVar,
  mfaTokenVar,
} from '../SettingsSecurity.partials'
import { ISuccess, ITokenError, ModalProps } from '../SettingsSecurity.types'

interface MfaFactor {
  id: string
  authenticator_type: string
  active: boolean
}

export const RemoveMFAModal: FC<ModalProps<ISuccess, ITokenError>> = ({
  show,
  closeCallback,
  successCallback,
  handleTokenErrorCallback,
}) => {
  const log = logger.setLogger(logger.LoggerNames.SECURITY)
  const [showFailureAlert, setShowFailureAlert] = useState(false)
  const [failureAlertMessage, setFailureAlertMessage] = useState('An unspecified error occurred.')
  const [mfaMethod, setMfaMethod] = useState<AuthenticationMethod>()
  const authenticatorIdToRemove = useReactiveVar(authenticatorIdToRemoveVar)
  const [recoveryCodeIdToRemove, setRecoveryCodeIdToRemove] = useState('')
  const mfaApiToken = useReactiveVar(mfaTokenVar)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (authenticatorIdToRemove.includes('sms')) {
      setMfaMethod(AuthenticationMethod.SMS)
    } else if (authenticatorIdToRemove.includes('otp')) {
      setMfaMethod(AuthenticationMethod.App)
    }
  }, [authenticatorIdToRemove])

  const [toggleUseMfaMutation] = useOrg_userToggleMfaMutation()

  const removeAuthenticator = async () => {
    try {
      setIsLoading(true)
      const response = await fetch(
        `https://${process.env.NX_AUTH0_DOMAIN}/mfa/authenticators/${authenticatorIdToRemove}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${mfaApiToken}` },
        },
      )
      setIsLoading(false)
      if (response.status === 204) {
        try {
          setIsLoading(true)
          const listAuthenticators = await fetch(
            `https://${process.env.NX_AUTH0_DOMAIN}/mfa/authenticators`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${mfaApiToken}`,
              },
            },
          )
          setIsLoading(false)
          const listAuthenticatorsJSON = await listAuthenticators.json()
          if (listAuthenticatorsJSON.length > 0) {
            // when we have the methods filter out the inactive pending/abandoned ones
            const filteredFactors: MfaFactor[] = listAuthenticatorsJSON.filter(
              (factor: MfaFactor) => factor.active !== false,
            )
            if (
              filteredFactors.length === 1 &&
              filteredFactors[0].authenticator_type === 'recovery-code'
            ) {
              setRecoveryCodeIdToRemove(filteredFactors[0].id)
              toggleUseMfaMutation({ variables: { toggleUserMfaInput: { toggle: false } } })
            }
          }
        } catch (error) {
          setIsLoading(false)
          log.error('Remove MFA', error)
          setFailureAlertMessage('')
          setShowFailureAlert(true)
        }
        // do some remove mfamethod callback to remove the listing from the front-end
        successCallback(
          `${AuthenticationMethod[mfaMethod]} based MFA successfully removed!`,
          mfaMethod,
        )
        setShowFailureAlert(false)
        closeCallback()
      }
    } catch (error) {
      log.error('Remove MFA', error)
      setFailureAlertMessage('')
      setShowFailureAlert(true)
      handleTokenErrorCallback('remove')
    }
  }

  const resetModalOnExited = () => {
    setShowFailureAlert(false)
    setFailureAlertMessage('')
  }

  useEffect(() => {
    if (recoveryCodeIdToRemove !== '') {
      const removeRecoveryCode = async () => {
        try {
          await fetch(
            `https://${process.env.NX_AUTH0_DOMAIN}/mfa/authenticators/${recoveryCodeIdToRemove}`,
            {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${mfaApiToken}`,
              },
            },
          )
        } catch (error) {
          logger.error('Remove MFA error: removeRecoveryCode', error)
        }
      }
      removeRecoveryCode()
    }
  }, [recoveryCodeIdToRemove, mfaApiToken])

  return (
    <Dialog
      width="sm"
      open={show}
      id="removemfadialog"
      onClose={() => {
        resetModalOnExited()
        closeCallback()
      }}
    >
      <DialogTitle>Remove multi-factor authentication</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Spinner tw="m-auto mb-8 h-64 w-64" />
        ) : (
          <div tw="flex w-full flex-col px-2 text-center mobile:px-1">
            {showFailureAlert && (
              <Alert
                tw="mb-3.5"
                severity="error"
                onClose={() => {
                  setShowFailureAlert(false)
                  setFailureAlertMessage('')
                }}
              >
                {appendIfValid(
                  'Something went wrong when attempting to remove this authentication method',
                  failureAlertMessage,
                )}
              </Alert>
            )}
            <p tw="text-left">
              This will remove the multifactor authentication method. In order to better protect
              your account information you will need to have at least one MFA method for your
              account. Are you sure you want to remove this method?
            </p>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeCallback()}>Cancel</Button>
        <Button variant="contained" color="error" onClick={() => removeAuthenticator()}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}
