import { ReactNode } from 'react'
/* eslint-disable @typescript-eslint/no-namespace */

/**
 * Cart.com shared types for typescript services & frontends
 *
 * @remarks
 * The `@brand-console/types` package defines a number of common interfaces and enums
 * which are common to numerous services & front ends.
 *
 * @packageDocumentation
 */

/** *************************************** */
// GraphQL Organization Types
/** *************************************** */

export enum RolesNames {
  BUSINESS_ADMINISTRATOR = 'business_admin',
  BUSINESS_MEMBER = 'business_member',
  ORGANIZATION_MEMBER = 'org_member',
  ORGANIZATION_ADMINISTRATOR = 'org_admin',
  ORGANIZATION_MANAGER = 'org_manager',
  CUSTOMER_SALES_REP = 'customer_sales_rep',
  SUPER_ADMIN = 'superadmin',
}

export type OrgRoles = RolesNames.ORGANIZATION_ADMINISTRATOR | RolesNames.ORGANIZATION_MEMBER

export interface Role {
  description: string
  displayName: string
  id: number
  name: RolesNames
}

export interface ActiveOrganization {
  businessEntityTerminology?: string
  businesses: ActiveBusiness[]
  createdAt?: string
  id: number
  name: string
}

export interface ActiveBusiness {
  id: number
  name: string
  createdAt?: string
  businessServices?: {
    feedManagementEnabled: boolean
    fulfillmentEnabled: boolean
    mcmEnabled: boolean
    storefrontEnabled: boolean
    unifiedAnalyticsEnabled: boolean
  }
}

export interface ISidebarItem {
  children?: ISidebarItem[]
  component?: () => JSX.Element
  exactRoute?: boolean
  footer?: boolean
  grouping?: string
  hideOnNavBar?: boolean
  icon?: ReactNode
  label?: string
  link?: string
  routeElement?: ReactNode
  route?: string | string[]
  sidebar?: boolean
  order?: number
}

/**
 * @public
 * Web Components
 * */
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'cart-button': HTMLElement
    }
  }
}

/**
 * Hubspot Forms
 */
interface HubspotFormFields {
  [key: string]: string
}

interface HubspotCookie {
  hutk: string
  pageName: string
  pageUri: string
}

export interface HubspotFormMessage {
  submittedAt: number
  inlineMessage?: string
  fields: HubspotFormFields[]
  context: HubspotCookie
}

export enum Services {
  CUSTOMER_ENGAGEMENT = 'Customer Engagement',
  DATA_SCIENCE_SOLUTIONS = 'Data Science Solutions',
  FEED_MARKETING = 'Feed Marketing',
  FULFILLMENT = 'Fulfillment',
  GROWTH_CAPITAL = 'Growth Capital',
  GROWTH_MARKETING = 'Growth Marketing',
  HOME = 'Home',
  MARKETPLACE_SERVICES = 'Marketplace Services',
  MARKETPLACE_MANAGEMENT = 'Marketplace Management',
  STOREFRONT = 'Storefront',
  UNIFIED_ANALYTICS = 'Unified Analytics',
}

export type ProductType = 'dfw' | 'mcm' | 'sf' | 'ua' | 'bc' | 'rpt'

export type AlertMessageType = {
  type: 'success' | 'error' | 'info' | 'warning'
  heading: string
  text: string
}

export enum ContactPreferenceTypeOpts {
  phone = 'Phone',
  email = 'Email',
}

export enum BusinessStage {
  GettingStarted = 'Getting Started',
  GrowingBusiness = 'Growing Business',
  LargerEnterprise = 'Larger Enterprise',
}

export enum AdChannelInterestOpts {
  SocialMedia = 'Social media',
  DigitalPublishing = 'Digital publishing',
  Television = 'Traditional television',
  Streaming = 'OTT / streaming',
  Podcasts = 'Podcasts',
}

export enum ServiceInterestOpts {
  SEO = 'Search Engine Optimization',
  IntegratedMedia = 'Integrated media management',
  eCRM = 'eCRM and lifecycle management',
  ChannelManagement = 'Channel Management',
  MarketingIntel = 'Marketing Intelligence',
}

export enum SalesChannelInterestOpts {
  D2CWebsite = 'Direct-to-consumer website',
  Amazon = 'Amazon',
  Walmart = 'Walmart',
  Ebay = 'Ebay',
  Etsy = 'Etsy',
  GoogleShopping = 'Google Shopping',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  TikTok = 'TikTok',
  AffiliateSites = 'Affiliate sites',
  OtherOnlineMarkets = 'Other online marketplaces',
  OtherSocialMedia = 'Other social media channels',
}

export namespace FullfillmentServices {
  export enum ServiceInterestOpts {
    OrderManagement = 'Order management and fulfillment',
    FBARestocking = 'FBA restocking',
    InventoryStorage = 'Inventory storage',
  }

  export enum CurrentPlatformOpts {
    CartDotCom = 'Cart.com',
    Shopify = 'Shopify',
    BigCommerce = 'BigCommerce',
    Magento = 'Magento',
    WooCommerce = 'WooCommerce',
    Amazon = 'Amazon',
    Walmart = 'Walmart',
    Ebay = 'Ebay',
    Etsy = 'Etsy',
    Wix = 'Wix',
    Other = 'Other',
  }

  export enum ShipmentsPerDayOpts {
    LowShipments = '0 - 250',
    MediumShipments = '251 - 500',
    HighShipments = '501 - 1000',
    VeryHighShipments = '1000+',
  }

  export enum HowLongSellingOnlineOpts {
    ZeroToOne = '0 - 1 years',
    OneToThree = '1 - 3 years',
    ThreeToFive = '3 - 5 years',
    FivePlus = '5+ years',
  }

  export enum ShippingSpecialConsiderationOpts {
    SubscriptionBox = 'Subscription Box Fulfillment',
    Crowdfunding = 'Crowdfunding Fulfillment',
    Kitting = 'Kitting',
  }
}

export namespace DataScience {
  export enum ServiceInterestOpts {
    OmniChannel = 'Omni-Channel Analysis',
    DemandForecasting = 'Demand Forecasting',
    CampaignOptimization = 'Campaign Optimization',
    BuyerJourney = 'Buyer Journey Modeling',
    CustomerExperience = 'Customer Experience Analysis',
    NotIncluded = "I don't see my problem in your service offerings",
  }

  export enum DataScienceProcessSSupportOpts {
    ProblemFraming = 'Problem framing / business case development',
    DataAcquisition = 'Data acquisition, preparation, and visualization',
    FeatureEngineering = 'Feature engineering',
    ModelTraining = 'Model training, evaluation, and interpretation',
    ModelDeployment = 'Model deployment, integration and operations',
    ModelAuditing = 'Model auditing',
  }
}

export namespace CustomerEngagement {
  export enum ServiceInterestOpts {
    ProductExperts = 'Trained Product Experts',
    MultichannelComm = 'Multichannel Communications',
  }
}
