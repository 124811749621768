import { useOnClickOutside } from '@cart/ui/hooks/useOnClickOutside'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import React, { useMemo, useRef, useState } from 'react'
import { CSSObject, SubMenu as ReactProSidebarSubMenu } from 'react-pro-sidebar'
import tw from 'twin.macro'

import { Icon } from '../Icon/Icon'
import { MenuItem } from '../MenuItem/MenuItem'
import { IMenuItem } from '../MenuItem/MenuItem.types'
import { useLeftNavStore } from '../../store/left-nav.store'

export const SubMenu = ({ submenu, activeItem, setActiveItem, level = 0 }) => {
  const { isCollapsed } = useLeftNavStore()

  const { pathname } = window.location
  const [showSubmenuItems, setShowSubmenuItems] = useState<boolean>(false)
  const menuRef = useRef(null)

  const { id, icon, title, items } = submenu

  const closeSubmenuItems = () => setShowSubmenuItems(false)

  useOnClickOutside(menuRef, () => setShowSubmenuItems(false))

  /**
   * Recursively check if any children are the current path.
   * The submenu should be open if any of its children are active.
   */
  const { isOnChildRoute } = useMemo(() => {
    const findUrlMatch = (menuItem: IMenuItem): boolean => {
      const menuItemPathName = menuItem.url?.startsWith('http')
        ? new URL(menuItem.url).pathname
        : menuItem.url
      if (menuItem.url && pathname.startsWith(menuItemPathName)) return true
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (menuItem.items) return findActiveSubmenu(menuItem.items)
      return false
    }
    const findActiveSubmenu = (menu: IMenuItem[]): boolean => {
      return menu?.some((menuItem) => findUrlMatch(menuItem))
    }
    return { isOnChildRoute: findActiveSubmenu(items) }
  }, [pathname, items])

  const onSubmenuClick = () => {
    setShowSubmenuItems((prev) => !prev)
  }

  const iconComp = useMemo(
    () =>
      level === 0 && (
        <Icon
          icon={icon as IconName}
          hidden={!icon}
          title={title}
          showTooltip={isCollapsed && !showSubmenuItems}
        />
      ),
    [isCollapsed, showSubmenuItems, icon, level, title],
  )

  let submenuStyles: CSSObject
  if (isCollapsed && !showSubmenuItems) submenuStyles = tw`[& .ps-submenu-content]:!invisible`
  else if (showSubmenuItems) submenuStyles = tw`[& .ps-submenu-content]:visible`

  return (
    <ReactProSidebarSubMenu
      ref={menuRef}
      label={title}
      defaultOpen={isOnChildRoute}
      icon={iconComp}
      data-level={level}
      onClick={onSubmenuClick}
      rootStyles={submenuStyles}
    >
      {items.map((item: IMenuItem) => {
        if (item.hidden) return null
        if (!item.url && !item.onClick) {
          return isCollapsed ? null : (
            <h5 key={`${id}:${item.title}`} className="leftnav-group-header">
              {item.title}
            </h5>
          )
        }
        return (
          <MenuItem
            key={`${id}:${item.title}`}
            item={item}
            level={level + 1}
            closeParentMenu={closeSubmenuItems}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        )
      })}
    </ReactProSidebarSubMenu>
  )
}
