import { DeprecatedCard } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useState } from 'react'
import tw from 'twin.macro'

import arrow_icon from '../../../assets/icons/arrow_bold_down.svg'
import { SERVICE_CTA_DATA } from '../../../helpers/serviceCtaData'
import { useDsMeQuery } from '../../../stores/services/userEndpoints'
import { CTAContainer } from '../../molecules/CTAContainer/CTAContainer'
import { CtaOrCard } from './TableauSection.partials'

type PropTypes = {
  title: string
  content: any
  flags: { title: string; order: number }[] | null
}

export const TableauSection = ({ title, content, flags }: PropTypes) => {
  const [isShowPrompt, setShowPrompt] = useState(false)
  const { uaTempShowDataHealth } = useFlags()

  const sortingContent = [...content]
  sortingContent?.sort((a, b) => a.order - b.order)

  const organizationId = JSON.parse(localStorage.getItem('organization'))?.id
  const { data: user } = useDsMeQuery(organizationId)

  const viewAccess =
    flags?.filter((flag) => !user?.data.attributes.flags[flag.title] && flag).length === 0
  const activeFlag = flags?.find((flag) => flag.order === 0 || flag.order === 1).title

  if (activeFlag) {
    if (!uaTempShowDataHealth && title === 'Data Health Summary') {
      return null
    }
    return (
      <>
        <div tw="relative flex w-max items-center">
          {viewAccess && <h2 tw="mb-0 mr-2">{title}</h2>}
          {viewAccess && (
            <FontAwesomeIcon
              icon={solid('info-circle')}
              tw="mr-2.5"
              onFocus={() => setShowPrompt(true)}
              onMouseOver={() => setShowPrompt(true)}
              onMouseLeave={() => setShowPrompt(false)}
              onBlur={() => setShowPrompt(false)}
            />
          )}
          <div
            tw="absolute top-1/2 left-full w-72 translate-y-[-50%]"
            css={[isShowPrompt ? tw`flex` : tw`hidden`]}
          >
            <img
              alt=""
              tw="absolute top-1/2 -left-1.5 -translate-y-1.5 rotate-90"
              src={arrow_icon}
            />
            <div tw="bg-monochrome-900 text-white text-xs max-w-[294px] rounded-[3px] p-[5px]">
              {SERVICE_CTA_DATA[activeFlag] && SERVICE_CTA_DATA[activeFlag].prompt}
            </div>
          </div>
        </div>
        {viewAccess ? (
          <CtaOrCard
            activeFlag={activeFlag}
            contentLength={content?.length}
            sortingContent={sortingContent}
            title={title}
          />
        ) : (
          <div tw="relative mt-4 mb-6 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
            {SERVICE_CTA_DATA[activeFlag] &&
              SERVICE_CTA_DATA[activeFlag].widgets &&
              SERVICE_CTA_DATA[activeFlag].widgets.map((item: any) => (
                <div
                  key={`${item.title}-${title}-${activeFlag}`}
                  tw="my-2 overflow-hidden rounded-lg xl:m-0"
                >
                  <DeprecatedCard tw="scale-105 blur-xs brightness-50 max-h-[500px]">
                    <img src={item.image} alt="" tw="w-full" />
                  </DeprecatedCard>
                </div>
              ))}
            {SERVICE_CTA_DATA[activeFlag] && SERVICE_CTA_DATA[activeFlag].widgets && (
              <CTAContainer data={SERVICE_CTA_DATA[activeFlag]} />
            )}
          </div>
        )}
      </>
    )
  }
  return null
}
