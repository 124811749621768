import { DeprecatedCard, useBreakpoint } from '@cart/ui'
import React from 'react'

import { SERVICE_CTA_DATA } from '../../../helpers/serviceCtaData'
import TableauReport from '../../atoms/TableauReport/TableauReport'

type CtaType = {
  title?: string
  image?: string
}

export const CtaOrCard = ({ activeFlag, contentLength, sortingContent, title }) => {
  const isLarge = useBreakpoint('lg')
  const isMed = useBreakpoint('md')
  if (contentLength === 1) {
    let device = 'phone'
    switch (true) {
      case isLarge:
        device = 'default'
        break
      case isMed:
        device = 'tablet'
        break
      default:
        device = 'phone'
        break
    }
    return (
      <div tw="relative mt-4 mb-6 grid grid-cols-1">
        {sortingContent.map((item) => (
          <DeprecatedCard
            key={`${item.order}-${title}-${activeFlag}`}
            tw="my-2 bg-white p-0 xl:m-0"
          >
            <TableauReport
              url={item.link}
              options={{
                width: '100%',
                hideTabs: false,
                device,
              }}
            />
          </DeprecatedCard>
        ))}
      </div>
    )
  }
  if (contentLength > 0) {
    return (
      <div tw="relative mt-4 mb-6 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
        {sortingContent.map((item) => (
          <DeprecatedCard
            key={`${item.order}-${title}-${activeFlag}`}
            tw="my-2 bg-white p-4 xl:m-0"
          >
            <TableauReport
              url={item.link}
              options={{
                width: '100%',
                height: '330px',
                hideTabs: false,
              }}
            />
          </DeprecatedCard>
        ))}
      </div>
    )
  }
  return (
    <div tw="relative mt-4 mb-6 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
      {SERVICE_CTA_DATA[activeFlag]
        ? SERVICE_CTA_DATA[activeFlag].widgets.map((item: CtaType) => (
            <DeprecatedCard
              key={`${item.title}-${title}-${activeFlag}`}
              tw="my-2 bg-white p-4 xl:m-0"
            >
              <img src={item.image} alt="" />
            </DeprecatedCard>
          ))
        : SERVICE_CTA_DATA.default.widgets.map((item: CtaType) => (
            <DeprecatedCard
              key={`${item.title}-${title}-${activeFlag}`}
              tw="my-2 bg-white p-4 xl:m-0"
            >
              <img src={item.image} alt="" />
            </DeprecatedCard>
          ))}
    </div>
  )
}
