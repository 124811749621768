import {
  useOrgProductInstanceListQuery,
  useUAWidgetSectionsQuery,
} from '@brand-console/generated-graphql-hooks'
import { logger, useLayoutStore } from '@brand-console/utilities'
import { Spinner } from '@cart/ui'
import { useCurrentContext } from '@cartdotcom/auth'

import TableauReport from '../../components/atoms/TableauReport/TableauReport'
import { UnderMaintenance } from '../../components/molecules/UnderMaintenance/UnderMaintenance'
import { useGetTrustedTicketQuery } from '../../stores/services/tableauEndpoints'
import ErrorPage404 from '../ErrorPage404/ErrorPage404'

export const Fulfillment = () => {
  const { fulfillmentProvisioned } = useLayoutStore()
  const { currentOrganization, currentBusiness } = useCurrentContext()

  const { data: widgetSectionsData, loading: widgetSectionsLoading } = useUAWidgetSectionsQuery({
    variables: {
      widgetTypeKey: 'LOGISTICS_DASHBOARD',
      organizationId: currentOrganization?.id,
    },
  })

  const { loading: productInstanceLoading } = useOrgProductInstanceListQuery({
    variables: {
      input: {
        businessId: currentBusiness?.id,
      },
    },
    skip: !currentBusiness?.id,
  })

  // for correct iframe height
  const iframeHeight = '2835px'
  const { data: ticketData, isFetching: ticketIsFetching } = useGetTrustedTicketQuery(
    currentOrganization.id.toString(),
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const isLoading = ticketIsFetching || widgetSectionsLoading || productInstanceLoading
  const ticket = ticketData?.data?.ticket
  const link = widgetSectionsData?.ua_widgetSections[0]?.widgets[0]?.tableauView?.embedLink

  if (isLoading) {
    return <Spinner tw="m-auto h-64 w-64" type="global" />
  }

  const conditions = {
    fulfillmentProvisioned,
    link: link || 'undefined',
    ticket: ticket || 'undefined',
  }

  if (!fulfillmentProvisioned) {
    logger.error(`Fulfillment not provisioned: ${JSON.stringify(conditions, null, 2)}`)
    return <ErrorPage404 />
  }

  // This is the experience we serve on other pages when there is no Tableau chart or auth info
  if (!ticket || !link) {
    logger.error(
      `Tableau fulfillment information not available: ${JSON.stringify(conditions, null, 2)}`,
    )
    return <UnderMaintenance />
  }

  return (
    <div tw="w-full px-0" data-testid="fulfillment-container">
      <div tw="[max-width: 1440px] m-auto">
        <TableauReport
          url={link}
          token={ticket}
          options={{
            width: '100%',
            height: iframeHeight,
            hideTabs: false,
          }}
          query="?:embed=yes&:toolbar=no"
        />
      </div>
    </div>
  )
}
