import { IMenuItem } from "../../MenuItem/MenuItem.types"

export enum McmMenuIds {
  MCM = 'mcm',
  ORDERS = 'mcm-orders',
  REPORTS = 'mcm-reports',
  PRODUCT_OPPORTUNITIES = 'mcm-product-opportunities',
  MCM_SETTINGS = 'mcm-settings',
  SETTINGS = 'settings',
  API = 'mcm-api',
  LEGACY = 'mcm-legacy',
  ADMIN = 'mcm-admin',
}

export const McmOrdersUrls = {
  BC: `${process.env.NX_APP_ORIGIN}/orders?nav=mcm`,
  LEGACY: `${process.env.NX_MCM_LEGACY_URL}/orders.aspx`,
}

export interface McmMenuItemsVisibility {
  menuItems: IMenuItem[];
  visibility: {[key: string]: boolean};
}
