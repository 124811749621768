// bottom: 22 keeps the "g" in "Aug" from being cut off
export const CHART_MARGIN = { top: 5, right: 20, bottom: 22, left: 40 }

export const sumByKey = (arr, key) => arr.reduce((acc, curr) => acc + curr[key], 0)

// this slice, action types, & reducer can probably be removed once we have actual
// API queries in place
export const queryData = (data, numberOfDays) => {
  return data.slice(0, numberOfDays)
}

export const ACTION_TYPES = {
  WIDEN: 'WIDEN',
  NARROW: 'NARROW',
}

export function daysToGoBackReducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.WIDEN:
      return state + 30
    case ACTION_TYPES.NARROW:
      if (state > 30) {
        return state - 30
      }
      return state
    default:
      throw new Error()
  }
}
