import { ContextMenu, DeprecatedButton, DeprecatedDialog } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import tw from 'twin.macro'

export const DeleteViewModal = ({
  showDeleteViewDialog,
  toggleDeleteView,
  methods,
  handleCvDelete,
  handleSubmit,
  workbookIsLoaded,
}) => {
  return (
    <DeprecatedDialog
      id="delete-view-dialog"
      title="Please confirm"
      show={showDeleteViewDialog}
      close={toggleDeleteView}
      width={800}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form tw="w-full" onSubmit={handleSubmit(handleCvDelete)}>
          <DeprecatedDialog.Body>
            <div tw="flex w-full flex-col">
              <p>Are you sure you want to delete this custom view?</p>
            </div>
          </DeprecatedDialog.Body>
          <DeprecatedDialog.Actions>
            <DeprecatedButton
              text="Cancel"
              variant="tertiary"
              behavior="warning-primary"
              onClick={toggleDeleteView}
            />
            <DeprecatedButton
              busy={!workbookIsLoaded}
              text="Ok"
              behavior="warning-primary"
              type="submit"
            />
          </DeprecatedDialog.Actions>
        </form>
      </FormProvider>
    </DeprecatedDialog>
  )
}

export const DefaultViewModal = ({
  showDefaultViewDialog,
  toggleDefaultView,
  methods,
  handleCvDefault,
  handleSubmit,
  workbookIsLoaded,
}) => {
  return (
    <DeprecatedDialog
      id="default-view-dialog"
      title="Please confirm"
      show={showDefaultViewDialog}
      close={toggleDefaultView}
      width={800}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form tw="w-full" onSubmit={handleSubmit(handleCvDefault)}>
          <DeprecatedDialog.Body>
            <div tw="flex w-full flex-col">
              <p>Are you sure you want to set this view as default?</p>
            </div>
          </DeprecatedDialog.Body>
          <DeprecatedDialog.Actions>
            <DeprecatedButton
              text="Cancel"
              variant="tertiary"
              behavior="default"
              onClick={toggleDefaultView}
            />
            <DeprecatedButton busy={!workbookIsLoaded} text="Ok" behavior="default" type="submit" />
          </DeprecatedDialog.Actions>
        </form>
      </FormProvider>
    </DeprecatedDialog>
  )
}

export const ActionsButton = ({
  customView,
  view,
  toggleDeleteView,
  toggleDefaultView,
  customViewUrl,
}) => {
  return (
    <div css={[view === 'list' ? tw`w-full` : tw`w-10`]} tw="flex h-full items-center justify-end">
      <ContextMenu
        size="small"
        variant="text"
        left
        items={[
          [
            {
              label: 'Set as default',
              onClick: () => toggleDefaultView(customView, customViewUrl),
            },
            {
              label: '',
              icon: <span tw="ml-2 text-accent-two-700">Delete</span>,
              onClick: () => toggleDeleteView(customView, customViewUrl),
            },
          ],
        ]}
      >
        <FontAwesomeIcon
          icon={solid('ellipsis-v')}
          tw="fill-monochrome-600 hover:fill-primary-700"
        />
      </ContextMenu>
    </div>
  )
}
