import type {
  ComputedDatum,
  CustomLayerProps,
  Point,
  PointTooltipProps,
  SliceTooltipProps,
} from '@nivo/line'
import { subDays } from 'date-fns'
import maxBy from 'lodash/maxBy'
import React from 'react'
import tw from 'twin.macro'

import { TooltipCard } from '../TooltipCard'

export const PointToolTip: React.FunctionComponent<PointTooltipProps> = ({ point }) => {
  let xDate = point.data.x as Date

  if (typeof point.serieId === 'string' && point.serieId.toLowerCase() === 'previous period') {
    xDate = subDays(xDate, 30)
  }

  return (
    <TooltipCard title={point.serieId as string}>
      <table>
        <tr>
          <td>Date:</td>
          <td tw="font-bold">{xDate.toLocaleDateString()}</td>
        </tr>
        <tr>
          <td>Impressions:</td>
          <td tw="font-bold">{point.data.yFormatted}</td>
        </tr>
      </table>
    </TooltipCard>
  )
}

export const SliceToolTip: React.FunctionComponent<SliceTooltipProps> = ({ slice }) => {
  return (
    <div tw="rounded bg-white p-2 shadow">
      {slice.points.map((point) => {
        let xDate = point.data.x as Date

        const isPreviousMonth =
          typeof point.serieId === 'string' && point.serieId.toLowerCase() === 'previous period'

        if (isPreviousMonth) {
          xDate = subDays(xDate, 30)
        }

        return (
          <div
            key={point.id}
            css={[isPreviousMonth ? tw`text-monochrome-700` : tw`text-primary-800`]}
          >
            <h4 tw="underline">{point.serieId}</h4>
            <table tw="mb-2">
              <tr>
                <td>Date:</td>
                <td tw="font-bold">{xDate.toLocaleDateString()}</td>
              </tr>
              <tr>
                <td>Impressions:</td>
                <td tw="font-bold">{point.data.yFormatted}</td>
              </tr>
            </table>
          </div>
        )
      })}
    </div>
  )
}

const styleById = {
  default: {
    strokeWidth: 1,
  },
  'Current Period': {
    strokeWidth: 5,
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    r: 5,
  },
  'Previous Period': {
    strokeWidth: 2,
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    r: 3,
  },
}

// For some reason the typescript types for this are wrong
interface CustomLayerPropsWithCurrentPoint extends CustomLayerProps {
  currentPoint: Point
}

export const CustomLines = (props: CustomLayerPropsWithCurrentPoint) => {
  const { series, lineGenerator, xScale, yScale, currentPoint } = props

  const currentSeries = series.find((s) => s.id === 'Current Period')

  const finalPoint = maxBy(currentSeries.data, (d: ComputedDatum) => d.position.x)

  return series.map(({ id, data, color }) => (
    <>
      <path
        key={id}
        d={lineGenerator(
          data.map(
            (d) =>
              ({
                x: xScale(d.data.x as Date) as number,
                y: yScale(d.data.y as number) as number,
              } as never),
          ),
        )}
        // d={lineGenerator(
        //   data.map(
        //     (d) =>
        //       ({
        //         x: d.position.x,
        //         y: d.position.y,
        //       } as never),
        //   ),
        // )}
        fill="none"
        stroke={color}
        style={styleById[id] || styleById.default}
      />

      {currentPoint && (
        <circle
          cx={currentPoint.x}
          cy={currentPoint.y}
          // r={5}
          style={styleById[currentPoint.serieId] || styleById.default}
          fill={currentPoint.serieColor}
        />
      )}

      <circle
        cx={xScale(finalPoint.data.x as Date) as number}
        cy={yScale(finalPoint.data.y as number) as number}
        r={10}
        tw="fill-primary-700 opacity-30"
      />
      <text
        tw="transform fill-primary-700"
        x={(xScale(finalPoint.data.x as Date) as number) - 10}
        y={(yScale(finalPoint.data.y as number) as number) - 15}
      >
        {finalPoint.data.y.toString()}
      </text>
    </>
  ))
}
