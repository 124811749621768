import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import tw from 'twin.macro'

interface ChartHeaderProps {
  title: string
  currentTotal?: number
  percentChange?: number
  plusClick?: () => void
  minusClick?: () => void
}

export const ChartHeader: React.FC<ChartHeaderProps> = ({
  title,
  currentTotal = 0,
  percentChange = 0,
  plusClick,
  minusClick,
}) => {
  let ChangeIcon = null
  let changeClass = tw`text-monochrome-900`

  if (percentChange > 0) {
    ChangeIcon = solid('caret-up')
    changeClass = tw`text-accent-three-800`
  } else if (percentChange < 0) {
    ChangeIcon = solid('caret-down')
    changeClass = tw`text-accent-two-800`
  }

  return (
    <div tw="flex justify-between mb-2">
      <div>
        <h3>{title}</h3>
        <h3 tw="m-0 text-2xl text-primary-700">{currentTotal.toLocaleString()}</h3>

        <p>
          <span css={[changeClass]}>
            {ChangeIcon && <FontAwesomeIcon icon={ChangeIcon} size="lg" tw="mx-1" />}
            <strong>{percentChange.toLocaleString()}%</strong>
          </span>{' '}
          vs. previous period
        </p>
      </div>
      {plusClick && minusClick && (
        <div tw="flex flex-col gap-1 text-monochrome-600">
          <FontAwesomeIcon icon={solid('plus')} tw="cursor-pointer" onClick={plusClick} />
          <FontAwesomeIcon icon={solid('minus')} tw="cursor-pointer" onClick={minusClick} />
        </div>
      )}
    </div>
  )
}
