import {
  logger,
  useDfwNavigation,
  useLayoutStore,
  useMcmNavigation,
  useSfAccount,
  useSfNavigation,
} from '@brand-console/utilities'
import { useEnabledServices } from '@cart/ui/hooks/useEnabledServices'
import { useCurrentContext } from '@cartdotcom/auth'
import { useMemo } from 'react'

import { MenuItems } from '../AppsDropdown.constants'

export const useEnabledItems = (log: typeof logger) => {
  const { product } = useLayoutStore()
  const { currentOrganization, currentBusiness } = useCurrentContext()
  const {
    enabled: { mcmEnabled, sfEnabled, dfwEnabled, uaEnabled, ffEnabled },
  } = useEnabledServices(currentOrganization?.id, currentBusiness?.id, log)
  const [sfAccount] = useSfAccount({ businessId: currentBusiness?.id, log })
  const mcmNavigation = useMcmNavigation()
  const dfwNavigation = useDfwNavigation()
  const sfNavigation = useSfNavigation(sfAccount)

  type EnabledMenuItems = { [key in keyof typeof MenuItems]: boolean }
  const enabledKeys: EnabledMenuItems = useMemo(
    () => ({
      dfw: dfwEnabled,
      ff: ffEnabled,
      mcm: mcmEnabled,
      sf: sfEnabled,
      ua: uaEnabled,
    }),
    [dfwEnabled, ffEnabled, mcmEnabled, sfEnabled, uaEnabled],
  )

  const enabledNavigation = useMemo(
    () => ({
      dfw: { link: dfwNavigation.link },
      mcm: { link: mcmNavigation.link },
      sf: { link: sfNavigation.link, onClick: sfNavigation.onClick },
    }),
    [dfwNavigation.link, mcmNavigation.link, sfNavigation.link, sfNavigation.onClick],
  )

  const enabledItems = useMemo(() => {
    return Object.keys(enabledKeys)
      .filter((key) => key !== product && enabledKeys[key])
      .map((key) => {
        const enabledItem = { ...MenuItems[key] }
        const navigation = enabledNavigation[key]

        if (navigation?.link) enabledItem.link = navigation.link
        else if (navigation?.onClick) {
          enabledItem.link = '#'
          enabledItem.onClick = navigation.onClick
        }

        return enabledItem
      })
  }, [enabledKeys, enabledNavigation, product])

  const disabledItems = useMemo(() => {
    return Object.keys(enabledKeys)
      .filter((key) => key !== product && !enabledKeys[key])
      .map((key) => MenuItems[key])
  }, [enabledKeys, product])

  return [enabledItems, disabledItems] as const
}
