import { DeprecatedButton, DeprecatedChip, Dropdown, DropdownItem, useBreakpoint } from '@cart/ui'
import React, { useMemo, useState } from 'react'
import tw from 'twin.macro'

import { SearchInput } from '../../components/atoms/SearchInput/SearchInput'

export const CustomViewsFilter = ({ data, setValue, values, reset }) => {
  const [mobileFilter, openMobileFilter] = useState(false)
  const isMedium = useBreakpoint('md')
  const createdBy = useMemo(
    () =>
      values.createdBy.map((value) => {
        return {
          value,
          title: data.createdBy.find((item) => item.value === value).title,
        }
      }),
    [values.createdBy, data.createdBy],
  )

  const category = useMemo(
    () =>
      values.category.map((value) => {
        return {
          value,
          title: data.category.find((item) => item.value.toString() === value).title,
        }
      }),
    [values.category, data.category],
  )

  const report = useMemo(
    () =>
      values.report.map((value) => {
        return {
          value,
          title: data.report.find((item) => item.value.toString() === value).title,
        }
      }),
    [values.report, data.report],
  )

  const dropdownItems = useMemo(
    () => [
      // {
      //   id: 'createdBy',
      //   actions: data.createdBy,
      //   placeholder: 'Created By',
      // },
      {
        id: 'category',
        actions: data.category,
        placeholder: 'Category',
      },
      {
        id: 'report',
        actions: data.report,
        placeholder: 'Report',
      },
    ],
    [data],
  )
  return (
    <>
      <div
        css={[
          tw`mb-4 grid items-end gap-4 md:(grid-rows-1 grid-cols-[2fr 2fr])`,
          mobileFilter ? tw`grid-rows-[1fr 3fr]` : tw`grid-rows-1`,
        ]}
      >
        <SearchInput
          onChange={(value: string) => setValue('search', value)}
          placeholder="Search by name..."
          id="search"
          filter
          openMobileFilter={openMobileFilter}
          mobileFilter={mobileFilter}
          value={values.search}
          actionsActive={[...createdBy, ...category]}
        />
        {(isMedium || mobileFilter) && (
          <div tw="grid grid-rows-3 gap-4 md:(grid-cols-2 grid-rows-1)">
            {dropdownItems.map((dropdown) => (
              <Dropdown
                key={dropdown.id}
                id={dropdown.id}
                placeholder={dropdown.placeholder}
                multipleSelection
              >
                {dropdown.actions?.map((item) => (
                  <DropdownItem
                    data-testid="select-option"
                    key={item.value}
                    text={item.title}
                    value={item.value}
                  />
                ))}
              </Dropdown>
            ))}
          </div>
        )}
      </div>
      <div tw="relative mb-4 flex w-full flex-wrap items-center gap-y-2">
        {createdBy.length > 0 &&
          createdBy.map(({ title, value }) => (
            <DeprecatedChip
              text="Created by"
              emphasisText={title}
              key={`CreatedBy${title}`}
              className="mr-2 min-w-[100px]"
              onClose={() =>
                setValue(
                  'createdBy',
                  values.createdBy.filter((item) => item !== value),
                )
              }
            />
          ))}
        {category.length > 0 &&
          category.map(({ title, value }) => (
            <DeprecatedChip
              text="Category is"
              emphasisText={title}
              tw="mr-2 min-w-[100px]"
              key={`Category${title}`}
              onClose={() =>
                setValue(
                  'category',
                  values.category.filter((item) => item !== value),
                )
              }
            />
          ))}
        {report.length > 0 &&
          report.map(({ title, value }) => (
            <DeprecatedChip
              text="Report is"
              tw="mr-2 min-w-[100px]"
              emphasisText={title}
              key={`Report${title}`}
              onClose={() =>
                setValue(
                  'report',
                  values.report.filter((item) => item !== value),
                )
              }
            />
          ))}
        {(values.createdBy.length > 0 ||
          values.category.length > 0 ||
          values.report.length > 0) && (
          <DeprecatedButton
            variant="tertiary"
            text="Clear filters"
            onClick={() => {
              reset()
            }}
          />
        )}
      </div>
    </>
  )
}
