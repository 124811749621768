import {
  AmazonIcon,
  BigCommerceIcon,
  BingIcon,
  CustomerEngagementIcon,
  DataScienceIcon,
  EbayIcon,
  EtsyIcon,
  FacebookIcon,
  FeedMarketingIcon,
  FulfillmentIcon,
  GoogleAnalyticsIcon,
  GoogleIcon,
  GrowthMarketingIcon,
  HubSpotIcon,
  InstagramIcon,
  KlaviyoIcon,
  MailchimpIcon,
  MarketplaceManagementIcon,
  MarketplaceServicesIcon,
  PaypalIcon,
  PinterestIcon,
  QuickBooksIcon,
  RakutenIcon,
  ShipStationIcon,
  ShopifyIcon,
  StorefrontIcon,
  StripeIcon,
  TikTokIcon,
  UnifiedAnalyticsIcon,
  ZapierIcon,
} from '@cart/ui'
import React from 'react'

import { IAppOrService } from './AppsAndServices.types'

export const MarketplaceManagement: IAppOrService = {
  title: 'Marketplace Management',
  link: '/marketplace-management',
  description: (
    <>
      Use our repricing tool to capture the Buy Box <b>72% of the time</b>. Integrates with:
    </>
  ),
  integrations: [AmazonIcon, EbayIcon, ShopifyIcon, BigCommerceIcon, EtsyIcon],
  Icon: MarketplaceManagementIcon,
}

export const FeedMarketing: IAppOrService = {
  title: 'Feed Marketing',
  description: (
    <>
      Advertise on <b>2,000+</b> search engines, social platforms, shopping channels, marketplaces
      and custom channels.
    </>
  ),
  integrations: [
    GoogleIcon,
    AmazonIcon,
    InstagramIcon,
    PinterestIcon,
    BingIcon,
    FacebookIcon,
    RakutenIcon,
  ],
  link: '/feed-marketing',
  Icon: FeedMarketingIcon,
}

export const UnifiedAnalytics: IAppOrService = {
  title: 'Unified Analytics',
  description: 'Sync your data with ease and avoid the hassle of pulling manual reports.',
  integrations: [
    GoogleAnalyticsIcon,
    MailchimpIcon,
    HubSpotIcon,
    KlaviyoIcon,
    TikTokIcon,
    FacebookIcon,
    AmazonIcon,
  ],
  link: '/unified-analytics',
  Icon: UnifiedAnalyticsIcon,
}

export const Storefront: IAppOrService = {
  title: 'Storefront',
  description: (
    <>
      Create an online store with <b>off-the-shelf features</b> you need to scale and{' '}
      <b>integrate</b> with the best e-commerce solutions.
    </>
  ),
  integrations: [PaypalIcon, StripeIcon, ZapierIcon, QuickBooksIcon, ShipStationIcon],
  link: '/storefront',
  Icon: StorefrontIcon,
}

export const Fulfillment: IAppOrService = {
  title: 'Fulfillment',
  description: (
    <>
      Ship products faster with our <b>nationwide fulfillment</b> network.
    </>
  ),
  integrations: [],
  link: '/fulfillment',
  buttonText: 'Learn more',
  Icon: FulfillmentIcon,
}

export const GrowthMarketing: IAppOrService = {
  title: 'Growth Marketing',
  description: 'Expert marketing services for omnichannel sales growth.',
  integrations: [],
  link: '/growth-marketing',
  buttonText: 'Learn more',
  Icon: GrowthMarketingIcon,
}

export const CustomerEngagement: IAppOrService = {
  title: 'Customer Engagement',
  description: (
    <>
      Omnichannel and <b>personalized customer service</b> from a team you can trust.
    </>
  ),
  integrations: [],
  link: '/customer-engagement',
  buttonText: 'Learn more',
  Icon: CustomerEngagementIcon,
}

export const DataScienceSolutions: IAppOrService = {
  title: 'Data Science Solutions',
  description: (
    <>
      Learn about current and upcoming capabilities{' '}
      <b>leveraging machine learning and automation to acquire, convert, and fulfill</b> shopper
      demand at scale.
    </>
  ),
  integrations: [],
  link: '/data-science-solutions',
  buttonText: 'Learn more',
  Icon: DataScienceIcon,
}

export const MarketplaceServices: IAppOrService = {
  title: 'Marketplace Services',
  description: (
    <>
      Helping you <b>reach new audiences</b> with full-service solutions that maximize revenue on
      the world’s leading Marketplaces.
    </>
  ),
  integrations: [],
  link: '/marketplace-services',
  buttonText: 'Learn more',
  Icon: MarketplaceServicesIcon,
}
