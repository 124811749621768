import React, { PropsWithChildren } from 'react'

interface TooltipCardProps {
  title?: string
}

export const TooltipCard: React.FC<PropsWithChildren<TooltipCardProps>> = ({ title, children }) => {
  return (
    <div tw="rounded bg-white p-3.5 shadow">
      {title && <h5 tw="underline">{title}</h5>}
      {children}
    </div>
  )
}
