import React from 'react'

import { TooltipCard } from '../TooltipCard'

export function customTimeTicks(tick) {
  // Every 10 days looks about right for now
  if (tick.tickIndex % 10 === 0) {
    return (
      <g transform={`translate(${tick.x},${tick.y})`}>
        <text
          // Bar with time series requires a custom tick mark, so this copies the styles
          // from inspecting the html on the tick mark from the line chart. If those are
          // ever changed, this will need to be updated.
          textAnchor="middle"
          dominantBaseline="text-before-edge"
          transform="translate(0,10)"
          style={{ fontFamily: 'sans-serif', fontSize: 11, fill: 'rgb(51, 51, 51)' }}
        >
          {tick.value}
        </text>
      </g>
    )
  }
  return null
}

export const ReturnsChartTooltip = (props) => {
  const {
    data: { date, returns },
  } = props

  return (
    <TooltipCard>
      <table>
        <tr>
          <td>Date:</td>
          <td tw="font-bold">{date}</td>
        </tr>
        <tr>
          <td>Returns:</td>
          <td tw="font-bold">{returns}</td>
        </tr>
      </table>
    </TooltipCard>
  )
}
