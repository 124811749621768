import { useMemo, useReducer } from 'react'
import { theme } from 'twin.macro'

import { ACTION_TYPES, daysToGoBackReducer, queryData, sumByKey } from '../chartHelpers'
import { ALL_DATA } from '../sessions_revenue_orders_byDate'
import { SessionsByDate } from '../SessionsByDate'

export function useLineData(key) {
  const keyUpper = key.toUpperCase()

  const [daysToGoBack, dispatch] = useReducer(daysToGoBackReducer, 60)
  const widenDateRange = () => dispatch({ type: ACTION_TYPES.WIDEN })
  const narrowDateRange = () => dispatch({ type: ACTION_TYPES.NARROW })
  const slicedData = queryData(ALL_DATA, daysToGoBack)

  const [data, dataSummary] = useMemo(() => {
    const currentPeriod = slicedData.slice(0, daysToGoBack / 2)
    const previousPeriod = slicedData.slice(daysToGoBack / 2, daysToGoBack)

    const dataPrevious = previousPeriod.map((d) => {
      const xAsDate = new Date(d.DATE)
      return {
        // adds 30 days to the date to put the line on the same scale as the current data
        x: new Date(xAsDate.setDate(xAsDate.getDate() + daysToGoBack / 2)),
        // + converts the string to a number
        y: +d[keyUpper],
      }
    })
    const dataCurrent = currentPeriod.map((d) => ({ x: new Date(d.DATE), y: +d[keyUpper] }))

    const currentTotal = sumByKey(dataCurrent, 'y')
    const previousTotal = sumByKey(dataPrevious, 'y')
    const percentChange = ((currentTotal - previousTotal) / previousTotal) * 100
    return [
      [
        {
          id: 'Previous Period',
          color: theme`colors.monochrome.500`,
          data: dataPrevious,
        },
        {
          id: 'Current Period',
          color: theme`colors.primary.700`,
          data: dataCurrent,
        },
      ],
      { id: 'summary', percentChange, currentTotal, previousTotal },
    ]
  }, [daysToGoBack, keyUpper, slicedData])

  return {
    data,
    dataSummary,
    daysToGoBack,
    widenDateRange,
    narrowDateRange,
    slicedData,
  }
}

export function useDataDifferentSets() {
  // const [data, setData] = useState<DataType>()
  const [dataNew, dataOld] = useMemo(() => {
    // const coords = snowFlakeData.map((d) => ({ x: new Date(d.DATE), y: +d.SESSIONS }))
    const coords = SessionsByDate.map((d) => ({ x: new Date(d.DATE), y: +d.SESSIONS }))
    return [
      // { id: 'Previous Month', data: coords.filter((coord) => coord.x.startsWith('5')) },
      [
        {
          id: 'Current Period',
          color: theme`colors.primary.700`,
          // data: coords.filter((coord) => coord.x.startsWith('6')),
          // data: coords.filter((coord) => coord.x.getMonth() === 5),
          data: coords.filter((coord) => coord.x.getMonth() === 5),
        },
      ],
      [
        {
          id: 'Previous Period',
          color: theme`colors.monochrome.500`,
          // data: coords.filter((coord) => coord.x.startsWith('6')),
          // data: coords.filter((coord) => coord.x.getMonth() === 5),
          data: coords.filter((coord) => coord.x.getMonth() === 4),
        },
      ],
    ]
  }, [])
  // setData({ id: 'Sessions', data: coords })
  return { dataNew, dataOld }
}
