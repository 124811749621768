import { useReactiveVar } from '@apollo/client'
import {
  activeOrgVar,
  useUAAllCustomViewsQuery,
  useUACustomViewsClearCacheMutation,
} from '@brand-console/generated-graphql-hooks'
import { DeprecatedCard, Spinner } from '@cart/ui'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

import attribution from '../../../assets/images/image_adjust.png'
import marketing from '../../../assets/images/image_attract.png'
import analytics from '../../../assets/images/image_conversions.png'
import online from '../../../assets/images/image_convert.png'
import fulfillment from '../../../assets/images/image_fulfill.png'
import TableauReport from '../../../components/atoms/TableauReport/TableauReport'
import { CTAContainer } from '../../../components/molecules/CTAContainer/CTAContainer'
import { UnderMaintenance } from '../../../components/molecules/UnderMaintenance/UnderMaintenance'
import { SERVICE_CTA_DATA } from '../../../helpers/serviceCtaData'
import { useGetAnalyticsQuery } from '../../../stores/services/analyticsEndpoints'
import {
  useGetTableauJWTQuery,
  useGetTrustedTicketQuery,
} from '../../../stores/services/tableauEndpoints'
import { useDsMeQuery } from '../../../stores/services/userEndpoints'
import { DefaultViewModal, SavedViewControls, SaveViewModal } from './AnalyticsCommon.partials'

type TabType = {
  [key: string]: {
    image: string
  }
}

const CustomStyles = createGlobalStyle`
  .scollbar-widthless::-webkit-scrollbar {
    width: 0;
  }
`

const tabParams: TabType = {
  online_store_flag: {
    image: online,
  },
  inventory_flag: {
    image: analytics,
  },
  fulfillment_customer_flag: {
    image: fulfillment,
  },
  marketing_analytics_flag: {
    image: marketing,
  },
  attribution_ai_flag: {
    image: attribution,
  },
  web_analytics_flag: {
    image: analytics,
  },
  default: {
    image: analytics,
  },
}

export const AnalyticsCommonPage = () => {
  const activeOrg = useReactiveVar(activeOrgVar)
  const { uaTempUseTableauJwt220715 } = useFlags()
  const orgId = JSON.parse(localStorage.getItem('organization'))?.id

  const location = useLocation()
  const [activeFlag, setActiveFlag] = useState<string | null>(null)
  const [viewAccess, setViewAccess] = useState<boolean>(false)
  const [activeUri, setActiveUri] = useState<string | null>(null)
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false)
  const [openDefaultModal, setOpenDefaultModal] = React.useState(false)
  const [customViews, setCustomViews] = useState([])
  const [customViewsDefault, setCustomViewsDefault] = useState([])

  const [workbookSaving, setWorkbookSaving] = useState<boolean>(false)
  const [showSaveViewDialog, setShowSaveViewDialog] = useState<boolean>(false)
  const [showSetDefaultDialog, setShowSetDefaultDialog] = useState<boolean>(false)
  const [vizHasLoaded, setVizHasLoaded] = useState<boolean>(false)

  const { refetch: customViewsRefetch } = useUAAllCustomViewsQuery({
    variables: {
      organizationId: activeOrg?.id.toString(),
    },
  })

  const [clearCache] = useUACustomViewsClearCacheMutation({
    onCompleted: () => customViewsRefetch(),
  })

  const methods = useForm({ mode: 'onChange' })
  const { handleSubmit, watch } = methods
  const loadingAutocomplete = openAutocomplete && customViews.length === 0
  const loadingDefault = openDefaultModal && customViews.length === 0

  const { data: user, isLoading: userLoading } = useDsMeQuery(orgId)
  const {
    data: analyticsResponse,
    isLoading: analyticsIsLoading,
    isFetching: analyticsIsFetching,
  } = useGetAnalyticsQuery(orgId)
  const analyticsData = analyticsResponse?.data

  const {
    data: ticketData,
    error: ticketError,
    isFetching: ticketIsFetching,
  } = useGetTrustedTicketQuery(orgId, {
    refetchOnMountOrArgChange: true,
    skip: uaTempUseTableauJwt220715,
  })
  const {
    data: tableauJWTData,
    error: tableauJWTError,
    isFetching: tableauJWTsFetching,
  } = useGetTableauJWTQuery(orgId, {
    refetchOnMountOrArgChange: true,
    skip: !uaTempUseTableauJwt220715,
  })

  const isLoading =
    analyticsIsLoading ||
    analyticsIsFetching ||
    userLoading ||
    (uaTempUseTableauJwt220715 && tableauJWTsFetching) ||
    (!uaTempUseTableauJwt220715 && ticketIsFetching)
  const ticket = ticketData?.data?.ticket
  const token = tableauJWTData?.data?.token
  const [searchParams] = useSearchParams()
  const customViewUrl = searchParams.get('custom-view')

  // to display page titles and descriptions
  let activePage = null
  let activePageTitle = null
  let activePageDescription = null

  // for correct iframe height
  let iframeHeight = '1435px'

  if (location.pathname.includes('attract')) {
    activePage = 'Attract'
    if (location.pathname.includes('overview')) {
      activePageTitle = 'Attract Overview'
      activePageDescription =
        'A snapshot of your marketing activity across all online media platforms.'
    }
    if (location.pathname.includes('ask-marketing')) {
      activePageTitle = 'Ask Marketing'
      activePageDescription =
        'Analyze your data with a simple statement or question. Rapidly iterate with additional questions or apply filters.'
    }
    if (location.pathname.includes('adjust-ad-spend')) {
      activePageTitle = 'Adjust Ad Spend'
      activePageDescription =
        'Optimize your digital ad spend using Cart.com’s Attribution AI. Use our customized recommendations to generate the highest return across all marketing channels.'
    }
  }
  if (location.pathname.includes('convert')) {
    activePage = 'Convert'
    if (location.pathname.includes('overview')) {
      activePageTitle = 'Convert Overview'
      activePageDescription =
        'A snapshot of your sales activity coming from your online storefront.'
    }
    if (location.pathname.includes('increase-conversions')) {
      activePageTitle = 'Increase Conversions'
      activePageDescription =
        'A view into the activity on your online store, as measured by your web analytics platform. This excludes data from third party marketplaces (like Amazon).'
    }
    if (location.pathname.includes('improve-product-sales')) {
      activePageTitle = 'Improve Product Sales'
      activePageDescription =
        'Analyze an individual product to uncover insights & recommendations on how to scale your sales growth.'
      iframeHeight = '1615px'
    }
  }
  if (location.pathname.includes('fulfill')) {
    activePage = 'Fulfill'
    if (location.pathname.includes('overview')) {
      activePageTitle = 'Fulfillment Overview'
      activePageDescription =
        'A snapshot of your fulfillment activity coming from your third party logistics.'
    }
    if (location.pathname.includes('optimize-inventory')) {
      activePageTitle = 'Optimize Inventory'
      activePageDescription =
        'A deeper look into your inventory levels using Cart.com’s product demand forecast to highlight future availability concerns for your customers.'
    }
  }
  if (location.pathname.includes('explore')) {
    iframeHeight = '1435px'
    activePage = 'Explore'
    if (location.pathname.includes('explore-marketing')) {
      activePageTitle = 'Explore Marketing'
      activePageDescription =
        'All your marketing data at your fingertips! Slice, plot, and analyze your data as you see fit.'
    }
    if (location.pathname.includes('explore-sales')) {
      activePageTitle = 'Explore Sales'
      activePageDescription =
        'All your sales data at your fingertips! Slice, plot, and analyze your data as you see fit.'
    }
    if (location.pathname.includes('explore-fulfillment')) {
      activePageTitle = 'Explore Fulfillment'
      activePageDescription =
        'All your fulfillment data at your fingertips! Slice, plot, and analyze your data as you see fit.'
    }
    if (location.pathname.includes('attribution-ai')) {
      activePageTitle = 'Forecast Pacing'
      activePageDescription =
        'We help you easily compare actual spend and revenue performance against your budget so you can stay on track.'
      iframeHeight = '1515px'
    }
  }

  useEffect(() => {
    if (analyticsData) {
      Object.keys(analyticsData).forEach((key) => {
        if (location.pathname.includes(analyticsData[key].path)) {
          if (analyticsData[key].children.length > 0) {
            analyticsData[key].children.forEach(
              (child) => location.pathname.includes(child.path) && setActiveUri(child.link),
            )
            analyticsData[key].children.forEach((child) => {
              if (location.pathname.includes(child.path) && child.flags && child.flags.length > 0) {
                setActiveFlag(
                  child.flags.find((flag) => flag.order === 0 || flag.order === 1).title,
                )
                child.flags.filter((flag) => !user?.data.attributes.flags[flag.title] && flag)
                  .length === 0 && setViewAccess(true)
              }
            })
          } else {
            setActiveUri(analyticsData[key].link)
          }
        }
      })
    }
  }, [analyticsData, location.pathname, user?.data.attributes.flags])

  useEffect(() => {
    let timer = null
    if (!vizHasLoaded) {
      timer = setTimeout(() => {
        setVizHasLoaded(true)
      }, 3000)
    }
    return () => clearTimeout(timer)
  }, [vizHasLoaded])

  // autocomplete useEffect
  useEffect(() => {
    let active = true
    if (!loadingAutocomplete) {
      return undefined
    }
    const vizes = document.getElementsByTagName('tableau-viz')
    const viz = vizes[0]
    // @ts-ignore
    const { workbook } = viz

    let theReturnedData
    ;(async () => {
      if (workbook) {
        try {
          // @ts-ignore
          theReturnedData = await workbook.getCustomViewsAsync()
        } catch (error) {
          console.log('error is:', error)
          return
        }
      }
      const formattedCustomViews = [
        {
          id: 'default',
          label: activePageTitle,
          isDefault: false,
        },
      ]
      Object.keys(theReturnedData).forEach((datum) => {
        formattedCustomViews.push({
          id: theReturnedData[datum]._customViewImpl._luid,
          label: theReturnedData[datum]._customViewImpl._name,
          isDefault: theReturnedData[datum]._customViewImpl._isDefault,
        })
      })
      if (active) {
        setCustomViews(formattedCustomViews)
        setCustomViewsDefault(formattedCustomViews)
      }
    })()

    return () => {
      active = false
    }
  }, [loadingAutocomplete, activePageTitle])

  // default view modal useEffect
  useEffect(() => {
    let active = true
    if (!loadingDefault) {
      return undefined
    }
    const vizes = document.getElementsByTagName('tableau-viz')
    const viz = vizes[0]
    // @ts-ignore
    const { workbook } = viz

    let theReturnedData
    ;(async () => {
      if (workbook && workbook._workbookImpl) {
        try {
          // @ts-ignore
          theReturnedData = await workbook.getCustomViewsAsync()
        } catch (error) {
          console.log('error is:', error)
          return
        }
      }
      const formattedCustomViews = [
        {
          id: 'default',
          label: activePageTitle,
          isDefault: false,
        },
      ]
      if (theReturnedData) {
        Object.keys(theReturnedData).forEach((datum) => {
          formattedCustomViews.push({
            id: theReturnedData[datum]._customViewImpl._luid,
            label: theReturnedData[datum]._customViewImpl._name,
            isDefault: theReturnedData[datum]._customViewImpl._isDefault,
          })
        })
      }

      if (active) {
        setCustomViews(formattedCustomViews)
        setCustomViewsDefault(formattedCustomViews)
      }
    })()

    return () => {
      active = false
    }
  }, [loadingDefault, activePageTitle])

  useEffect(() => {
    if (!openAutocomplete) {
      setCustomViews([])
    }
  }, [openAutocomplete])

  const toggleSaveView = useCallback(
    () => setShowSaveViewDialog(!showSaveViewDialog),
    [showSaveViewDialog],
  )

  const toggleDefaultViewModal = useCallback(
    () => setShowSetDefaultDialog(!showSetDefaultDialog),
    [showSetDefaultDialog],
  )

  if (isLoading) {
    return <Spinner tw="m-auto h-64 w-64" type="global" />
  }

  const tableauError = uaTempUseTableauJwt220715 ? tableauJWTError : ticketError
  if (tableauError) {
    if (
      ('status' in tableauError && tableauError.status === 500) ||
      ('originalStatus' in tableauError && tableauError.originalStatus === 500)
    ) {
      return <UnderMaintenance />
    }
  }
  const handleAutocompleteInputChange = (event, value) => {
    const vizes = document.getElementsByTagName('tableau-viz')
    const viz = vizes[0]
    // @ts-ignore
    const { workbook } = viz

    if (workbook && value) {
      // @ts-ignore
      workbook.showCustomViewAsync(value.label)
    } else {
      // @ts-ignore
      workbook.showCustomViewAsync()
    }
  }

  const handleCvSave = async (values) => {
    const vizes = document.getElementsByTagName('tableau-viz')
    const viz = vizes[0]
    // @ts-ignore
    const { workbook } = viz

    if (workbook && values) {
      // @ts-ignore
      await workbook.saveCustomViewAsync(values.cvTitle)
      clearCache({
        onError(error) {
          console.log('error is:', error)
        },
      })
    }
    toggleSaveView()
  }

  const handleDefaultModalOpen = () => {
    if (customViewsDefault.length === 0) {
      setOpenDefaultModal(true)
    } else {
      setOpenDefaultModal(false)
    }
    setShowSetDefaultDialog(true)
  }

  const handleDefaultSave = async (values) => {
    const vizes = document.getElementsByTagName('tableau-viz')
    const viz = vizes[0]
    // @ts-ignore
    const { workbook } = viz
    if (workbook && values) {
      if (values.customViewsRadio === activePageTitle) {
        setWorkbookSaving(true)
        // this try-catch-finally block is necessary in order to
        // set the default to the original view because although
        // the embedded API docs instruct that we should call
        // showCustomViewsAsync with a null value in order to display
        // the original view, it returns an error (although still displays
        // the original view)
        try {
          await workbook.showCustomViewAsync()
        } catch (error) {
          console.log('error is:', error)
        } finally {
          await workbook.setActiveCustomViewAsDefaultAsync()
          clearCache({
            onError(error) {
              console.log('error is:', error)
            },
          })
        }
      } else {
        setWorkbookSaving(true)
        await workbook.showCustomViewAsync(values.customViewsRadio)
        await workbook.setActiveCustomViewAsDefaultAsync()
        clearCache({
          onError(error) {
            console.log('error is:', error)
          },
        })
      }
    }
    setWorkbookSaving(false)
    toggleDefaultViewModal()
  }

  if (activeFlag) {
    return (
      <>
        <CustomStyles />
        <div data-testid="tableau-container" tw="hidden w-full md:block">
          {viewAccess ? (
            <div tw="[width: 1385px] m-auto">
              <SavedViewControls
                pageTitle={activePageTitle}
                pageDescription={activePageDescription}
                openAutocomplete={openAutocomplete}
                setOpenAutocomplete={setOpenAutocomplete}
                handleAutocompleteInputChange={handleAutocompleteInputChange}
                customViews={customViews}
                loadingAutocomplete={loadingAutocomplete}
                handleDefaultModalOpen={handleDefaultModalOpen}
                setShowSaveViewDialog={setShowSaveViewDialog}
                vizHasLoaded={vizHasLoaded}
              />
              <TableauReport
                url={customViewUrl || (activeUri && activeUri)}
                token={uaTempUseTableauJwt220715 ? token : ticket}
                options={{
                  width: '100%',
                  height: iframeHeight,
                  hideTabs: false,
                }}
                query="?:embed=yes&:toolbar=no"
              />
              <SaveViewModal
                showSaveViewDialog={showSaveViewDialog}
                toggleSaveView={toggleSaveView}
                methods={methods}
                handleSubmit={handleSubmit}
                handleCvSave={handleCvSave}
              />
              <DefaultViewModal
                showSetDefaultDialog={showSetDefaultDialog}
                toggleDefaultViewModal={toggleDefaultViewModal}
                methods={methods}
                handleSubmit={handleSubmit}
                watch={watch}
                handleDefaultSave={handleDefaultSave}
                loadingDefault={loadingDefault}
                customViewsDefault={customViewsDefault}
                workbookSaving={workbookSaving}
              />
            </div>
          ) : (
            <div tw="relative overflow-hidden [border-radius:.5rem] [height:85vh]">
              <DeprecatedCard tw="h-full scale-100 p-5 shadow-md blur-xs brightness-50">
                <img
                  src={tabParams[activeFlag] && tabParams[activeFlag].image}
                  alt=""
                  tw="m-auto"
                />
              </DeprecatedCard>
              <CTAContainer
                data={
                  SERVICE_CTA_DATA[activeFlag]
                    ? SERVICE_CTA_DATA[activeFlag]
                    : SERVICE_CTA_DATA.default
                }
              />
            </div>
          )}
        </div>
        <div tw="p-7 md:hidden">
          <div tw="flex items-center justify-center">
            <h2 tw="mb-0 ml-2">{activePage}</h2>
          </div>
          <div tw="w-full py-36 px-9 text-center text-monochrome-600">
            <h2 tw="mb-2">Chart Unavailable</h2>
            <p>
              Please view on a larger screen or visit the
              <Link to="unified-analytics/dashboard" tw="text-primary-700 hover:text-primary-800">
                &nbsp;Dashboard
              </Link>
            </p>
          </div>
        </div>
      </>
    )
  }

  return null
}
