import { ResponsiveLine } from '@nivo/line'
import React from 'react'
import { theme } from 'twin.macro'

import { ChartHeader } from '../ChartHeader'
import { CHART_MARGIN } from '../chartHelpers'
import { CustomLines, PointToolTip } from './LineChart.Partials'
import { useLineData } from './useLineData'

export const LineChart = ({ title }) => {
  const { data, dataSummary, daysToGoBack } = useLineData(title)

  return (
    <div>
      <ChartHeader
        title={title}
        currentTotal={dataSummary.currentTotal}
        percentChange={dataSummary.percentChange}
      />
      <div tw="w-full h-64">
        <ResponsiveLine
          onClick={(e) => {
            console.log(e)
          }}
          animate
          data={data}
          margin={CHART_MARGIN}
          // enableSlices="x"
          // pointSize={8}
          useMesh
          enableCrosshair={false}
          enableGridX={false}
          enableGridY={false}
          colors={{ datum: 'color' }}
          lineWidth={7}
          enablePoints={false}
          xScale={{
            type: 'time',
          }}
          axisBottom={{
            format: '%b %d',
            tickValues: `every ${daysToGoBack / 6} days`,
            tickSize: 0,
            tickPadding: 10,
          }}
          axisLeft={{
            // tickValues: ['200', 400, 600, 800, 1000],
            tickSize: 0,
            tickPadding: 10,
          }}
          tooltip={PointToolTip}
          // sliceTooltip={SliceToolTip}
          // Do we need all of these? Maybe.
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            // 'lines',
            CustomLines,
            'slices',
            'points',
            'mesh',
            'legends',
          ]}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: theme`colors.monochrome.500`,
                  strokeWidth: 1,
                },
              },
            },
          }}
        />
      </div>
    </div>
  )
}
