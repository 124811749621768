import { ResponsiveBar } from '@nivo/bar'
import React from 'react'
import { theme } from 'twin.macro'

import { ChartHeader } from '../ChartHeader'
import { CHART_MARGIN } from '../chartHelpers'
import { SHIPPING_COST_DATA } from '../ShippingCostData'
import {
  BarComponent,
  BarTooltip,
  convertCentsToDollarString,
  onlyEvenTicks,
} from './ShippingCostChart.partials'

export const ShippingCostChart = ({ title }) => {
  return (
    <div>
      <ChartHeader title={title} />
      <div tw="h-64 w-full">
        <ResponsiveBar
          layout="horizontal"
          indexBy="carrier"
          keys={['costInCents']}
          animate
          data={SHIPPING_COST_DATA}
          margin={CHART_MARGIN}
          enableGridX={false}
          enableGridY={false}
          colors={(props) => {
            // based on #532fa9, our accent-four.700 color. closest HSL equivalent is hsla(258, 56%, 42%, 1)
            // use 58 as the base percentage for the lightness because the lightness scale is inverted
            let lightnessPercent = 100 - (props.data.shipments / 540) * 58
            lightnessPercent = lightnessPercent > 95 ? 95 : lightnessPercent
            const saturationPercent = (props.data.shipments / 540) * 56
            return `hsla(258, ${saturationPercent}%, ${lightnessPercent}%, 1)`
          }}
          barComponent={BarComponent}
          valueFormat={convertCentsToDollarString}
          axisBottom={{
            format: onlyEvenTicks,
            tickSize: 0,
            tickPadding: 10,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
          }}
          tooltip={BarTooltip}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: theme`colors.monochrome.500`,
                  strokeWidth: 1,
                },
              },
            },
          }}
        />
      </div>
    </div>
  )
}
