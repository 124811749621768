// @ts-ignore
import { useReactiveVar } from '@apollo/client'
import {
  activeOrgVar,
  useUAAllCustomViewsQuery,
  useUACustomViewsClearCacheMutation,
} from '@brand-console/generated-graphql-hooks'
import { DeprecatedBadge, DeprecatedCard, Spinner, Table, TablePagination } from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFlags } from 'launchdarkly-react-client-sdk'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { theme } from 'twin.macro'

import no_connectors from '../../assets/images/no-connectors.png'
import TableauReport from '../../components/atoms/TableauReport/TableauReport'
import { useAppDispatch, useAppSelector } from '../../stores/hooks/hooks'
import { setHiddenVizHasLoaded } from '../../stores/reducers/dashboardSlice'
import {
  useGetTableauJWTQuery,
  useGetTrustedTicketQuery,
} from '../../stores/services/tableauEndpoints'
import { AnalyticsCommonPage } from '../analytics/analyticsCommonPage/AnalyticsCommonPage'
import { ActionsButton, DefaultViewModal, DeleteViewModal } from './CustomViews.partials'
import { CustomViewsFilter } from './CustomViewsFilter'

const ActionsCell = (row, toggleDeleteView, toggleDefaultView) => {
  const {
    original: { cvName, url },
  } = row

  return (
    <div tw="h-full w-full pr-5">
      <ActionsButton
        customView={cvName}
        customViewUrl={url}
        toggleDefaultView={toggleDefaultView}
        toggleDeleteView={toggleDeleteView}
        view="list"
      />
    </div>
  )
}

const CategoryCell = ({ row }) => {
  return (
    <div>
      {row?.original.category}
      {row?.original.isDefault ? (
        <DeprecatedBadge tw="ml-2" text="default" type="emphasis" showDot={false} />
      ) : null}
    </div>
  )
}

const NameCell = ({ row }) => {
  let constructedPath
  if (row.original.category === 'Attract / Overview') {
    constructedPath = `/unified-analytics/attract/overview?custom-view=${row.original.url}`
  }
  if (row.original.category === 'Attract / Ask Marketing') {
    constructedPath = `/unified-analytics/attract/ask-marketing?custom-view=${row.original.url}`
  }
  if (row.original.category === 'Attract / Adjust Ad Spend') {
    constructedPath = `/unified-analytics/attract/adjust-ad-spend?custom-view=${row.original.url}`
  }
  if (row.original.category === 'Convert / Overview') {
    constructedPath = `/unified-analytics/convert/overview?custom-view=${row.original.url}`
  }
  if (row.original.category === 'Convert / Increase Conversions') {
    constructedPath = `/unified-analytics/convert/increase-conversions?custom-view=${row.original.url}`
  }
  if (row.original.category === 'Convert / Improve Product Sales') {
    constructedPath = `/unified-analytics/convert/improve-product-sales?custom-view=${row.original.url}`
  }
  if (row.original.category === 'Fulfill / Overview') {
    constructedPath = `/unified-analytics/fulfill/overview?custom-view=${row.original.url}`
  }
  if (row.original.category === 'Fulfill / Optimize Inventory') {
    constructedPath = `/unified-analytics/fulfill/optimize-inventory?custom-view=${row.original.url}`
  }
  if (row.original.category === 'Explore / Marketing') {
    constructedPath = `/unified-analytics/explore/explore-marketing?custom-view=${row.original.url}`
  }
  if (row.original.category === 'Explore / Sales') {
    constructedPath = `/unified-analytics/explore/explore-sales?custom-view=${row.original.url}`
  }
  if (row.original.category === 'Explore / Fulfillment') {
    constructedPath = `/unified-analytics/explore/explore-fulfillment?custom-view=${row.original.url}`
  }

  if (row.original.category === 'Explore / Attribution AI') {
    constructedPath = `/unified-analytics/explore/attribution-ai?custom-view=${row.original.url}`
  }

  return (
    <div tw="text-primary-700 hover:text-primary-300">
      {/* @ts-ignore */}
      <Link key="AnalyticsCommonPage" to={constructedPath} element={<AnalyticsCommonPage />}>
        {row.original.cvName}
      </Link>
    </div>
  )
}

const DateCell = ({ row }) => {
  return <div>{row.original.createdAt.split(' ')[0]}</div>
}

// const CreatorCell = ({ row }) => {
//   return <div>{row.original.userName}</div>
// }

export const CustomViewsPage = () => {
  const activeOrg = useReactiveVar(activeOrgVar)
  const { uaTempUseTableauJwt220715 } = useFlags()
  const dispatch = useAppDispatch()
  const hiddenVizHasLoaded = useAppSelector((state) => state.dashboardSlice.hiddenVizHasLoaded)
  const organizationId = JSON.parse(localStorage.getItem('organization'))?.id

  // form
  const methods = useForm({
    mode: 'onChange',
    defaultValues: { createdBy: [], category: [], report: [], search: '' },
  })
  const { handleSubmit, setValue, watch, reset } = methods
  // state
  const [selectedView, setSelectedView] = useState<string>('list')
  const [currentPage, setCurrentPage] = useState(1)
  const [noCustomViews, setNoCustomViews] = useState(false)
  const [showDeleteViewDialog, setShowDeleteViewDialog] = useState<boolean>(false)
  const [showDefaultViewDialog, setShowDefaultViewDialog] = useState<boolean>(false)
  const [workbookIsLoaded, setWorkbookIsLoaded] = useState<boolean>(false)
  const [selectedCustomView, setSelectedCustomView] = useState<string>()
  const [selectedCustomViewUrl, setSelectedCustomViewUrl] = useState<string>()
  const [itemsPerPage, setItemsPerPage] = useState(5)

  // data

  const {
    data: ticketData,
    error: ticketError,
    isFetching: ticketIsFetching,
  } = useGetTrustedTicketQuery(organizationId, {
    refetchOnMountOrArgChange: true,
    skip: uaTempUseTableauJwt220715,
  })

  const {
    data: tableauJWTData,
    error: tableauJWTError,
    isFetching: tableauJWTsFetching,
  } = useGetTableauJWTQuery(organizationId, {
    refetchOnMountOrArgChange: true,
    skip: !uaTempUseTableauJwt220715,
  })

  const {
    data: customViewsList,
    loading: customViewsListLoading,
    error: customViewsListError,
    refetch: customViewsRefetch,
  } = useUAAllCustomViewsQuery({
    variables: {
      organizationId: activeOrg?.id.toString(),
    },
  })

  const loading = customViewsListLoading || !hiddenVizHasLoaded
  const ticket = ticketData?.data?.ticket
  const token = tableauJWTData?.data?.token
  const [clearCache] = useUACustomViewsClearCacheMutation({
    onCompleted: () => customViewsRefetch(),
  })

  const customViewsItems = useMemo(
    () => customViewsList?.ua_customViewsList ?? [],
    [customViewsList?.ua_customViewsList],
  )

  const filterData = useMemo(() => {
    return {
      createdBy: uniqBy(
        customViewsItems?.map((item, index) => {
          return { title: item.userName, value: (index + 1).toString() }
        }),
        'title',
      ),
      category: uniqBy(
        customViewsItems?.map((item) => {
          return { title: item.category?.split(' / ')[0], value: item.id }
        }),
        'title',
      ),
      report: uniqBy(
        customViewsItems?.map((item) => {
          return { title: item.category?.split(' / ')[1], value: item.id }
        }),
        'title',
      ),
    }
  }, [customViewsItems])

  const values = watch()

  const updatePageSize = (size) => {
    setItemsPerPage(size)
    setCurrentPage(1)
  }

  const updateCurrentPage = (page) => {
    setCurrentPage(page)
  }

  const toggleDeleteView = useCallback(
    (customView?, customViewUrl?) => {
      setShowDeleteViewDialog(!showDeleteViewDialog)
      if (customView) {
        setSelectedCustomView(customView)
        // give tableau viz time to load before enabling the save button
        setTimeout(async () => {
          setWorkbookIsLoaded(true)
        }, 6000)
      }
      if (customViewUrl) {
        setSelectedCustomViewUrl(customViewUrl)
      }
    },
    [showDeleteViewDialog],
  )

  const toggleDefaultView = useCallback(
    (customView?, customViewUrl?) => {
      setShowDefaultViewDialog(!showDefaultViewDialog)
      setWorkbookIsLoaded(false)
      if (customView) {
        setSelectedCustomView(customView)
        // give tableau viz time to load before enabling the save button
        setTimeout(async () => {
          setWorkbookIsLoaded(true)
        }, 6000)
      }
      if (customViewUrl) {
        setSelectedCustomViewUrl(customViewUrl)
      } else {
        setSelectedCustomViewUrl(null)
      }
    },
    [showDefaultViewDialog],
  )

  const handleCvDelete = async () => {
    setWorkbookIsLoaded(false)
    let workbookLoaded = false
    setTimeout(async () => {
      try {
        const vizes = document.getElementsByTagName('tableau-viz')
        const viz = vizes[0]
        // @ts-ignore
        const { workbook } = viz
        if (workbook) {
          workbookLoaded = true
          setWorkbookIsLoaded(true)
          await workbook.removeCustomViewAsync(selectedCustomView)
          clearCache({
            onError(error) {
              console.log('error is:', error)
            },
          })
          toggleDeleteView()
          setSelectedCustomViewUrl(null)
        }
      } catch (error) {
        console.log('error is:', error)
      } finally {
        // if the tableau viz STILL hasn't loaded after 6 seconds
        // give it another 4 seconds and try again #embeddedApiLife
        if (!workbookLoaded) {
          setTimeout(async () => {
            const vizes = document.getElementsByTagName('tableau-viz')
            const viz = vizes[0]
            // @ts-ignore
            const { workbook } = viz
            if (workbook) {
              setWorkbookIsLoaded(true)
              await workbook.removeCustomViewAsync(selectedCustomView)
              clearCache({
                onError(error) {
                  console.log('error is:', error)
                },
              })
              toggleDeleteView()
              setSelectedCustomViewUrl(null)
            }
          }, 4000)
        }
      }
    }, 6000)
  }

  const handleCvDefault = async () => {
    let workbookLoaded = false
    setWorkbookIsLoaded(false)
    setTimeout(async () => {
      try {
        const vizes = document.getElementsByTagName('tableau-viz')
        const viz = vizes[0]
        // @ts-ignore
        const { workbook } = viz
        // eslint-disable-next-line no-underscore-dangle
        if (workbook._workbookImpl) {
          workbookLoaded = true
          setWorkbookIsLoaded(true)
          await workbook.setActiveCustomViewAsDefaultAsync()
          clearCache({
            onError(error) {
              console.log('error is:', error)
            },
          })
          toggleDefaultView()
          setSelectedCustomViewUrl(null)
        }
      } catch (error) {
        console.log('error is:', error)
      } finally {
        // if the tableau viz STILL hasn't loaded after 6 seconds
        // give it another 4 seconds and try again #embeddedApiLife
        if (!workbookLoaded) {
          setTimeout(async () => {
            const vizes = document.getElementsByTagName('tableau-viz')
            const viz = vizes[0]
            // @ts-ignore
            const { workbook } = viz
            if (workbook) {
              setWorkbookIsLoaded(true)
              await workbook.setActiveCustomViewAsDefaultAsync()
              clearCache({
                onError(error) {
                  console.log('error is:', error)
                },
              })
              toggleDefaultView()
              setSelectedCustomViewUrl(null)
            }
          }, 4000)
          toggleDefaultView()
          setSelectedCustomViewUrl(null)
        }
      }
    }, 6000)
  }

  const sortingResult = useMemo(() => {
    if (
      values.createdBy.length > 0 ||
      values.category.length > 0 ||
      values.report.length > 0 ||
      values.search
    ) {
      return customViewsItems
        .filter((item) =>
          values.createdBy.length > 0
            ? values.createdBy.find((createdById) =>
                item.userName.includes(
                  filterData.createdBy.find((elem) => elem.value === createdById).title,
                ),
              )
            : item,
        )
        .filter((item) =>
          values.category.length > 0
            ? values.category.find((categoryId) =>
                item.category?.includes(
                  filterData.category.find((initItem) => categoryId === initItem.value.toString())
                    .title,
                ),
              )
            : item,
        )
        .filter((item) =>
          values.report.length > 0
            ? values.report?.find((reportId) =>
                item.category?.includes(
                  filterData.report.find((initItem) => reportId === initItem.value.toString())
                    .title,
                ),
              )
            : item,
        )
        .filter((item) => item.cvName.toLowerCase().includes(values.search.toLowerCase()))
    }
    return customViewsItems
  }, [filterData.createdBy, filterData.category, filterData.report, values, customViewsItems])

  useEffect(() => {
    if (
      values.createdBy.length > 0 ||
      values.category.length > 0 ||
      values.report.length > 0 ||
      values.search
    ) {
      updateCurrentPage(1)
    }
  }, [values])

  useEffect(() => {
    if (customViewsItems && customViewsItems.length === 0) {
      setNoCustomViews(true)
    } else {
      setNoCustomViews(false)
    }
  }, [customViewsItems, setNoCustomViews])

  // the same hackish solution used in Dashboard.tsx to ensure that
  // the auth hidden viz is loaded so we can load the other vizes
  useEffect(() => {
    let timer = null
    if (!hiddenVizHasLoaded && !uaTempUseTableauJwt220715) {
      timer = setTimeout(() => {
        dispatch(setHiddenVizHasLoaded(true))
      }, 4000)
    }
    return () => clearTimeout(timer)
  }, [dispatch, hiddenVizHasLoaded, uaTempUseTableauJwt220715])

  const currentPageData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage
    const lastPageIndex = firstPageIndex + itemsPerPage
    return sortingResult?.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, itemsPerPage, sortingResult])

  const setLinkUrl = (category, url) => {
    switch (category) {
      case 'Attract / Overview':
        return `/unified-analytics/attract/overview?custom-view=${url}`
      case 'Attract / Ask Marketing':
        return `/unified-analytics/attract/ask-marketing?custom-view=${url}`
      case 'Attract / Adjust Ad Spend':
        return `/unified-analytics/attract/adjust-ad-spend?custom-view=${url}`
      case 'Convert / Overview':
        return `/unified-analytics/convert/overview?custom-view=${url}`
      case 'Convert / Increase Conversions':
        return `/unified-analytics/convert/increase-conversions?custom-view=${url}`
      case 'Convert / Improve Product Sales':
        return `/unified-analytics/convert/improve-product-sales?custom-view=${url}`
      case 'Fulfill / Overview':
        return `/unified-analytics/fulfill/overview?custom-view=${url}`
      case 'Fulfill / Optimize Inventory':
        return `/unified-analytics/fulfill/optimize-inventory?custom-view=${url}`
      case 'Explore / Marketing':
        return `/unified-analytics/explore/explore-marketing?custom-view=${url}`
      case 'Explore / Sales':
        return `/unified-analytics/explore/explore-sales?custom-view=${url}`
      case 'Explore / Fulfillment':
        return `/unified-analytics/explore/explore-fulfillment?custom-view=${url}`
      case 'Explore / Attribution AI':
        return `/unified-analytics/explore/attribution-ai?custom-view=${url}`
      default:
        return null
    }
  }

  const cardElements = currentPageData?.map(
    ({ createdAt, cvName, isDefault, userName, category, url }) => (
      <DeprecatedCard
        noPadding
        key={`source-${cvName}`}
        tw="w-full overflow-visible p-4 md:(px-8 py-6)"
      >
        <div tw="flex h-full flex-col justify-between">
          <div tw="flex flex-wrap items-center justify-between" />
          <div tw="mb-4">
            <div tw="text-primary-700 hover:text-primary-300">
              {/* @ts-ignore */}
              <Link to={setLinkUrl(category, url)} element={<AnalyticsCommonPage />}>
                {cvName}
              </Link>
            </div>
          </div>
          <p tw="mb-4">
            {category}
            {isDefault ? (
              <DeprecatedBadge
                showDot={false}
                tw="ml-1 whitespace-nowrap capitalize max-w-[95px]"
                type="emphasis"
                text="default"
              />
            ) : null}
          </p>
        </div>
        <div tw="flex border-t border-t-monochrome-600 pt-2 text-monochrome-600">
          <div tw="mt-1 flex w-full gap-1">
            <div tw="flex w-4/5 gap-2.5">
              {/* <div tw="flex grow items-center gap-2.5 text-ellipsis">
                <FontAwesomeIcon icon={solid('user')} tw="fill-monochrome-600" />
                {userName}
              </div> */}
              <div tw="flex items-center gap-2.5">
                <FontAwesomeIcon icon={solid('clock')} tw="fill-monochrome-600" />
                {createdAt.split(' ')[0]}
              </div>
            </div>

            <ActionsButton
              customView={cvName}
              customViewUrl={url}
              view={selectedView}
              toggleDefaultView={toggleDefaultView}
              toggleDeleteView={toggleDeleteView}
            />
          </div>
        </div>
      </DeprecatedCard>
    ),
  )
  if (loading) {
    return (
      <>
        <Spinner tw="m-auto h-64 w-64" type="global" />
        {!hiddenVizHasLoaded && currentPageData?.length > 0 && (
          <div tw="hidden">
            <TableauReport
              hidden
              url={currentPageData[0]?.url}
              token={uaTempUseTableauJwt220715 ? token : ticket}
            />
          </div>
        )}
      </>
    )
  }

  if (noCustomViews) {
    return (
      <div tw="flex w-full items-center justify-center" data-testid="custom-views-empty-container">
        <div tw="flex flex-1 flex-col justify-center md:flex-row">
          <div tw="flex w-full flex-col items-center justify-center md:(w-1/2 items-start)">
            <h1 tw="mb-0">Custom Views</h1>
            <p tw="mb-0 text-center pt-[8px] pb-[32px] md:text-left">
              Custom views allow you to save your reports in the exact configuration you want. This
              can include applied filters, selections, date ranges, period comparisons, and more. To
              begin using saved reports, click the &quot;Save&quot; button on the toolbar of the
              report you wish to save.
            </p>
          </div>
          <div tw="flex flex-col items-center justify-center">
            <img src={no_connectors} alt="custom views" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div tw="w-full px-0" data-testid="custom-views-container">
      <div tw="w-full">
        <h2 tw="mb-6">Custom Views</h2>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <FormProvider {...methods}>
          <form tw="w-full">
            <CustomViewsFilter
              data={filterData}
              setValue={setValue}
              reset={reset}
              values={values}
            />
          </form>
        </FormProvider>
        <div tw="mb-4 flex w-full items-end justify-end rounded-lg border-monochrome-50 bg-white p-2 py-3 pl-6 shadow-md sm:(mb-0 rounded-b-none) mobile:shadow">
          <div tw="flex h-full items-center justify-between gap-3 bg-white pr-4">
            <span>View:</span>
            <FontAwesomeIcon
              icon={solid('grid-2')}
              tw="cursor-pointer text-monochrome-600 hover:text-primary-700"
              style={{ color: selectedView === 'cards' ? theme`colors.primary.700` : undefined }}
              onClick={() => setSelectedView('cards')}
            />
            <FontAwesomeIcon
              icon={solid('bars')}
              tw="cursor-pointer text-monochrome-600 hover:text-primary-700"
              style={{ color: selectedView === 'list' ? theme`colors.primary.700` : undefined }}
              onClick={() => setSelectedView('list')}
            />
          </div>
        </div>
        {selectedView === 'list' ? (
          <Table
            columns={[
              {
                Header: 'Name',
                accessor: 'name',
                Cell: NameCell,
              },
              {
                Header: 'Category & Report',
                accessor: 'workbook',
                Cell: CategoryCell,
              },
              {
                Header: 'Date Created',
                accessor: 'dateCreated',
                Cell: DateCell,
              },
              // {
              //   Header: 'Created by',
              //   accessor: 'ownerName',
              //   Cell: CreatorCell,
              // },
              {
                disableSortBy: true,
                Header: 'Actions',
                accessor: 'url',
                Cell: ({ row }) => ActionsCell(row, toggleDeleteView, toggleDefaultView),
              },
            ]}
            enablePagination
            pagination={{
              itemsPerPage,
              currentPage,
              currentPageCallback: (value: number) => updateCurrentPage(value),
              setItemsPerPageCustom: (value: number) => setItemsPerPage(value),
            }}
            data={sortingResult}
          />
        ) : (
          <>
            <div tw="grid w-full grid-cols-1 gap-7 px-6 py-7 shadow-md md:grid-cols-2 xl:grid-cols-3">
              {cardElements}
            </div>
            <TablePagination
              pageSize={itemsPerPage}
              totalCount={sortingResult.length}
              pageSizeCallback={updatePageSize}
              currentPageCallback={updateCurrentPage}
              currentPage={currentPage}
            />
          </>
        )}
      </div>
      <DeleteViewModal
        showDeleteViewDialog={showDeleteViewDialog}
        toggleDeleteView={toggleDeleteView}
        methods={methods}
        handleSubmit={handleSubmit}
        handleCvDelete={handleCvDelete}
        workbookIsLoaded={workbookIsLoaded}
      />
      <DefaultViewModal
        showDefaultViewDialog={showDefaultViewDialog}
        workbookIsLoaded={workbookIsLoaded}
        toggleDefaultView={toggleDefaultView}
        methods={methods}
        handleSubmit={handleSubmit}
        handleCvDefault={handleCvDefault}
      />
      {selectedCustomViewUrl && (
        <div tw="absolute -left-44 -top-52 -z-50 h-0 opacity-0">
          <TableauReport
            url={selectedCustomViewUrl && selectedCustomViewUrl}
            options={{
              width: '100%',
              height: '80vh',
              hideTabs: false,
            }}
            query="?:embed=yes&:toolbar=no"
          />
        </div>
      )}
    </div>
  )
}
