// components
import {
  Carousel,
  CarouselItem,
  DeprecatedBadge,
  DeprecatedCard,
  Spinner,
  useBreakpoint,
} from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect } from 'react'

// components
import TableauReport from '../../../components/atoms/TableauReport/TableauReport'
import { LineChart, ReturnsChart, ShippingCostChart } from '../../../components/Charts'
import { UnderMaintenance } from '../../../components/molecules/UnderMaintenance/UnderMaintenance'
import { TableauSection } from '../../../components/organisms/TableauSection/TableauSection'
// store
import { useAppDispatch, useAppSelector } from '../../../stores/hooks/hooks'
import { setHiddenVizHasLoaded } from '../../../stores/reducers/dashboardSlice'
import { useGetComingSoonQuery } from '../../../stores/services/comingSoonEndpoints'
import { useGetDashboardQuery } from '../../../stores/services/dashboardEndpoints'
import {
  useGetTableauJWTQuery,
  useGetTrustedTicketQuery,
} from '../../../stores/services/tableauEndpoints'
import { useDsMeQuery } from '../../../stores/services/userEndpoints'
import { StyledChartCard } from './Dashboard.styles'

export const Dashboard = () => {
  const { uaTempUseTableauJwt220715, uaTempShowNewCharts220822, uaShowNewsCarousel } = useFlags()
  const dispatch = useAppDispatch()
  const isMedium = useBreakpoint('md')
  const hiddenVizHasLoaded = useAppSelector((state) => state.dashboardSlice.hiddenVizHasLoaded)

  const organizationId = JSON.parse(localStorage.getItem('organization'))?.id

  const { data: dashboardData, isLoading: dashboardIsLoading } =
    useGetDashboardQuery(organizationId)
  const { data: comingSoonData, isLoading: comingSoonIsLoading } = useGetComingSoonQuery()

  const { isLoading: userIsLoading } = useDsMeQuery(organizationId)
  const {
    data: ticketData,
    error: ticketError,
    isFetching: tiketIsFetching,
  } = useGetTrustedTicketQuery(organizationId, {
    refetchOnMountOrArgChange: true,
    skip: uaTempUseTableauJwt220715,
  })

  const {
    data: tableauJWTData,
    error: tableauJWTError,
    isFetching: tableauJWTIsFetching,
  } = useGetTableauJWTQuery(organizationId, {
    skip: !uaTempUseTableauJwt220715,
  })

  // A mildly hackish solution to make sure the Tableau XSRF token has time to load before the dashboard charts.
  // We only need to ensure it happens on initial load, so subsequent page vists don't need the extra load time.
  // The version of Tableau server that uses trusted tickets does not allow us to capture events, so we're stuck with this for now.
  // This effect can be deleted once the transition to Tableau JWTs is complete
  useEffect(() => {
    let timer = null
    if (!hiddenVizHasLoaded && !uaTempUseTableauJwt220715) {
      timer = setTimeout(() => {
        dispatch(setHiddenVizHasLoaded(true))
      }, 4000)
    }
    return () => clearTimeout(timer)
  }, [dispatch, hiddenVizHasLoaded, uaTempUseTableauJwt220715])

  const isLoading =
    userIsLoading ||
    dashboardIsLoading ||
    (!uaTempUseTableauJwt220715 && tiketIsFetching) ||
    !hiddenVizHasLoaded ||
    (uaTempUseTableauJwt220715 && tableauJWTIsFetching) ||
    comingSoonIsLoading

  const comingSoon = comingSoonData?.data
  const dashboard = dashboardData?.data

  let dashboardSorted
  if (dashboard) {
    dashboardSorted = [...dashboard]?.sort((a, b) => a.order - b.order)
  }

  const tableauError = uaTempUseTableauJwt220715 ? tableauJWTError : ticketError
  if (tableauError) {
    if (
      ('status' in tableauError && tableauError.status === 500) ||
      ('originalStatus' in tableauError && tableauError.originalStatus === 500)
    ) {
      return <UnderMaintenance />
    }
  }

  const ticket = ticketData?.data?.ticket
  const token = tableauJWTData?.data?.token

  if (isLoading) {
    return (
      <>
        <Spinner tw="m-auto h-64 w-64" type="global" />
        {!hiddenVizHasLoaded && dashboard?.length > 0 && (
          <div tw="hidden">
            <TableauReport
              hidden
              url={dashboard[0]?.content[0]?.link}
              token={uaTempUseTableauJwt220715 ? token : ticket}
            />
          </div>
        )}
      </>
    )
  }
  const carouselCards =
    comingSoon &&
    comingSoon.length > 0 &&
    comingSoon.map((item) => (
      <CarouselItem key={item.id}>
        <DeprecatedCard tw="my-2 h-full p-6 px-6 shadow sm:py-5 xl:m-0" noPadding>
          <div tw="h-full sm:flex">
            <div tw="mr-0 flex flex-col items-start gap-4 sm:(mr-3 w-1/2)">
              <h3>{item.attributes.title}</h3>
              {item.attributes.carousel_type === 'New Release' ? (
                <DeprecatedBadge
                  text="New Release"
                  showDot={false}
                  type="success"
                  tw="float-right"
                />
              ) : (
                <DeprecatedBadge
                  text="Coming Soon"
                  showDot={false}
                  type="primary"
                  tw="float-right"
                />
              )}
              <p>{item.attributes.text}</p>
            </div>
            <div tw="mr-0 flex items-center sm:(ml-3 w-1/2)">
              <img
                src={item.attributes.image_path}
                alt="coming soon"
                tw="mb-0 p-1 shadow-md [border-radius:6px] sm:mb-4"
              />
            </div>
          </div>
        </DeprecatedCard>
      </CarouselItem>
    ))
  if (dashboardSorted) {
    return (
      <div tw="flex flex-1 w-full flex-col max-w-[1400px]">
        {dashboardSorted.map(({ section, content, flags }) => (
          <TableauSection key={`${section}`} title={section} content={content} flags={flags} />
        ))}
        {uaTempShowNewCharts220822 && (
          <>
            <div tw="relative flex w-max items-center">
              <h2 tw="mb-0 mr-2">Testing</h2>
              <FontAwesomeIcon icon={solid('info-circle')} tw="mr-2.5" />
            </div>
            <div tw="relative mt-4 mb-6 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
              <StyledChartCard>
                <LineChart title="Sessions" />
              </StyledChartCard>

              <StyledChartCard>
                {/* <div tw="absolute top-0 z-20 w-full h-96"> */}
                <LineChart title="Revenue" />
                {/* </div> */}
              </StyledChartCard>

              <StyledChartCard>
                <ShippingCostChart title="Shipping Cost per Pound" />
              </StyledChartCard>

              <StyledChartCard>
                <LineChart title="Orders" />
              </StyledChartCard>

              <StyledChartCard>
                <ReturnsChart title="Returned Orders" />
              </StyledChartCard>
            </div>
          </>
        )}
        {comingSoon && comingSoon.length > 0 && uaShowNewsCarousel && (
          <div>
            <h2 tw="mt-4 sm:m-0">News</h2>
            <div tw="pb-0 sm:pb-6">
              <Carousel loop={comingSoon.length > 2 || !isMedium} slidesToScroll={isMedium ? 2 : 1}>
                {carouselCards}
              </Carousel>
            </div>
          </div>
        )}
      </div>
    )
  }

  return null
}
